































import { Component, Vue } from 'vue-property-decorator';
import optionsService from './services/options.service';
import restService from './services/rest.service';
import solidPancakeService from './services/solid-pancake.service';
import { Route } from 'vue-router';
import LootToaster from '@/components/LootToaster.vue';
import ForceLandscape from '@/components/ForceLandscape.vue';
import PwaBadge from '@/components/PwaBadge.vue';
import calendarService from './services/calendar.service';

@Component({
  components: {
    LootToaster,
    ForceLandscape,
    PwaBadge,
  },
  watch: {
    $route(to: Route, from: Route) {
      // Add event to SolidPancake
      solidPancakeService.addEvent(
        'viewPage',
        'navigation',
        to.path);
    },
  },
})
export default class App extends Vue {
  public data() {
    return {
      ready: false,
      cycle: null,
      options: optionsService.options,
    };
  }

  public async mounted() {
    // Set language based on options
    this.$i18n.locale = optionsService.options.language ? optionsService.options.language : '';

    // Create session to SolidPancake
    solidPancakeService.createSession(
      'arena',
      navigator.platform,
      process.env.VUE_APP_VERSION);
    solidPancakeService.addEvent(
      'viewPage',
      'navigation',
      '/');

    this.$data.cycle = await calendarService.currentCycle();

    // App should be only compatible with a certain amount of cycle
    if (!['treasure-2021', 'souvenirs-2021', 'fire-2021', 'harvest-2021', 'crowned-souls-2021', 'snow-man-2021', 'renewal-2022', 'drifter'].includes(this.$data.cycle.name)) {
      alert(`Votre application n'est pas compatible avec le cycle en cours.
Vous pouvez aller sur www.thefirstspine.fr pour mettre à jour votre client.`);
      return;
    }

    this.$data.ready = true;
    this.$router.push('/');
  }

  get isInMatch() {
    const matchesPaths: string[] = [
      '/matchmaking',
      '/game',
    ];
    return matchesPaths.includes(this.$route.path);
  }
}
