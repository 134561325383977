





















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ILoot } from '@thefirstspine/types-rest';

@Component
export default class ScheduledLoot extends Vue {

  @Prop() public loot?: ILoot;

  public data() {
    return {
    };
  }

}
