import { ApiService } from './api.service';
import authService from './auth.service';
import { ICard, IDeck, IGameType, IAvatar, ITriumph, IShopItem, IQuest } from '@thefirstspine/types-rest';

export class RestService extends ApiService {

  public async cards() {
    return this.listRestResources<ICard>('cards');
  }

  public async decks() {
    return this.listRestResources<IDeck>('decks');
  }

  public async shopItems() {
    return this.listRestResources<IShopItem>('shop-items');
  }

  public async gameTypes() {
    return this.listRestResources<IGameType>('game-types');
  }

  public async avatars() {
    return this.listRestResources<IAvatar>('avatars');
  }

  public async triumphs() {
    return this.listRestResources<ITriumph>('triumphs');
  }

  public async card(id: string) {
    return this.singleRestResource<ICard>('cards', id);
  }

  public async deck(id: string) {
    return this.singleRestResource<IDeck>('decks', id);
  }

  public async shopItem(id: string) {
    return this.singleRestResource<IShopItem>('shop-items', id);
  }

  public async gameType(id: string) {
    return this.singleRestResource<IGameType>('game-types', id);
  }

  public async avatar(id: string) {
    return this.singleRestResource<IGameType>('avatars', id);
  }

  public async triumph(id: string) {
    return this.singleRestResource<IGameType>('triumphs', id);
  }

  public async quests() {
    return this.singleAsListRestResource<{
      daily: IQuest,
      weekly: IQuest,
    }>('quests');
  }

  public async listRestResources<T>(resource: string): Promise<T[]> {
    const result = await fetch(
      `${process.env.VUE_APP_REST_URL}/rest/${resource}`,
      {
        headers: {
          Authorization: `Bearer ${authService.getAccessToken()}`,
        },
      });
    const jsonResult = await result.json();
    return jsonResult as T[];
  }

  public async singleRestResource<T>(resource: string, id: string): Promise<T> {
    const result = await fetch(
      `${process.env.VUE_APP_REST_URL}/rest/${resource}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authService.getAccessToken()}`,
        },
      });
    const jsonResult = await result.json();
    return jsonResult as T;
  }

  public async singleAsListRestResource<T>(resource: string): Promise<T> {
    const result = await fetch(
      `${process.env.VUE_APP_REST_URL}/rest/${resource}`,
      {
        headers: {
          Authorization: `Bearer ${authService.getAccessToken()}`,
        },
      });
    const jsonResult = await result.json();
    return jsonResult as T;
  }

}

export default new RestService();
