var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("\n    card-component\n    " + ((_vm.micro ? 'is-micro' : '')) + "\n    " + ((_vm.holo ? 'is-holo' : '')) + "\n    " + ((_vm.premium ? 'is-premium' : '')) + "\n    " + ((_vm.card.type === 'square' ? 'is-square' : '')) + "\n    type-" + (_vm.card.type) + "\n    style-" + _vm.cardStyle + "\n  "),style:({backgroundColor: _vm.color}),on:{"mousedown":function($event){return _vm.$emit('mousedown')},"mouseup":function($event){return _vm.$emit('mouseup')},"mouseenter":function($event){return _vm.$emit('mouseenter')},"mouseleave":function($event){return _vm.$emit('mouseleave')}}},[_c('div',{staticClass:"card-container"},[_c('div',{staticClass:"image",style:({backgroundImage: 'url(img/' + _vm.card.imageUrl.replace('https://static.thefirstspine.fr/', '') + ')'})}),_c('img',{staticClass:"premium-filter",attrs:{"src":require("../assets/images/premium-filter.gif")}}),_c('img',{staticClass:"holo-filter",attrs:{"src":require("../assets/images/holo-filter.gif")}}),_c('div',{staticClass:"frame frame1"}),_c('div',{staticClass:"frame frame2"}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.card.name[_vm.$i18n.locale]))]),_c('div',{staticClass:"description"},[_c('FormattedTextCard',{attrs:{"text":_vm.card.text[_vm.$i18n.locale]}})],1),(_vm.card.stats)?_c('div',{staticClass:"stats"},[_vm._l((['top', 'right', 'bottom', 'left']),function(side){return _c('div',{key:side,class:side,style:({paddingTop: (side === 'left' || side === 'right') && !_vm.card.stats[side].capacity ? '23px': 'auto'})},[_c('div',{class:{
            stat: true,
            strength: true,
            plus: _vm.stats && _vm.stats[side] && _vm.stats[side].strength > _vm.card.stats[side].strength,
            minus: _vm.stats && _vm.stats[side] && _vm.stats[side].strength < _vm.card.stats[side].strength,
          }},[_vm._v(_vm._s(_vm.stats && _vm.stats[side] && _vm.stats[side].strength ? _vm.stats[side].strength : _vm.card.stats[side].strength))]),_c('div',{class:{
            stat: true,
            defense: true,
            plus: _vm.stats && _vm.stats[side] && _vm.stats[side].defense > _vm.card.stats[side].defense,
            minus: _vm.stats && _vm.stats[side] && _vm.stats[side].defense < _vm.card.stats[side].defense,
          }},[_vm._v(_vm._s(_vm.stats && _vm.stats[side] && _vm.stats[side].defense ? _vm.stats[side].defense : _vm.card.stats[side].defense))]),((_vm.stats && _vm.stats[side] && _vm.stats[side].capacity) || _vm.card.stats[side].capacity)?_c('div',{staticClass:"stat capacity"},[_c('span',{class:'capacity-' + (_vm.stats && _vm.stats[side] && _vm.stats[side].capacity ? _vm.stats[side].capacity : _vm.card.stats[side].capacity)})]):_vm._e()])}),_c('div',{staticClass:"center"},[_c('div',{class:{
            stat: true,
            life: true,
            plus: _vm.stats && _vm.stats && _vm.stats.life > _vm.card.stats.life,
            minus: _vm.stats && _vm.stats && _vm.stats.life < _vm.card.stats.life,
          }},[_vm._v(_vm._s(_vm.stats ? _vm.stats.life : _vm.card.stats.life))]),_vm._l(((_vm.stats && _vm.stats.capacities ? _vm.stats.capacities : _vm.card.stats.capacities)),function(capacity){return _c('div',{key:capacity,staticClass:"stat capacity"},[_c('span',{class:'capacity-' + capacity})])})],2)],2):_vm._e()]),_c('div',{staticClass:"glow"})])}
var staticRenderFns = []

export { render, staticRenderFns }