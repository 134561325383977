































































































































































import { Component, Vue } from 'vue-property-decorator';
import Card from '@/components/Card.vue';
import restService from '../services/rest.service';
import arenaService from '../services/arena.service';
import { IWizard, IWizardHistoryItem, IApiGetGameResponse, IGameUser, IUserQuest } from '@thefirstspine/types-arena';
import wizardService from '../services/wizard.service';
import { ITriumph, IAvatar } from '@thefirstspine/types-rest';
import moment from 'moment';

@Component({
  components: {
    Card,
  },
})
export default class Home extends Vue {

  public async mounted() {
    const wizard: IWizard = await wizardService.getMe();
    if (wizard.name === '') {
      this.$router.push('/name?redirect=profile');
      return;
    }

    // Get base data
    this.$data.triumphs = await restService.triumphs();
    this.$data.wizard = wizard;
    this.$data.avatars = await restService.avatars();

    // Construct history
    this.$data.history =
      await Promise.all(this.$data.wizard.history.reverse().slice(0, 10).map(async (h: IWizardHistoryItem) => {
      const game: IApiGetGameResponse = await arenaService.getGame(h.gameId);
      const users: IWizard[] = await Promise.all(game.users.map((user: IGameUser) => {
        return wizardService.get(user.user);
      }));
      return {
        ...h,
        game,
        users,
      };
    }));

    this.$data.shards = this.$data.wizard.items
      .reduce((acc: number, curr: any) => acc + (curr.name === 'shard' ? curr.num : 0), 0);
    this.$data.coins = this.$data.wizard.items
      .reduce((acc: number, curr: any) => acc + (curr.name === 'coin' ? curr.num : 0), 0);
  }

  public data() {
    return {
      wizard: null,
      history: [],
      avatars: [],
      triumphs: [],
      triumphsCategories: [
        {
          key: 'activities',
          triumphs: ['wizzard', 'loyal', 'devoted', 'crafter', 'adventurer'],
        },
        {
          key: 'destinies-and-origins',
          triumphs: ['conjurer', 'summoner', 'sorcerer', 'hunter', 'healer', 'architect', 'priest', 'blacksmith'],
        },
        {
          key: 'achievements',
          triumphs: ['sacrificer', 'transporter', 'comic', 'repeater'],
        },
        {
          key: 'targets',
          triumphs: ['silentist', 'predator', 'constructor', 'poacher'],
        },
      ],
      selectedTriumphCategory: 'activities',
      users: [],
      currentState: 'summary',
      shards: 0,
      coins: 0,
      selectedWizard: null,
      selectedTriumph: null,
    };
  }

  public saveAvatar() {
    this.$data.currentState = 'summary';
    wizardService.setMe({avatar: this.$data.wizard.avatar});
  }

  public saveTitle(triumphId: string) {
    if (this.$data.wizard.triumphs.includes(triumphId)) {
      this.$data.wizard.title = triumphId;
      wizardService.setMe({title: this.$data.wizard.title});
    }
  }

  public async discardQuest(id: string) {
    this.$data.wizard.quests = this.$data.wizard.quests.filter((q: string) => q !== id);
    this.$data.wizard.questsProgress = this.$data.wizard.questsProgress.filter((q: IUserQuest) => q.id !== id);
    wizardService.setMe({quests: this.$data.wizard.quests});
    this.$data.wizard = await wizardService.getMe();
  }

  public getFilteredTriumphs(): ITriumph[] {
    const triumphsCategory: any = this.$data.triumphsCategories
      .find((t: any) => t.key === this.$data.selectedTriumphCategory);
    return this.$data.triumphs.filter((triumph: ITriumph) => triumphsCategory.triumphs.includes(triumph.id));
  }

  public hasTriumph(id: string): boolean {
    return this.$data.wizard.triumphs.includes(id);
  }

  public getAvatar(id: string): IAvatar|undefined {
    return this.$data.avatars.find((a: IAvatar) => a.id === id);
  }

  public getTriumph(id: string): ITriumph|undefined {
    return this.$data.triumphs.find((a: ITriumph) => a.id === id);
  }

  public getGameTypeName(id: string): string {
    return this.$t(`profile.gametype-${id}`).toString();
  }

  public getDate(timestamp: number): string {
    return moment(new Date(timestamp)).format(this.$t('profile.datetime').toString());
  }

  public get availableAvatars(): IAvatar[] {
    return this.$data.avatars.filter((a: IAvatar) => a.id !== 'applicant');
  }

}
