











import { Component, Vue, Prop } from 'vue-property-decorator';
import Card from '@/components/Card.vue';
import restService from '@/services/rest.service';

@Component({
  components: {
    Card,
  },
})
export default class CollectedItem extends Vue {

  @Prop() public itemName?: string;

  public data() {
    if (!this.itemName) {
      return {type: 'unhandled'};
    }

    if (/^cover-/.test(this.itemName)) {
      return {
        type: 'cover',
        cover: this.itemName,
      };
    }

    if (/^style-/.test(this.itemName)) {
      return {
        type: 'card-style',
        cardName: 'the-fox',
        card: null,
        style: this.itemName.split('-').splice(1, 10).join('-'),
      };
    }

    if (/^holo-/.test(this.itemName)) {
      return {
        type: 'card-holo',
        cardName: this.itemName.split('-').splice(1, 10).join('-'),
        card: null,
      };
    }

    if (/^premium-/.test(this.itemName)) {
      return {
        type: 'card-premium',
        cardName: this.itemName.split('-').splice(1, 10).join('-'),
        card: null,
      };
    }

    return {type: 'unhandled'};
  }

  public async mounted() {
    if (this.$data.cardName) {
      this.$data.card = await restService.card(this.$data.cardName);
    }
  }

}
