import { ApiService } from './api.service';
import authService from './auth.service';
import { IWizard } from '@thefirstspine/types-arena';
import optionsService from './options.service';

export class WizardService extends ApiService {

  public async get(id: number): Promise<IWizard> {
    const ret: Response = await fetch(
      `${process.env.VUE_APP_ARENA_REALMS_URL.replace('{realm}', optionsService.options.realm)}/wizard/${id}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    const json = await ret.json();
    return json as IWizard;
  }

  public async getMe(): Promise<IWizard> {
    const ret: Response = await fetch(
      `${process.env.VUE_APP_ARENA_REALMS_URL.replace('{realm}', optionsService.options.realm)}/wizard/me`,
      {
        method: 'get',
        headers: {
          'Authorization': `Bearer ${authService.getAccessToken()}`,
          'Content-Type': 'application/json',
        },
      },
    );
    const json = await ret.json();
    return json as IWizard;
  }

  public async setMe(data: Partial<IEditableWizard>): Promise<IWizard> {
    const ret: Response = await fetch(
      `${process.env.VUE_APP_ARENA_REALMS_URL.replace('{realm}', optionsService.options.realm)}/wizard/me`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          'Authorization': `Bearer ${authService.getAccessToken()}`,
          'Content-Type': 'application/json',
        },
      },
    );
    const json = await ret.json();
    return json as IWizard;
  }

}

export interface IEditableWizard {
  avatar: string;
  title: string;
  name: string;
  friends: number[];
  publicRoom: null|'fr'|'en';
  quests: string[];
}

export default new WizardService();
