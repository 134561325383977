import { ApiService } from './api.service';
import authService from './auth.service';

export class RoomsService extends ApiService {

  public async getMessagesInRealmRoom(realm: string): Promise<IRoomMessage[]> {
    const response: {data: IRoomMessage[]} =
      await this.call<{data: IRoomMessage[]}>(`subjects/arena/rooms/realm-${realm}/messages`);
    return response.data;
  }

  public postMessageInRealmRoom(realm: string, message: string) {
    fetch(
      `${process.env.VUE_APP_ROOMS_URL}/api/subjects/arena/rooms/realm-${realm}/messages`,
      {
        method: 'post',
        body: JSON.stringify({
          message,
        }),
        headers: {
          'Authorization': `Bearer ${authService.getAccessToken()}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public async getMessagesInGameRoom(gameId: number): Promise<IRoomMessage[]> {
    const response: {data: IRoomMessage[]} =
      await this.call<{data: IRoomMessage[]}>(`subjects/arena/rooms/game-${gameId}/messages`);
    return response.data;
  }

  public postMessageInGameRoom(gameId: number, message: string) {
    fetch(
      `${process.env.VUE_APP_ROOMS_URL}/api/subjects/arena/rooms/game-${gameId}/messages`,
      {
        method: 'post',
        body: JSON.stringify({
          message,
        }),
        headers: {
          'Authorization': `Bearer ${authService.getAccessToken()}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }

  protected async call<T>(path: string): Promise<T> {
    const ret: Response = await fetch(
      `${process.env.VUE_APP_ROOMS_URL}/api/${path}`,
      {
        method: 'get',
        headers: {
          'Authorization': `Bearer ${authService.getAccessToken()}`,
          'Content-Type': 'application/json',
        },
      },
    );
    const json = await ret.json();
    if (json.error) {
      throw new Error(json.error.message);
    }

    return json as T;
  }

}

export default new RoomsService();

export interface IRoomMessage {
  message: string;
  user: number;
  timestamp: number;
}
