






























































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import Card from '../components/Card.vue';
import CardInfo from '../components/CardInfo.vue';
import { IApiGetGameResponse,
         IWizard,
         IGameInstance,
         IGameCard,
         IGameAction,
         IInteractionMoveCardOnBoardPossibility,
         IGameResult,
         IGameUser } from '@thefirstspine/types-arena';
import arenaService from '../services/arena.service';
import restService from '../services/rest.service';
import wizardService from '../services/wizard.service';
import optionsService from '../services/options.service';
import messagingService, { ISubscriber, MessagingService, IMessage } from '../services/messaging.service';
import { ICardCoords } from '@thefirstspine/types-rest';
import AnimationsHelper, { IAnimation, effect } from '../helpers/animations.helper';
import AnimationSpell from '@/components/AnimationSpell.vue';
import AnimationSpellRuin from '@/components/AnimationSpellRuin.vue';
import AnimationSpellFire from '@/components/AnimationSpellFire.vue';
import AnimationSpellAlterTheFate from '@/components/AnimationSpellAlterTheFate.vue';
import AnimationSpellAchievement from '@/components/AnimationSpellAchievement.vue';
import AnimationSpellThunder from '@/components/AnimationSpellThunder.vue';
import AnimationSpellEther from '@/components/AnimationSpellEther.vue';
import AnimationSpellHeal from '@/components/AnimationSpellHeal.vue';
import AnimationSpellReconstruct from '@/components/AnimationSpellReconstruct.vue';
import AnimationSpellPutrefaction from '@/components/AnimationSpellPutrefaction.vue';
import AnimationSpellReplacement from '@/components/AnimationSpellReplacement.vue';
import AnimationConfronts from '@/components/AnimationConfronts.vue';
import AnimationLife from '@/components/AnimationLife.vue';
import AnimationDestroyed from '@/components/AnimationDestroyed.vue';
import roomsService, { IRoomMessage } from '../services/rooms.service';

@Component({
  components: {
    Card,
    CardInfo,
    AnimationSpell,
    AnimationSpellRuin,
    AnimationSpellFire,
    AnimationSpellAlterTheFate,
    AnimationSpellAchievement,
    AnimationSpellThunder,
    AnimationSpellEther,
    AnimationSpellHeal,
    AnimationSpellReconstruct,
    AnimationSpellPutrefaction,
    AnimationSpellReplacement,
    AnimationConfronts,
    AnimationLife,
    AnimationDestroyed,
  },
})
export default class Game extends Vue {

  protected gameId: number = -1;
  protected userId: number = -1;
  protected subscriberAction: ISubscriber|null = null;
  protected subscriberCardChanged: ISubscriber|null = null;
  protected subscriberMessageRoom: ISubscriber|null = null;
  protected currentAnimations: IAnimation[] = [];

  public data() {
    return {
      ready: false,
      cards: [],
      setup: null,
      actions: [],
      messages: [],
      users: [],
      cycle: null,
      account: null,
      boardWidth: 500,
      boardHeight: 500,
      cardScale: 1,
      cardMargins: 0,
      selectedCard: null,
      chosenAction: null,
      chosenHandIndexes: [],
      chosenBoardCoords: [],
      displayedDiscard: null,
      displayedResult: null,
      selectedWizard: null,
      isLootDisplayed: false,
      options: optionsService.options,
      currentDate: new Date(),
      isActionsDisplayed: true,
      isMessagesDisplayed: false,
      isDisplayedGame: false,
      hasInteractionOffCameraTop: false,
      hasInteractionOffCameraBottom: false,
      typedMessage: '',
    };
  }

  public scrollOnGame() {
    this.updateOffCameraIndicators();
  }

  public updateOffCameraIndicators() {
    let hasInteractionOffCameraTop = false;
    let hasInteractionOffCameraBottom = false;
    document.querySelectorAll('.can-do-action').forEach((element: any) => {
      const rect: DOMRect = element.getBoundingClientRect();
      if (rect.y > window.innerHeight) {
        hasInteractionOffCameraBottom = true;
      }
      if (rect.y + rect.height < 0) {
        hasInteractionOffCameraTop = true;
      }
    });

    // We do this in order to fire only required changes
    if (this.$data.hasInteractionOffCameraBottom !== hasInteractionOffCameraBottom) {
      this.$data.hasInteractionOffCameraBottom = hasInteractionOffCameraBottom;
    }
    if (this.$data.hasInteractionOffCameraTop !== hasInteractionOffCameraTop) {
      this.$data.hasInteractionOffCameraTop = hasInteractionOffCameraTop;
    }
  }

  public async mounted() {
    const actionsTypesRegex: Array<{handler: CallableFunction, regex: RegExp}> = [
      {regex: /^spell-heal/, handler: AnimationsHelper.spellHealAnimation.bind(this)},
      {regex: /^spell-thunder/, handler: AnimationsHelper.spellThunderAnimation.bind(this)},
      {regex: /^fpe-4/, handler: AnimationsHelper.spellThunderAnimation.bind(this)},
      {regex: /^spell-reconstruct/, handler: AnimationsHelper.spellReconstructAnimation.bind(this)},
      {regex: /^spell-putrefaction/, handler: AnimationsHelper.spellPutrefactionAnimation.bind(this)},
      {regex: /^spell-replacement/, handler: AnimationsHelper.spellReplacementAnimation.bind(this)},
      {regex: /^spell-ruin/, handler: AnimationsHelper.spellRuinAnimation.bind(this)},
      {regex: /^fpe-17/, handler: AnimationsHelper.spellRuinAnimation.bind(this)},
      {regex: /^spell-alter-the-fate/, handler: AnimationsHelper.spellAlterTheFateAnimation.bind(this)},
      {regex: /^spell-achieve/, handler: AnimationsHelper.spellAchievement.bind(this)},
      {regex: /^spell-fire/, handler: AnimationsHelper.spellFireAnimation.bind(this)},
      {regex: /^spell-ether/, handler: AnimationsHelper.spellEtherAnimation.bind(this)},
      {regex: /^confronts/, handler: AnimationsHelper.confrontsAnimation.bind(this)},
      {regex: /^fpe-11/, handler: AnimationsHelper.confrontsAnimation.bind(this)},
      {regex: /^fpe-20/, handler: AnimationsHelper.confrontsAnimation.bind(this)},
      {regex: /^throw-cards/, handler: AnimationsHelper.throwCardsAnimation.bind(this)},
    ];
    window.addEventListener('scroll', this.scrollOnGame.bind(this));
    // Compute cards sizes
    this.setupSizes(1);
    // Get setup
    this.gameId = parseInt(this.$router.currentRoute.query.gameId as string, 10);
    // Set data
    this.$data.account = await wizardService.getMe();
    // Update
    await this.updateWizard();
    await this.updateCards();
    // Get the users
    this.$data.users = await Promise.all(
      this.$data.setup.users.map((u: IGameUser) => {
        return wizardService.get(u.user);
      }));
    // Setup subscribers
    this.subscriberAction = {
      subject: `${MessagingService.SUBJECT__GAME}:${this.gameId}:action`,
      handler: (message: IMessage) => {
        // Load actions
        this.updateActions();
        // Load cards
        this.updateCards();
        // Play animation
        const method = actionsTypesRegex.find((actionTypesRegex: {handler: CallableFunction, regex: RegExp}) => {
          return actionTypesRegex.regex.test(message.message.type);
        });
        if (method) {
          method.handler(message.message, this.currentAnimations);
        }
      },
    };
    await messagingService.subscribe(this.subscriberAction);
    this.subscriberCardChanged = {
      subject: `${MessagingService.SUBJECT__GAME}:${this.gameId}:cardChanged`,
      handler: (message: IMessage) => {
        Object.keys(message.message.changes).forEach((statChanged: string) => {
          const statValue: string = message.message.changes[statChanged];
          if (statChanged === 'life') {
            AnimationsHelper.lifeAnimation(message.message.gameCard, parseInt(statValue, 10), this.currentAnimations);
          }
          if (statChanged === 'life' && message.message.gameCard.currentStats.life <= 0) {
            AnimationsHelper.destroyedAnimation(message.message.gameCard, this.currentAnimations);
          }
        });
      },
    };
    await messagingService.subscribe(this.subscriberCardChanged);
    this.subscriberMessageRoom = {
      subject: `${MessagingService.SUBJECT__MESSAGE_ROOM}:game-${this.gameId}`,
      handler: (message: IMessage) => {
        this.updateMessages();
      },
    };
    await messagingService.subscribe(this.subscriberMessageRoom);
    // Ready!
    this.$data.ready = true;
    // Load actions
    this.updateActions();
    // Load messages
    this.updateMessages();
    // Update action expiration
    setTimeout(this.updateDate.bind(this), 500);
    // Scroll to the bottom
    setTimeout(() => { window.scrollTo(0, document.body.scrollHeight); }, 1);
    setTimeout(() => {
      this.$data.isDisplayedGame = true;
    }, 3000);
    // Deactivate back button
    document.addEventListener('backbutton', this.backButton, false);
  }

  public backButton(e: Event) {
    e.preventDefault();
  }

  public updateDate() {
    this.$data.currentDate = Date.now();
    setTimeout(this.updateDate.bind(this), 500);
  }

  public get expirationTime(): number {
    const expireAction: IGameAction<any>|undefined = this.$data.actions.find((a: IGameAction<any>) => a.expiresAt);
    if (!expireAction || !expireAction.expiresAt) {
      return -1;
    }

    return Math.floor((expireAction.expiresAt - this.$data.currentDate) / 1000);
  }

  public get totalTime(): number {
    const expireAction: IGameAction<any>|undefined = this.$data.actions.find((a: IGameAction<any>) => a.expiresAt);
    if (!expireAction || !expireAction.expiresAt) {
      return -1;
    }
    return Math.floor((expireAction.expiresAt - expireAction.createdAt) / 1000);
  }

  public resetSelections() {
    this.$data.chosenHandIndexes = [];
    this.$data.chosenBoardCoords = [];
    setTimeout(() => {
      this.updateOffCameraIndicators();
    }, 10);
  }

  public async updateActions() {
    this.$data.actions = await arenaService.getActions(this.gameId);
  }

  public async updateMessages() {
    this.$data.messages = await roomsService.getMessagesInGameRoom(this.gameId);
  }

  public async updateCards() {
    this.$data.setup = await arenaService.getGame(this.gameId);
    this.$data.cards = await arenaService.getCards(this.gameId);
    if (this.$data.setup.status !== 'active') {
      // Display result
      this.$data.displayedResult = this.$data.setup.result.find((r: IGameResult) => r.user === this.userId);
      // On FPE, remove tip option
      if (this.$data.setup.gameType === 'fpe') {
        optionsService.options.tip_game = true;
        optionsService.saveOptions();
      }
    }
  }

  public async updateWizard() {
    const wizard: IWizard = await wizardService.getMe();
    this.userId = wizard.id;
  }

  public destroyed() {
    if (this.subscriberAction) {
      messagingService.unsubscribe(this.subscriberAction);
    }
    // Remove scroll event listener
    window.removeEventListener('scroll', this.scrollOnGame.bind(this));
    // Reactivate back button
    document.removeEventListener('backbutton', this.backButton, false);
  }

  public sendMessage() {
    if (this.$data.typedMessage.replace(/ /, '').length > 0) {
      const message: string = this.$data.typedMessage;
      this.$data.typedMessage = '';
      roomsService.postMessageInGameRoom(this.gameId, message);
    }
  }

  public renderMessage(message: string) {
    try {
      const json: any = JSON.parse(message);
      return json[this.$i18n.locale];
    } catch (e) {
      return message;
    }
  }

  public getCardStyle(card: IGameCard) {
    const colorsSheme: string[] = optionsService.options.cards_color_scheme ?
      optionsService.options.cards_color_scheme.split(':') :
      ['ORANGERED', 'SLATEBLUE'];
    const style: any = {};
    if (card.location === 'board' && card.coords) {
      style.top = `${((100 / 7) * card.coords.y)}%`;
      style.left = `${((100 / 7) * card.coords.x)}%`;
    }
    if (card.location === 'discard') {
      if (card.user === this.$data.setup.users[0].user) {
        style.top = `-${(100 / 7) * 3}%`;
        style.left = `${50 - (100 / 7 / 2)}%`;
      }
      if (card.user === this.$data.setup.users[1].user) {
        style.top = `${100 + ((100 / 7) * 2)}%`;
        style.left = `${50 - (100 / 7 / 2)}%`;
      }
    }
    if (card.location === 'hand') {
      const cardIndex: number = this.$data.cards
        .filter((c: IGameCard) => c.location === 'hand')
        .findIndex((c: IGameCard) => c === card);
      if (card.user === this.$data.setup.users[0].user) {
        style.top = `-${(100 / 7) * 1.5}%`;
        style.left = `${100 - (100 / 7) * (cardIndex + 1.5)}%`;
      }
      if (card.user === this.$data.setup.users[1].user) {
        style.top = `${100 + ((100 / 7) * .5)}%`;
        style.left = `${(cardIndex + .5) * (100 / 7)}%`;
      }
    }
    if (card.user === this.$data.account.id) {
      style.backgroundColor = colorsSheme[1];
    } else {
      style.backgroundColor = colorsSheme[0];
    }
    if (card.user === this.$data.setup.users[0].user) {
      style.transform = `scale(${this.$data.cardScale}) rotate(180deg)`;
    }
    return style;
  }

  public getStyleOfAnimation(animation: IAnimation) {
    const style: any = {
      position: 'absolute',
    };

    if (animation.metadata && animation.metadata.coords) {
      // Get coords
      const x: number = parseInt(animation.metadata.coords.split('-')[0], 10);
      const y: number = parseInt(animation.metadata.coords.split('-')[1], 10);

      // TODO
      style.top = (100 / 7 * (y + .5)) + '%';
      style.left = (100 / 7 * (x + .5)) + '%';
    }

    if (this.$data.setup.users[0].user === this.$data.account.id) {
      style.transform = 'rotate(-180deg)';
    }

    return style;
  }

  public choseAction(action: IGameAction<any>) {
    this.$data.chosenAction = action;
    if (action.interaction.type === 'pass') {
      this.sendPass();
    }
    setTimeout(() => {
      this.updateOffCameraIndicators();
    }, 10);
  }

  public skipDisard() {
    this.$data.chosenAction = this.$data.actions[0];
    this.$data.chosenHandIndexes = [];
    this.sendDiscard();
  }

  public cancelAction() {
    this.$data.chosenAction = null;
    this.resetSelections();
  }

  public canDoActionOnBoard(coords: ICardCoords): boolean {
    // Get out on none chosen action
    if (!this.$data.chosenAction || !this.$data.chosenAction.interaction) {
      return false;
    }

    // Get the card coords
    const coordsStr: string = `${coords.x}-${coords.y}`;

    // Action putCardOnBoard
    if (
      this.$data.chosenAction.interaction.type === 'putCardOnBoard' &&
      this.$data.chosenHandIndexes.length === 1
    ) {
      return this.$data.chosenAction.interaction.params.boardCoords.includes(coordsStr);
    }

    // Action moveCardOnBoard
    if (
      this.$data.chosenAction.interaction.type === 'moveCardOnBoard' &&
      this.$data.chosenBoardCoords.length === 1
    ) {
      return this.$data.chosenAction.interaction.params.possibilities
        .find((c: IInteractionMoveCardOnBoardPossibility) => {
          return c.boardCoordsFrom === this.$data.chosenBoardCoords[0];
        })
        ?.boardCoordsTo.includes(coordsStr);
    }

    // Action choseSquareOnBoard
    if (this.$data.chosenAction.interaction.type === 'choseSquareOnBoard') {
      return this.$data.chosenAction.interaction.params.boardCoords.find((c: string) => {
        return c === coordsStr;
      });
    }

    // Other actions are not handled
    return false;
  }

  public canDoActionOnCard(card: IGameCard): boolean {
    // Get out on none chosen action
    if (!this.$data.chosenAction || !this.$data.chosenAction.interaction) {
      return false;
    }

    // Get card hand index
    const cardHandIndex: number = this.$data.cards
      .filter((c: IGameCard) => c.location === 'hand')
      .findIndex((c: IGameCard) => c === card);

    // Get the card coords
    const cardCoords: string = card.coords ?
      `${card.coords.x}-${card.coords.y}` :
      `0-0`;

    // Action moveCardToDiscard
    if (this.$data.chosenAction.interaction.type === 'moveCardsToDiscard') {
      // Guard for non-handled card
      if (card.location !== 'hand') {
        return false;
      }
      // Guard for maximum cards
      return this.$data.chosenAction.interaction.params.handIndexes.includes(cardHandIndex) &&
        !this.$data.chosenHandIndexes.includes(cardHandIndex);
    }

    // Action putCardOnBoard
    if (
      this.$data.chosenAction.interaction.type === 'putCardOnBoard' &&
      this.$data.chosenHandIndexes.length === 0
    ) {
      // Guard for non-handled card
      if (card.location !== 'hand') {
        return false;
      }
      return this.$data.chosenAction.interaction.params.handIndexes.includes(cardHandIndex);
    }

    // Action moveCardOnBoard
    if (
      this.$data.chosenAction.interaction.type === 'moveCardOnBoard' &&
      this.$data.chosenBoardCoords.length === 0
    ) {
      if (card.location !== 'board') {
        return false;
      }
      return !!this.$data.chosenAction.interaction.params.possibilities
        .find((c: IInteractionMoveCardOnBoardPossibility) => {
          return c.boardCoordsFrom === cardCoords;
        });
    }

    // Action selectCouple
    if (
      this.$data.chosenAction.interaction.type === 'selectCoupleOnBoard' &&
      this.$data.chosenBoardCoords.length === 0
    ) {
      if (card.location !== 'board') {
        return false;
      }
      return !!this.$data.chosenAction.interaction.params.possibilities
        .find((c: IInteractionMoveCardOnBoardPossibility) => {
          return c.boardCoordsFrom === cardCoords;
        });
    }

    if (
      this.$data.chosenAction.interaction.type === 'selectCoupleOnBoard' &&
      this.$data.chosenBoardCoords.length === 1
    ) {
      if (card.location !== 'board') {
        return false;
      }
      return this.$data.chosenAction.interaction.params.possibilities
        .find((c: IInteractionMoveCardOnBoardPossibility) => {
          return c.boardCoordsFrom === this.$data.chosenBoardCoords[0];
        })
        ?.boardCoordsTo.includes(cardCoords);
    }

    if (
      this.$data.chosenAction.interaction.type === 'choseCardOnBoard'
    ) {
      if (card.location !== 'board') {
        return false;
      }
      return this.$data.chosenAction.interaction.params.boardCoords.includes(cardCoords);
    }

    // Other actions are not handled
    return false;
  }

  public isCardSelected(card: IGameCard) {
    // Get card hand index
    const cardHandIndex: number = this.$data.cards
      .filter((c: IGameCard) => c.location === 'hand')
      .findIndex((c: IGameCard) => c === card);

    // Get the card coords
    const cardCoords: string = card.coords ?
      `${card.coords.x}-${card.coords.y}` :
      `0-0`;

    if (
      this.$data.chosenHandIndexes.includes(cardHandIndex) ||
      this.$data.chosenBoardCoords.includes(cardCoords)
    ) {
      return true;
    }

    return false;
  }

  public clickOn(card: IGameCard) {
    // Get out on none chosen action
    if (!this.$data.chosenAction || !this.$data.chosenAction.interaction) {
      return false;
    }

    // Get card hand index
    const cardHandIndex: number = this.$data.cards
      .filter((c: IGameCard) => c.location === 'hand')
      .findIndex((c: IGameCard) => c === card);

    // Get the card coords
    const cardCoords: string = card.coords ?
      `${card.coords.x}-${card.coords.y}` :
      `0-0`;

    // Action moveCardToDiscard
    if (this.$data.chosenAction.interaction.type === 'moveCardsToDiscard') {
      // Guard for non-handled card
      if (card.location !== 'hand') {
        return false;
      }
      // Guard for maximum cards
      if (this.$data.chosenHandIndexes.includes(cardHandIndex)) {
        this.$data.chosenHandIndexes = this.$data.chosenHandIndexes.filter((i: number) => i !== cardHandIndex);
        return;
      }
      if (this.$data.chosenAction.interaction.params.handIndexes.includes(cardHandIndex)) {
        window.navigator.vibrate([30]);
        this.$data.chosenHandIndexes.push(cardHandIndex);
      }
      return;
    }

    // Action putCardOnBoard
    if (
      this.$data.chosenAction.interaction.type === 'putCardOnBoard' &&
      this.$data.chosenHandIndexes.length === 0 &&
      this.canDoActionOnCard(card)
    ) {
      window.navigator.vibrate([30]);
      this.$data.chosenHandIndexes.push(cardHandIndex);
      setTimeout(() => {
        this.updateOffCameraIndicators();
      }, 10);
      return;
    }
    if (
      this.$data.chosenAction.interaction.type === 'putCardOnBoard' &&
      this.$data.chosenHandIndexes.length === 1 &&
      this.$data.chosenHandIndexes.includes(cardHandIndex)
    ) {
      this.$data.chosenHandIndexes = this.$data.chosenHandIndexes.filter((i: number) => i !== cardHandIndex);
      setTimeout(() => {
        this.updateOffCameraIndicators();
      }, 10);
      return;
    }

    // Action moveCardOnBoard
    if (
      this.$data.chosenAction.interaction.type === 'moveCardOnBoard' &&
      this.$data.chosenHandIndexes.length === 0 &&
      this.canDoActionOnCard(card)
    ) {
      window.navigator.vibrate([30]);
      this.$data.chosenBoardCoords.push(cardCoords);
      setTimeout(() => {
        this.updateOffCameraIndicators();
      }, 10);
      return;
    }
    if (
      this.$data.chosenAction.interaction.type === 'moveCardOnBoard' &&
      this.$data.chosenBoardCoords.length === 1 &&
      this.$data.chosenBoardCoords[0] === cardCoords
    ) {
      this.resetSelections();
      return;
    }

    // Action selectCoupleOnBoard
    if (
      this.$data.chosenAction.interaction.type === 'selectCoupleOnBoard' &&
      this.$data.chosenBoardCoords.length === 0 &&
      this.canDoActionOnCard(card)
    ) {
      window.navigator.vibrate([30]);
      this.$data.chosenBoardCoords.push(cardCoords);
      return;
    }
    if (
      this.$data.chosenAction.interaction.type === 'selectCoupleOnBoard' &&
      this.$data.chosenBoardCoords.length === 1 &&
      this.$data.chosenBoardCoords[0] === cardCoords
    ) {
      this.resetSelections();
      return;
    }
    if (
      this.$data.chosenAction.interaction.type === 'selectCoupleOnBoard' &&
      this.$data.chosenBoardCoords.length === 1 &&
      this.$data.chosenBoardCoords[0] !== cardCoords
    ) {
      window.navigator.vibrate([30]);
      arenaService.respondToAction(
        this.gameId,
        this.$data.chosenAction.type,
        {
          boardCoordsFrom: this.$data.chosenBoardCoords[0],
          boardCoordsTo: cardCoords,
        });
      this.resetSelections();
      this.clearActions();
      return;
    }

    // Action choseCardOnBoard
    if (
      this.$data.chosenAction.interaction.type === 'choseCardOnBoard' &&
      this.canDoActionOnCard(card)
    ) {
      window.navigator.vibrate([30]);
      arenaService.respondToAction(
        this.gameId,
        this.$data.chosenAction.type,
        {
          boardCoords: cardCoords,
        });
      this.resetSelections();
      this.clearActions();
      return;
    }

    // Other actions are not handled
    return false;
  }

  public clickOnBoard(coords: ICardCoords) {
    // Get out on none chosen action
    if (!this.$data.chosenAction || !this.$data.chosenAction.interaction) {
      return false;
    }

    // Get the card coords
    const coordsStr: string = `${coords.x}-${coords.y}`;

    // Action putCardOnBoard
    if (
      this.$data.chosenAction.interaction.type === 'putCardOnBoard' &&
      this.$data.chosenHandIndexes.length === 1 &&
      this.$data.chosenAction.interaction.params.boardCoords.includes(coordsStr)
    ) {
      window.navigator.vibrate([30]);
      arenaService.respondToAction(
        this.gameId,
        this.$data.chosenAction.type,
        {
          handIndex: this.$data.chosenHandIndexes[0],
          boardCoords: coordsStr,
        });
      this.resetSelections();
      this.clearActions();
      return;
    }

    // Action moveCardOnBoard
    if (
      this.$data.chosenAction.interaction.type === 'moveCardOnBoard' &&
      this.$data.chosenBoardCoords.length === 1 &&
      this.canDoActionOnBoard(coords)
    ) {
      window.navigator.vibrate([30]);
      arenaService.respondToAction(
        this.gameId,
        this.$data.chosenAction.type,
        {
          boardCoordsFrom: this.$data.chosenBoardCoords[0],
          boardCoordsTo: coordsStr,
        });
      this.resetSelections();
      this.clearActions();
      return;
    }

    // Action choseSquareOnBoard
    if (
      this.$data.chosenAction.interaction.type === 'choseSquareOnBoard' &&
      this.canDoActionOnBoard(coords)
    ) {
      window.navigator.vibrate([30]);
      arenaService.respondToAction(
        this.gameId,
        this.$data.chosenAction.type,
        {
          boardCoords: coordsStr,
        });
      this.resetSelections();
      this.clearActions();
      return;
    }

    // Other actions are not handled
    return false;
  }

  public getBoardStyle() {
    if (!this.$data.setup) {
      return;
    }
    const style: any = {};
    if (this.$data.setup.users[0].user === this.$data.account.id) {
      style.transform = 'rotate(180deg)';
    }
    return style;
  }

  public getMessageStyle(message: IRoomMessage) {
    const colorsSheme: string[] = optionsService.options.cards_color_scheme ?
      optionsService.options.cards_color_scheme.split(':') :
      ['ORANGERED', 'SLATEBLUE'];

    const style = {
      color: parseInt(message.user.toString(), 10) === this.userId ? colorsSheme[1] : colorsSheme[0],
    };

    return style;
  }

  public setupSizes(zoom: number) {
    const screenSizeL: number = window.outerWidth * zoom;
    const cardSize: number = Math.round(screenSizeL / 7);
    const cardScale: number = cardSize / 500;
    const cardMargins: number = 250 - 250 * cardScale;
    this.$data.cardScale = cardScale;
    this.$data.cardMargins = cardMargins;
    this.$data.boardWidth = screenSizeL;
    this.$data.boardHeight = screenSizeL + cardSize * 7;
  }

  public longclickOn(card: IGameCard) {
    window.navigator.vibrate([10, 30, 10]);
    this.$data.selectedCard = card;
  }

  public get canDiscard(): boolean {
    if (!this.$data.chosenAction) {
      return false;
    }

    if (this.$data.chosenAction.interaction.type !== 'moveCardsToDiscard') {
      return false;
    }

    if (this.$data.chosenHandIndexes.length < this.$data.chosenAction.interaction.params.min) {
      return false;
    }

    if (this.$data.chosenHandIndexes.length > this.$data.chosenAction.interaction.params.max) {
      return false;
    }

    return true;
  }

  public sendDiscard() {
    if (this.canDiscard) {
      window.navigator.vibrate([30]);
      arenaService.respondToAction(
        this.gameId,
        this.$data.chosenAction.type,
        {handIndexes: this.$data.chosenHandIndexes});
      this.resetSelections();
      this.clearActions();
    }
  }

  public sendPass() {
    window.navigator.vibrate([30]);
    arenaService.respondToAction(
      this.gameId,
      this.$data.chosenAction.type,
      {pass: true});
    this.clearActions();
  }

  public hasAction(type: string): boolean {
    return !!this.$data.actions.find((a: IGameAction<any>) => a.type === type);
  }

  public displayDiscardOfSelf() {
    window.navigator.vibrate([10, 30, 10]);
    this.$data.displayedDiscard = this.$data.cards
      .filter((c: IGameCard) => c.location === 'discard' && c.user === this.$data.account.id);
  }

  public displayDiscardOfEnemy() {
    window.navigator.vibrate([10, 30, 10]);
    this.$data.displayedDiscard = this.$data.cards
      .filter((c: IGameCard) => c.location === 'discard' && c.user !== this.$data.account.id);
  }

  public clearActions() {
    this.$data.chosenAction = null;
    this.$data.actions = [];
  }

  public getLootName(name: string) {
    return this.$t(`game.loot-${name}`);
  }

  public getCardStyleName(card: IGameCard): string {
    const user: IGameUser|undefined = this.$data.setup?.users.find((u: IGameUser) => u.user === card.user);
    return user && user.style ? user.style : '';
  }

  public getCover(userId: number): string {
    const user: IGameUser|undefined = this.$data.setup?.users.find((u: IGameUser) => u.user === userId);
    return user && user.cover ? user.cover : '';
  }

  public getEnemy(): number {
    const user: IGameUser|undefined = this.$data.setup?.users.find((u: IGameUser) => u.user !== this.userId);
    return user ? user.user : 0;
  }

  public get playerLessLife(): number {
    let life: number = 10;
    this.$data.cards.forEach((c: IGameCard) => {
      life = c.card.type === 'player' && c.currentStats && c.currentStats.life < life
        ? c.currentStats.life
        : life;
    });
    return life;
  }

  public isHolo(card: IGameCard): boolean {
    const user: IWizard|undefined =
      this.$data.users.find((w: any) => w.id === card.user);
    return user ? !!user.items.find((i) => i.name === `holo-${card.card.id}`) : false;
  }

  public isPremium(card: IGameCard): boolean {
    const user: IWizard|undefined =
      this.$data.users.find((w: any) => w.id === card.user);
    return user ? !!user.items.find((i) => i.name === `premium-${card.card.id}`) : false;
  }

  public getAnimations(name: string): IAnimation[] {
    return this.currentAnimations.filter((a) => a.name === name);
  }

  public get activeEffects(): effect[] {
    return this.currentAnimations.map((a) => a.effects).flat();
  }

  public get activeSfx(): string[] {
    const ret: string[] = [];
    this.currentAnimations.forEach((e: IAnimation) => {
      e.sfx.forEach((sfx: string) => {
        if (!ret.includes(sfx)) {
          ret.push(sfx);
        }
      });
    });
    return ret;
  }

  public async concede() {
    const result: boolean = confirm(this.$t('options.concede').toString());
    if (result) {
      await arenaService.concede(this.gameId);
      this.updateActions();
      this.updateCards();
    }
  }
}
