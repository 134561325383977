


















































































































import { Component, Vue } from 'vue-property-decorator';
import authService, { IUser } from '../services/auth.service';
import { IWizard, IWizardItem } from '@thefirstspine/types-arena';
import wizardService from '../services/wizard.service';
import { IDeck, ICard } from '@thefirstspine/types-rest';
import restService from '../services/rest.service';
import Card from '../components/Card.vue';
import CardInfo from '../components/CardInfo.vue';
import optionsService from '../services/options.service';

@Component({
  components: {
    Card,
    CardInfo,
  },
})
export default class Cards extends Vue {
  public data() {
    return {
      ready: false,
      decks: [],
      selectedDeck: null,
      model: null,
      cardStyle: '',
      displayCovers: false,
      sortBy: 'type',
      selectedCard: null,
      filteredDeckType: '*',
      onlyDecks: null,
      mode: 'explore',
      options: optionsService.options,
    };
  }

  public async mounted() {
    // Select appropriate mode
    this.$data.mode = this.$router.currentRoute.query.mode ? this.$router.currentRoute.query.mode : 'explore';
    this.$data.filteredDeckType =
      this.$router.currentRoute.query.filteredDeckType ? this.$router.currentRoute.query.filteredDeckType : '*';
    this.$data.onlyDecks =
      this.$router.currentRoute.query.onlyDecks ?
      this.$router.currentRoute.query.onlyDecks.toString().split(',') :
      null;

    // Get the wizard data
    const wizard: IWizard = await wizardService.getMe();
    this.$data.items = wizard.items;

    // Get the deck data
    this.$data.decks = await restService.decks();
    this.$data.selectedDeck = this.filteredDecks[0];

    // Ready!
    this.$data.ready = true;
  }

  public getFilteredDeckName(filter: string): string {
    const map: {[key: string]: string} = {
      '*': 'cards.filters.allTitle',
      'destiny': 'cards.filters.destinyTitle',
      'origin': 'cards.filters.originTitle',
    };
    return map[filter] ? this.$t(map[filter]).toString() : '';
  }

  public getSortByName(filter: string): string {
    const map: {[key: string]: string} = {
      name: 'cards.sort.name',
      type: 'cards.sort.type',
      number: 'cards.sort.number',
    };
    return map[filter] ? this.$t(map[filter]).toString() : '';
  }

  public isHolo(id: string) {
    return this.$data.items.some((item: IWizardItem) => item.name === `holo-${id}`);
  }

  public isPremium(id: string) {
    return this.$data.items.some((item: IWizardItem) => item.name === `premium-${id}`);
  }

  get gameLink(): string {
    const root = '/play';
    const properties = { ...this.$router.currentRoute.query };
    properties[this.$data.selectedDeck.type] = this.$data.selectedDeck.id;
    const fragments: string[] = Object.keys(properties).map((prop) => prop + '=' + properties[prop]);
    return root + '?' + fragments.join('&');
  }

  get filteredDecks(): IDeck[] {
    return this.$data.decks.filter((e: IDeck) => {
      return (this.$data.onlyDecks === null || this.$data.onlyDecks.includes(e.id)) &&
      (this.$data.filteredDeckType === '*' || this.$data.filteredDeckType === e.type);
    });
  }

  get cardsOfSelectedDeck(): Array<{card: ICard, num: number}> {
    const result: {[id: string]: {card: ICard, num: number}} = {};

    // Construct view
    this.$data.selectedDeck.cards.forEach((card: ICard) => {
      if (!result[card.id]) {
        result[card.id] = {card, num: 0};
      }
      result[card.id].num ++;
    });

    // Sort view
    const regex: RegExp = new RegExp(this.$data.search, 'i');
    return Object.keys(result).sort(
        (a: any, b: any) => {
          if (this.$data.sortBy === 'name') {
            const aName: any = result[a].card.name;
            const bName: any = result[b].card.name;
            return aName[this.$i18n.locale.toString()] > bName[this.$i18n.locale.toString()] ? 1 : -1;
          }
          if (this.$data.sortBy === 'type') {
            return result[a].card.type > result[b].card.type ? 1 : -1;
          }
          if (this.$data.sortBy === 'number') {
            return result[a].num > result[b].num ? 1 : -1;
          }
          return 0;
        },
      )
      .filter((k: string) => {
        const name: any = result[k].card.name;
        return regex.test(name[this.$i18n.locale.toString()]);
      })
      .map((k: string) => result[k]);
  }

  get availableStyles(): string[] {
    const styles: string[] = [''];
    const regexStyle: RegExp = new RegExp('style-');
    this.$data.items.forEach((item: any) => {
      if (regexStyle.test(item.name)) {
        styles.push(item.name.replace('style-', ''));
      }
    });
    return styles;
  }

  get availableCovers(): string[] {
    const covers: string[] = [];
    const regexCover: RegExp = new RegExp('cover-');
    this.$data.items.forEach((item: any) => {
      if (regexCover.test(item.name)) {
        covers.push(item.name.replace('cover-', ''));
      }
    });
    return covers;
  }
}
