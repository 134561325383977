import { ApiService } from './api.service';
import authService from './auth.service';

export class CalendarService extends ApiService {

  public async currentEvents() {
    const date = (new Date()).toISOString();
    const result = await fetch(`${process.env.VUE_APP_CALENDAR_URL}/events?filter=datetimeFrom||lt||${date}&filter=datetimeTo||gt||${date}`);
    const jsonResult = await result.json();
    return jsonResult;
  }

  public async currentCycle() {
    const result = await fetch(`${process.env.VUE_APP_CALENDAR_URL}/cycles/current`);
    const jsonResult = await result.json();
    return jsonResult;
  }

  public async scheduledLoot() {
    const result = await fetch(
      `${process.env.VUE_APP_CALENDAR_URL}/scheduled-loots/current`,
      {
        headers: { Authorization: `Bearer ${authService.getAccessToken()}` },
      });
    const jsonResult = await result.json();
    return jsonResult;
  }

}

export default new CalendarService();
