

































import { Component, Vue } from 'vue-property-decorator';
import Card from '@/components/Card.vue';
import optionsService from '@/services/options.service';

@Component({
  components: {
    Card,
  },
})
export default class Home extends Vue {

  public data() {
    return {
      realms: [
        {
          name: 'refuge',
          title: 'Refuge',
          language: 'fr',
          region: 'eu',
          level: 'low',
        },
        {
          name: 'sanctuaire',
          title: 'Sanctuaire',
          language: 'fr',
          region: 'eu',
          level: 'high',
        },
        {
          name: 'shelter',
          title: 'Shelter',
          language: 'en',
          region: 'eu',
          level: 'low',
        },
        {
          name: 'sanctuary',
          title: 'Sanctuary',
          language: 'en',
          region: 'eu',
          level: 'high',
        },
      ],
    };
  }

  public choseRealm(realm: string) {
    optionsService.options.realm = realm;
    optionsService.saveOptions();
    this.$router.push('/');
  }

}
