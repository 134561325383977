




































import { Component, Vue } from 'vue-property-decorator';
import restService from '../services/rest.service';
import arenaService from '../services/arena.service';
import messagingService, { MessagingService, ISubscriber, IMessage } from '../services/messaging.service';
import Card from '@/components/Card.vue';
import optionsService from '../services/options.service';

@Component({
  components: {
    Card,
  },
})
export default class Matchmaking extends Vue {

  private queueSubscriber: ISubscriber|null = null;
  private gameSubscriber: ISubscriber|null = null;
  private queueRefreshTimeout: any = -1; // any here because TS's timeout is waiting for a NodeJS.Timeout
  private currentTimeTimeout: any = -1; // any here because TS's timeout is waiting for a NodeJS.Timeout
  private currentTimeWaiting: number = 0;
  private skipMatchmaking: boolean = false;

  public async mounted() {
    // Subscribe to the right channel in the messenging service
    this.gameSubscriber = await messagingService.subscribe({
      subject: MessagingService.SUBJECT__GAME_CREATED,
      handler: (m: IMessage) => {
        if (m.message.gameId) {
          this.startGame(m.message.gameId);
        }
      },
    });

    // Get the cards from the destiny
    this.$data.destiny = this.$router.currentRoute.query.destiny;

    // Get the required data for the matchmaking screen
    const queue = await arenaService.getQueue(this.$data.key);
    this.$data.gameType = await restService.gameType(queue.gameTypeId);

    // Set the advice ID
    this.$data.adviceId = Math.floor(Math.random() * (this.$data.advices.length - 1));

    this.refreshQueue();
    this.refreshTime();
  }

  public async refreshQueue() {
    // When not in a queue anymore, ignore
    if (this.skipMatchmaking) {
      return;
    }

    // Refresh the token
    const response: any = await arenaService.refreshQueueAsk(this.$data.key);
    if (response.users.length > 1) {
      this.$data.isQueueNotEmpty = true;
    }
    this.queueRefreshTimeout = setTimeout(
      () => {
        this.refreshQueue();
      }, 30 * 1000, // 30 seconds
    );
  }

  public refreshTime() {
    // When not in a queue anymore, ignore
    if (this.skipMatchmaking) {
      return;
    }

    this.currentTimeWaiting ++;

    if (this.currentTimeWaiting % 30 === 0) {
      this.$data.adviceId = Math.floor(Math.random() * (this.$data.advices.length));
    }

    this.queueRefreshTimeout = setTimeout(
      () => {
        this.refreshTime();
      }, 1000,
    );
  }

  public async startGame(gameId: number) {
    this.skipMatchmaking = true;
    if (this.queueSubscriber !== null) {
      messagingService.unsubscribe(this.queueSubscriber);
    }
    if (this.gameSubscriber !== null) {
      messagingService.unsubscribe(this.gameSubscriber);
    }
    this.$router.push(`/game?gameId=${gameId}`);
  }

  public async leaveMatchmaking() {
    arenaService.quitQueue(this.$data.key);
    this.$router.push('/');
  }

  public data() {
    // Get data from router
    const key = this.$router.currentRoute.query.key;

    return {
      key,
      gameType: null,
      destiny: null,
      cards: [],
      adviceId: null,
      isQueueNotEmpty: false,
      advices: [
        {
          image: 'matchmaking-guardian.png',
          message: this.$t('matchmaking.advice1'),
        },
        {
          image: 'matchmaking-tournaments.png',
          message: this.$t('matchmaking.advice2'),
        },
        {
          image: 'matchmaking-rewards.png',
          message: this.$t('matchmaking.advice3'),
        },
        {
          image: 'matchmaking-curse.png',
          message: this.$t('matchmaking.advice4'),
        },
        {
          image: 'matchmaking-story.png',
          message: this.$t('matchmaking.advice5'),
        },
        {
          image: 'matchmaking-rewards.png',
          message: this.$t('matchmaking.advice6'),
        },
        {
          image: 'matchmaking-guardian.png',
          message: this.$t('matchmaking.advice7'),
        },
        {
          image: 'matchmaking-rewards.png',
          message: this.$t('matchmaking.advice8'),
        },
        {
          image: 'matchmaking-story.png',
          message: this.$t('matchmaking.advice9'),
        },
      ],
      options: optionsService.options,
    };
  }

  public get formattedTimeWait(): string {
    const hours   = Math.floor(this.currentTimeWaiting / 3600);
    const minutes = Math.floor((this.currentTimeWaiting - (hours * 3600)) / 60);
    const seconds = this.currentTimeWaiting - (hours * 3600) - (minutes * 60);
    return `${(minutes < 10 ? '0' + minutes : minutes)}:${(seconds < 10 ? '0' + seconds : seconds)}`;
  }

  protected shuffle(array: any[]) {
    let j: number;
    let x: any;
    let i: number;
    for (i = array.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = array[i];
        array[i] = array[j];
        array[j] = x;
    }
    return array;
  }

}
