




import { Component, Prop, Vue } from 'vue-property-decorator';
import { IAnimation } from '../helpers/animations.helper';

@Component
export default class Card extends Vue {
  @Prop() private animation?: IAnimation;
}
