





































































import { Component, Prop, Vue } from 'vue-property-decorator';
import FormattedTextCard from '@/components/FormattedTextCard.vue';
import { ICardStat, ICard } from '@thefirstspine/types-rest';

@Component({
  components: {
    FormattedTextCard,
  },
})
export default class Card extends Vue {
  @Prop() public card?: ICard;
  @Prop() public stats?: ICardStat;
  @Prop() public micro?: boolean;
  @Prop() public holo?: boolean;
  @Prop() public premium?: boolean;
  @Prop() public color?: string;
  @Prop() public cardStyle?: string;
}
