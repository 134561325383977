















import { IShopItem } from '@thefirstspine/types-rest';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class ShopItemPrice extends Vue {

  @Prop() public price?: Array<{
    num: number;
    currency: 'eur' | 'shards' | string;
  }>;

  public toCurrency(num: number): string {
    if (Math.ceil(num) === num) {
      return num.toString(10);
    }
    return num.toFixed(2);
  }

}
