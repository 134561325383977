















import { Component, Vue } from 'vue-property-decorator';
import Card from '@/components/Card.vue';
import authService from './../services/auth.service';
import optionsService from '../services/options.service';

@Component({
  components: {
    Card,
  },
})
export default class Home extends Vue {
}
