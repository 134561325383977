import { ApiService } from './api.service';
import authService from './auth.service';

/**
 * Handle messages from the messaging service
 */
export class MessagingService extends ApiService {

  /**
   * The host
   */
  public static readonly HOST: string = `${process.env.VUE_APP_MESSAGING_WS}`;

  public static readonly SUBJECT__GAME: string = 'TheFirstSpine:game';
  public static readonly SUBJECT__GAME_CREATED: string = 'TheFirstSpine:game:created';
  public static readonly SUBJECT__MESSAGE_ROOM: string = 'TheFirstSpine:messageRoom';
  public static readonly SUBJECT__ACCOUNT: string = 'TheFirstSpine:account';

  /**
   * Indicates if the socket is opened & the player logged
   */
  public connected: boolean = false;

  /**
   * The main socket
   */
  private socket: WebSocket;

  /**
   * Subscribers added to the service
   */
  private subscribers: ISubscriber[] = [];

  /**
   * Subscribers added to the service
   */
  private subscribersAwaitingConnection: ISubscriber[] = [];

  constructor() {
    super();
    this.socket = new WebSocket(MessagingService.HOST);
    setInterval(this.ping.bind(this), 15 * 1000);
  }

  /**
   * Wait for an open connection and log the user
   */
  public async connect() {
    // tslint:disable-next-line: no-console
    console.log('Connecting socket...');
    this.socket = new WebSocket(MessagingService.HOST);
    if (this.socket.readyState === WebSocket.OPEN) {
      this.login();
    } else {
      // Listen for events
      this.socket.addEventListener('open', (e) => {
        // tslint:disable-next-line: no-console
        console.log('Socket opened.');
        this.login();
      });
    }

    this.socket.addEventListener('message', (e) => {
      const data: any = JSON.parse(e.data);
      if (data && data.to && data.message && data.subject) {
        // We have a message
        this.subscribers.forEach((subscriber: ISubscriber) => {
          if (subscriber.subject === data.subject) {
            subscriber.handler(data as IMessage);
          }
        });
      }
    });

    return new Promise((resolve, reject) => {
      const messageHandler = (e: MessageEvent) => {
        const loggedData = JSON.parse(e.data);
        if (loggedData && loggedData.logged) {
          if (this.socket) {
            this.socket.removeEventListener('message', messageHandler);
          }
          // tslint:disable-next-line: no-console
          console.log('Logged in.');
          this.connected = true;
          this.subscribersAwaitingConnection.forEach((s: ISubscriber) => this.subscribe(s));
          this.subscribersAwaitingConnection = [];
          resolve();
        }
      };
      if (this.socket) {
        this.socket.addEventListener('message', messageHandler);
      }
    });
  }

  /**
   * Subscribe to a subject
   */
  public async subscribe(subscriber: ISubscriber): Promise<ISubscriber> {
    if (!this.connected) {
      // tslint:disable-next-line: no-console
      console.log('Socket not connected yet. Store subscriber.');
      this.subscribersAwaitingConnection.push(subscriber);
      return subscriber;
    }

    this.subscribers.push(subscriber);
    if (this.socket.readyState !== WebSocket.OPEN) {
      // tslint:disable-next-line: no-console
      console.log('Socket is closed. Retrying connection...');
      await this.connect();
      // tslint:disable-next-line: no-console
      console.log('Socket now reopened. Send message.');
    }

    return new Promise((resolve, reject) => {
      const messageHandler = (e: MessageEvent): any => {
        const data = JSON.parse(e.data);
        if (data && data.subscribed === subscriber.subject) {
          if (this.socket) {
            this.socket.removeEventListener('message', messageHandler);
          }
          resolve(subscriber);
        }
      };
      if (this.socket) {
        this.socket.addEventListener('message', messageHandler);
        this.sendMessage('subscribeToSubject', {subject: subscriber.subject});
      }
    });
  }

  /**
   * Subscribe to a subject
   */
  public async unsubscribe(subscriber: ISubscriber): Promise<ISubscriber> {
    this.subscribers = this.subscribers.filter((s: ISubscriber) => s !== subscriber);
    return new Promise((resolve, reject) => {
      const messageHandler = (e: MessageEvent): any => {
        const data = JSON.parse(e.data);
        if (data && data.unsubscribed === subscriber.subject) {
          if (this.socket) {
            this.socket.removeEventListener('message', messageHandler);
          }
          resolve(subscriber);
        }
      };
      if (this.socket) {
        this.socket.addEventListener('message', messageHandler);
        this.sendMessage('unsubscribeToSubject', {subject: subscriber.subject});
      }
    });
  }

  protected ping() {
    if (!this.socket) {
      return;
    }
    this.socket.send(JSON.stringify({
      event: 'ping',
      data: {},
    }));
  }

  /**
   * Send a message to the socket microservice
   * @param event
   * @param params
   */
  protected sendMessage(event: string, params: any) {
    this.socket.send(JSON.stringify({
      event,
      data: params,
    }));
  }

  /**
   * Login to the socket microservice
   */
  protected login() {
    // tslint:disable-next-line: no-console
    console.log('Logging in...');
    this.sendMessage('login', {jwt: authService.getAccessToken()});
  }

}

export interface ISubscriber {
  subject: string;
  handler: CallableFunction;
}

export interface IMessage {
  to: string;
  subject: string;
  message: any;
}

export default new MessagingService();
