export class OptionsService {

  public options: Partial<IOptions> = {
  };

  public defaultOptions: IOptions = {
    display_actions: true,
    play_music: true,
    play_sounds: true,
    cards_color_scheme: 'ORANGERED:SLATEBLUE',
    display_size: '1800x950',
    display_tips: true,
    tip_profile: false,
    tip_shop: false,
    tip_runes: false,
    tip_cards_list: false,
    tip_cards_play: false,
    tip_game_setup: false,
    tip_game: false,
    tip_fpe_2: false,
    tip_fpe_4: false,
    tip_fpe_6: false,
    tip_fpe_8: false,
    tip_fpe_9: false,
    tip_fpe_11: false,
    tip_fpe_15: false,
    tip_fpe_17: false,
    tip_matchmaking: false,
    language: '',
    realm: '',
  };

  constructor() {
    const rawOptions: string|null = localStorage.getItem('options');
    const parsedOptions: any = rawOptions && rawOptions !== '' ? JSON.parse(rawOptions) : {};
    const defaultOptions: any = this.defaultOptions;

    Object.keys(defaultOptions).forEach((key: string) => {
      if (!Object.keys(parsedOptions).includes(key)) {
        parsedOptions[key] = defaultOptions[key];
      }
    });

    this.options = parsedOptions;
    this.saveOptions();
  }

  public saveOptions() {
    localStorage.setItem('options', JSON.stringify(this.options));
  }

}

export interface IOptions {
  display_actions: boolean;
  play_music: boolean;
  play_sounds: boolean;
  cards_color_scheme: string;
  display_size: string;
  display_tips: boolean;
  tip_profile: boolean;
  tip_shop: boolean;
  tip_runes: boolean;
  tip_cards_list: boolean;
  tip_cards_play: boolean;
  tip_game_setup: boolean;
  tip_game: boolean;
  tip_fpe_2: boolean;
  tip_fpe_4: boolean;
  tip_fpe_6: boolean;
  tip_fpe_8: boolean;
  tip_fpe_9: boolean;
  tip_fpe_11: boolean;
  tip_fpe_15: boolean;
  tip_fpe_17: boolean;
  tip_matchmaking: boolean;
  language: string;
  realm: string;
}

export default new OptionsService();
