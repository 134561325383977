














































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import optionsService from '../services/options.service';

@Component
export default class Tips extends Vue {

  @Prop()
  private name?: string;

  public data() {
    return {
      options: optionsService.options,
      websiteUrl: process.env.VUE_APP_WEBSITE_URL,
    };
  }

  public closeTip() {
    if (optionsService.options.hasOwnProperty(`tip_${this.name}`)) {
      const options: any = optionsService.options;
      options[`tip_${this.name}`] = true;
    }
    optionsService.saveOptions();
  }

}
