import { ApiService } from './api.service';
import authService from './auth.service';
import { IApiGetGameResponse,
         IApiRespondToActionResponse,
         IGameCard,
         IGameAction } from '@thefirstspine/types-arena';
import optionsService from './options.service';

export class ArenaService extends ApiService {

  protected static readonly METHOD__CREATE_QUEUE: string = 'createQueue';
  protected static readonly METHOD__GET_QUEUE: string = 'getQueue';
  protected static readonly METHOD__JOIN_QUEUE: string = 'joinQueue';
  protected static readonly METHOD__QUIT_QUEUE: string = 'quitQueue';
  protected static readonly METHOD__REFRESH_QUEUE_ASK: string = 'refreshQueueAsk';
  protected static readonly METHOD__GET_CARDS: string = 'getCards';
  protected static readonly METHOD__GET_ACTIONS: string = 'getActions';
  protected static readonly METHOD__GET_CURRENT_GAME: string = 'getCurrentGame';
  protected static readonly METHOD__GET_GAME: string = 'getGame';
  protected static readonly METHOD__CONCEDE: string = 'concede';
  protected static readonly METHOD__RESPOND_TO_ACTION: string = 'respondToAction';

  // tslint:disable-next-line: no-shadowed-variable
  public async joinQueue(
    key: string,
    chosenDestiny: string,
    chosenOrigin?: string,
    style?: string,
    cover?: string): Promise<any> {
    return this.call<any>({
      method: ArenaService.METHOD__JOIN_QUEUE,
      params: {
        key,
        destiny: chosenDestiny,
        origin: chosenOrigin,
        style,
        cover,
      },
    });
  }

  // tslint:disable-next-line: no-shadowed-variable
  public async createQueue(
    gameTypeId: string,
    theme: string = '',
    modifiers: string[] = [],
    expirationTimeModifier: number = 1,
  ): Promise<any> {
    const params = {
      gameTypeId,
      modifiers,
      expirationTimeModifier,
      theme: theme !== '' ? theme : undefined,
    };
    return this.call<any>({
      method: ArenaService.METHOD__CREATE_QUEUE,
      params,
    });
  }

  // tslint:disable-next-line: no-shadowed-variable
  public async getQueue(
    key: string): Promise<any> {
    return this.call<any>({
      method: ArenaService.METHOD__GET_QUEUE,
      params: {
        key,
      },
    });
  }

  // tslint:disable-next-line: no-shadowed-variable
  public async quitQueue(key: string): Promise<any> {
    return this.call<any>({
      method: ArenaService.METHOD__QUIT_QUEUE,
      params: {
        key,
      },
    });
  }

  // tslint:disable-next-line: no-shadowed-variable
  public async refreshQueueAsk(key: string): Promise<any> {
    return this.call<any>({
      method: ArenaService.METHOD__REFRESH_QUEUE_ASK,
      params: {
        key,
      },
    });
  }

  // tslint:disable-next-line: no-shadowed-variable
  public async getCurrentGame(): Promise<IApiGetGameResponse> {
    return this.call<IApiGetGameResponse>({
      method: ArenaService.METHOD__GET_CURRENT_GAME,
      params: {},
    });
  }

  // tslint:disable-next-line: no-shadowed-variable
  public async getGame(id: number): Promise<IApiGetGameResponse> {
    return this.call<IApiGetGameResponse>({
      method: ArenaService.METHOD__GET_GAME,
      id,
      params: {},
    });
  }

  // tslint:disable-next-line: no-shadowed-variable
  public async concede(id: number): Promise<IApiGetGameResponse> {
    return this.call<IApiGetGameResponse>({
      method: ArenaService.METHOD__CONCEDE,
      id,
      params: {},
    });
  }

  // tslint:disable-next-line: no-shadowed-variable
  public async getCards(id: number): Promise<IGameCard[]> {
    return this.call<IGameCard[]>({
      method: ArenaService.METHOD__GET_CARDS,
      id,
      params: {},
    });
  }

  // tslint:disable-next-line: no-shadowed-variable
  public async getActions(id: number): Promise<Array<IGameAction<any>>> {
    return this.call<Array<IGameAction<any>>>({
      method: ArenaService.METHOD__GET_ACTIONS,
      id,
      params: {},
    });
  }

  // tslint:disable-next-line: no-shadowed-variable
  public async respondToAction(id: number, actionType: string, response: any): Promise<IApiRespondToActionResponse> {
    return this.call<IApiRespondToActionResponse>({
      method: ArenaService.METHOD__RESPOND_TO_ACTION,
      id,
      params: {
        actionType,
        response,
      },
    });
  }

  protected async call<T>(request: IApiRequest): Promise<T> {
    const ret: Response = await fetch(
      `${process.env.VUE_APP_ARENA_REALMS_URL.replace('{realm}', optionsService.options.realm)}/api`,
      {
        method: 'post',
        body: JSON.stringify(this.buildJSONRpcObject(request)),
        headers: {
          'Authorization': `Bearer ${authService.getAccessToken()}`,
          'Content-Type': 'application/json',
        },
      },
    );
    const json = await ret.json();
    if (json.error) {
      throw new Error(json.error.message);
    }

    return json.result as T;
  }

  protected buildJSONRpcObject(request: IApiRequest): IJsonRpcRequest {
    return {
      id: request.id ? request.id : null,
      jsonrpc: '2.0',
      method: request.method,
      params: request.params,
    };
  }
}

export interface IApiRequest {
  method: string;
  id?: number|null;
  params: {
    [key: string]: string|number|object|undefined,
  };
}

export interface IJsonRpcRequest extends IApiRequest {
  id: number|null;
  jsonrpc: '2.0';
}

export default new ArenaService();
