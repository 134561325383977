import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Cards from '../views/Cards.vue';
import Play from '../views/Play.vue';
import Game from '../views/Game.vue';
import Name from '../views/Name.vue';
import Matchmaking from '../views/Matchmaking.vue';
import Profile from '../views/Profile.vue';
import Shop from '../views/Shop.vue';
import About from '../views/About.vue';
import SelectLanguage from '../views/SelectLanguage.vue';
import Options from '../views/Options.vue';
import Social from '../views/Social.vue';
import Tchat from '../views/Tchat.vue';
import PlayChoice from '../views/PlayChoice.vue';
import Private from '../views/Private.vue';
import Runes from '../views/Runes.vue';
import Collection from '../views/Collection.vue';
import SelectRealm from '../views/SelectRealm.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/select-language',
    name: 'select-language',
    component: SelectLanguage,
  },
  {
    path: '/select-realm',
    name: 'select-realm',
    component: SelectRealm,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop,
  },
  {
    path: '/runes',
    name: 'Runes',
    component: Runes,
  },
  {
    path: '/collection',
    name: 'Collection',
    component: Collection,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/cards',
    name: 'Cards',
    component: Cards,
  },
  {
    path: '/play',
    name: 'Play',
    component: Play,
  },
  {
    path: '/private',
    name: 'Private',
    component: Private,
  },
  {
    path: '/play-choice',
    name: 'PlayChoice',
    component: PlayChoice,
  },
  {
    path: '/matchmaking',
    name: 'Matchmaking',
    component: Matchmaking,
  },
  {
    path: '/game',
    name: 'Game',
    component: Game,
  },
  {
    path: '/name',
    name: 'Name',
    component: Name,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/options',
    name: 'Options',
    component: Options,
  },
  {
    path: '/social',
    name: 'Social',
    component: Social,
  },
  {
    path: '/tchat',
    name: 'Tchat',
    component: Tchat,
  },
];

const router = new VueRouter({
  mode: 'abstract',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const element = document.getElementById('onesignal-bell-container');
  if (to.path === '/') {
    if (element) {
      element.style.display = 'block';
    }
  } else {
    if (element) {
      element.style.display = 'none';
    }
  }
  return next();
});

export default router;
