import { render, staticRenderFns } from "./Social.vue?vue&type=template&id=19bb1448&scoped=true&"
import script from "./Social.vue?vue&type=script&lang=ts&"
export * from "./Social.vue?vue&type=script&lang=ts&"
import style0 from "./Social.vue?vue&type=style&index=0&id=19bb1448&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19bb1448",
  null
  
)

export default component.exports