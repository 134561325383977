










































































































import { Component, Vue } from 'vue-property-decorator';
import authService, { IUser } from '../services/auth.service';
import { IWizard } from '@thefirstspine/types-arena';
import wizardService from '../services/wizard.service';
import restService from '../services/rest.service';
import { IGameType } from '@thefirstspine/types-rest';
import arenaService from '../services/arena.service';
import optionsService from '../services/options.service';

@Component({
  components: {
  },
})
export default class Play extends Vue {
  public data() {
    const key = this.$router.currentRoute.query.key;
    const display = this.$router.currentRoute.query.display;
    return {
      key,
      display,
      ready: false,
      queue: null,
      decks: [],
      items: [],
      gameType: null,
      selectedStyle: '',
      selectedCover: '',
      destiny: '',
      origin: '',
      options: optionsService.options,
    };
  }

  public async mounted() {
    // Get URL query
    ['selectedStyle', 'destiny', 'origin'].forEach((q: string) => {
      this.$data[q] = this.$router.currentRoute.query[q] ? this.$router.currentRoute.query[q] : '';
    });

    this.$data.queue = await arenaService.getQueue(this.$data.key);
    this.$data.gameType = await restService.gameType(this.$data.queue.gameTypeId);

    // Ready!
    this.$data.ready = true;

    const wizard: IWizard = await wizardService.getMe();
    if (wizard.name === '') {
      alert(this.$t('play.choseName').toString());
      this.$router.push('/name?redirect=/');
      return;
    }

    // Further data will be loaded
    this.$data.wizard = wizard;
    this.$data.items = this.$data.wizard.items;
    this.$data.decks = await restService.decks();
  }

  public back() {
    this.$router.push('/play-choice');
  }

  public async launch() {
    this.$data.ready = false;
    try {
      await arenaService.joinQueue(
        this.$data.key,
        this.$data.destiny,
        this.$data.origin === '' ? undefined : this.$data.origin,
        this.$data.selectedStyle === '' ? undefined : this.$data.selectedStyle,
        this.$data.selectedCover === '' ? undefined : this.$data.selectedCover,
      );
    } catch (e) {
      alert(e.message);
      this.$data.ready = true;
    }
    this.$router.push('/matchmaking?key=' + this.$data.key);
  }

  public get availableStyles(): string[] {
    const styles: string[] = [''];
    const regexStyle: RegExp = new RegExp('style-');
    this.$data.items.forEach((item: any) => {
      if (regexStyle.test(item.name)) {
        styles.push(item.name.replace('style-', ''));
      }
    });
    return styles;
  }

  public get availableCovers(): string[] {
    const styles: string[] = [''];
    const regexStyle: RegExp = new RegExp('cover-');
    this.$data.items.forEach((item: any) => {
      if (regexStyle.test(item.name)) {
        styles.push(item.name.replace('cover-', ''));
      }
    });
    return styles;
  }

  public get canLaunch(): boolean {
    if (this.$data.gameType.origins?.length > 0) {
      return this.$data.origin !== '' && this.$data.destiny !== '';
    } else {
      return this.$data.destiny !== '';
    }
    return false;
  }

  public get hasOrigin(): boolean {
    return this.$data.gameType.origins?.length > 0;
  }
}
