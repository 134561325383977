























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IWizard } from '@thefirstspine/types-arena';
import restService from '../services/rest.service';
import wizardService from '../services/wizard.service';

@Component({
  components: {
  },
})
export default class PlayerCard extends Vue {

  @Prop() public wizard?: IWizard;

  protected self?: IWizard;

  public async mounted() {
    if (!this.wizard) {
      return;
    }
    this.self = await wizardService.getMe();
    this.$data.title = await restService.triumph(this.wizard.title);
    this.$data.triumphs = await restService.triumphs();
    this.$data.ready = true;
  }

  public data() {
    return {
      title: null,
      ready: false,
      triumphs: [],
      blocked: false,
      websiteUrl: process.env.VUE_APP_WEBSITE_URL,
    };
  }

  public async addAsFriend() {
    if (!this.wizard || !this.self) {
      return false;
    }

    if (this.wizard.id === this.self.id) {
      return false;
    }

    // Block further modifications
    this.$data.blocked = true;

    // Add as friends
    const friends: number[] = this.self.friends;
    friends.push(this.wizard.id);
    await wizardService.setMe({
      friends,
    });

    // Reload and unblock
    this.self = await wizardService.getMe();
    this.$data.blocked = false;
  }

  public async removeFriend() {
    if (!this.wizard || !this.self) {
      return false;
    }

    if (this.wizard.id === this.self.id) {
      return false;
    }

    // Block further modifications
    this.$data.blocked = true;

    // Add as friends
    const friends: number[] = this.self.friends.filter((n: number) => this.wizard && n !== this.wizard.id);
    await wizardService.setMe({
      friends,
    });

    // Reload and unblock
    this.self = await wizardService.getMe();
    this.$data.blocked = false;
  }

  public isFriend(): boolean {
    if (!this.wizard || !this.self) {
      return false;
    }

    return this.self.friends.includes(this.wizard.id);
  }
}
