
































































import { Component, Vue } from 'vue-property-decorator';
import restService from '../services/rest.service';
import wizardService from '../services/wizard.service';
import optionsService from '../services/options.service';
import CollectedItem from '@/components/CollectedItem.vue';
import ShopItemPrice from '@/components/ShopItemPrice.vue';
import { IWizardItem } from '@thefirstspine/types-arena';
import { ILoot, IShopItem } from '@thefirstspine/types-rest';
import shopService from '@/services/shop.service';

@Component({
  components: {
    CollectedItem,
    ShopItemPrice,
  },
})
export default class Runes extends Vue {

  public async mounted() {
    this.$data.wizard = await wizardService.getMe();
    this.$data.shopItems = await restService.shopItems();
  }

  public data() {
    return {
      shopItems: [],
      wizard: null,
      options: optionsService.options,
      availableRunes: ['rune-holo', 'rune-solid', 'rune-ghostly'],
      crafting: false,
      craftedItem: null,
    };
  }

  public countItem(type: string): number {
    const item: IWizardItem|undefined = this.$data.wizard.items.find((i: IWizardItem) => i.name === type);
    return item ? item.num : 0;
  }

  public getShopItemFor(rune: string): IShopItem|undefined {
    return this.$data.shopItems ?
      this.$data.shopItems.find((shopItem: IShopItem) => {
        return shopItem.price.find((p: any) => p.currency === rune) !== undefined;
      }) :
      undefined;
  }

  public canCraft(item: IShopItem): boolean {
    let result = true;
    item.price.forEach((p) => {
      if (this.countItem(p.currency) < p.num) {
        result = false;
      }
    });
    return result;
  }

  public hasPossibilities(item: IShopItem): boolean {
    let result = false;
    item.possibleLoots?.forEach((p: ILoot[]) => {
      if (this.countItem(p[0].name) === 0) {
        result = true;
      }
    });
    return result;
  }

  public async craft(item: IShopItem) {
    this.$data.crafting = true;
    const result = await shopService.exchangePossibility(item.id);
    if (result.status !== true) {
      this.$data.crafting = false;
      alert(result.message);
      return;
    }

    const oldItems: string[] = this.$data.wizard.items
      .reduce((acc: string[], oldItem: IWizardItem) => {
        acc.push(oldItem.name);
        return acc;
      }, []);
    this.$data.wizard = await wizardService.getMe();
    const newItems: string[] = this.$data.wizard.items
      .reduce((acc: string[], newItem: IWizardItem) => {
        acc.push(newItem.name);
        return acc;
      }, []);

    const newItemObtained: string|undefined = newItems.find((newItem: string) => !oldItems.includes(newItem));
    this.$data.craftedItem = newItemObtained;

    setTimeout(() => {
      this.$data.craftedItem = null;
      this.$data.crafting = false;
    }, 1500);
  }
}
