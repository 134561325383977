




















































import { Component, Vue } from 'vue-property-decorator';
import optionsService from '../services/options.service';
import roomsService, { IRoomMessage } from '../services/rooms.service';
import wizardService from '../services/wizard.service';
import messagingService, { MessagingService, IMessage, ISubscriber } from '../services/messaging.service';

@Component({
  components: {
  },
})
export default class Social extends Vue {

  public async mounted() {
    const messages = await roomsService.getMessagesInRealmRoom(optionsService.options.realm as string);
    this.$data.messages = await Promise.all(messages.reverse().map(async (m: IRoomMessage) => {
      const wizard = await wizardService.get(m.user);
      return {
        timestamp: m.timestamp,
        message: m.message,
        wizard,
      };
    }));
    this.messagesScrollDown();

    const subscriberMessage: ISubscriber = {
      subject: `${MessagingService.SUBJECT__MESSAGE_ROOM}:realm-${optionsService.options.realm}`,
      handler: async (message: IMessage) => {
        const wizard = await wizardService.get(message.message.user);
        this.$data.messages.push({
          timestamp: message.message.timestamp,
          message: message.message.message,
          wizard,
        });
        this.messagesScrollDown();
      },
    };
    messagingService.subscribe(subscriberMessage);
  }

  public data() {
    return {
      selectedWizard: null,
      typedMessage: '',
      messages: [],
    };
  }

  public messageKeyUp(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.sendMessage();
    }
  }

  public sendMessage() {
    if (this.$data.typedMessage.replace(/ /, '').length > 0) {
      const message: string = this.$data.typedMessage;
      this.$data.typedMessage = '';
      roomsService.postMessageInRealmRoom(
        optionsService.options.realm as string,
        message);
    }
  }

  public messagesScrollDown() {
    setTimeout(() => {
      const d: any = document.getElementById('tchat-messages');
      d.scrollTop = d.scrollHeight;
    }, 1);
  }

}
