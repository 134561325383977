

















































import { Component, Vue } from 'vue-property-decorator';
import Card from '@/components/Card.vue';
import authService from './../services/auth.service';
import optionsService from '../services/options.service';

@Component({
  components: {
    Card,
  },
})
export default class Login extends Vue {
  public data() {
    return {
      loading: false,
      state: 'login',
      password: '',
      isPasswordHidden: true,
      email: '',
      loginError: null,
      loginMessage: null,
      options: optionsService.options,
    };
  }

  public async login() {
    this.$data.loading = true;
    this.$data.loginError = null;
    try {
      await authService.login(
        this.$data.email,
        this.$data.password,
      );
      this.$data.loading = false;
      this.$router.push('/about');
    } catch (e) {
      this.$data.loginError = 'Vérifiez vos identifiants';
      this.$data.loading = false;
    }
  }

  public loginWithFacebook() {
    // Web context
    const location: string = 'https://www.facebook.com/connect/login_success.html';
    const webWindow: Window|null = window.open(
      `https://www.facebook.com/v6.0/dialog/oauth?client_id=481790999175484&redirect_uri=${location}&scope=email`,
      '_blank',
      'toolbar=no,location=no,directories=no,status=no,' +
      'menubar=no,scrollbars=yes,resizable=yes,width=600,height=800',
    );
    webWindow?.addEventListener('loadstart', async (event: any) => {
        try {
          const url = event.url;
          if (url) {
            const afterCode: string = url.split('?code=')[1];
            const code = afterCode.split('#')[0];
            this.$data.loading = true;
            webWindow.close();
            try {
              await authService.loginWithFacebookCode(code, location);
              this.$router.push('/about');
            } catch (e) {
              this.$data.loginError = 'Vérifiez vos identifiants';
              this.$data.loading = false;
            }
          }
        } catch (e) {
          // nothing
        }
    });
  }
}
