











































































































































import { Component, Vue } from 'vue-property-decorator';
import arenaService from '../services/arena.service';

@Component({
  components: {
  },
})
export default class Cycle extends Vue {

  public data() {
    return {
      currentState: 'home',
      selectedGameType: null,
      selectedSpeed: 1,
      selectedTheme: '',
      selectedModifier: '',
      createdQueue: null,
      privateGameKey: '',
      loading: false,
      gameTypes: [
        {
          id: 'standard',
          name: 'private.standard',
          description: 'private.standardDescription',
          players: 2,
          destinies: 'all',
          origins: 'all',
        },
        {
          id: 'quick',
          name: 'private.quick',
          description: 'private.quickDescription',
          players: 2,
          destinies: 'all',
          origins: 'none',
        },
      ],
    };
  }

  public async createGame() {
    this.$data.loading = true;
    const queue = await arenaService.createQueue(
      this.$data.selectedGameType.id,
      this.$data.selectedTheme,
      [this.$data.selectedModifier],
      parseFloat(this.$data.selectedSpeed),
    );
    this.$data.createdQueue = queue;
    this.$data.loading = false;
  }

  public async joinGame() {
    this.$data.loading = true;
    try {
      const queue = await arenaService.getQueue(this.$data.privateGameKey);
      if (queue) {
        this.$router.push(`/play?key=${queue.key}&display=private`);
        return;
      }
    } catch (e) {
      // nothing
    }
    alert(this.$t('private.errorOnJoin').toString());
    this.$data.loading = false;
  }

}
