










































































































import { Component, Vue } from 'vue-property-decorator';
import Card from '@/components/Card.vue';
import authService from './../services/auth.service';
import optionsService from '../services/options.service';
import wizardService from '../services/wizard.service';

@Component({
  components: {
    Card,
  },
})
export default class Options extends Vue {

  public data() {
    return {
      optionsDisplayed: false,
      optionsState: 'game',
      options: optionsService.options,
    };
  }

  public save() {
    optionsService.saveOptions();
    if (optionsService.options.language && ['fr', 'en'].includes(optionsService.options.language)) {
      wizardService.setMe({
        publicRoom: optionsService.options.language as ('fr'|'en'),
      });
    }
    this.$i18n.locale = optionsService.options.language ? optionsService.options.language : '';
    this.$router.push('/');
  }

  public logout() {
    authService.logout();
    window.location.href = '/';
  }

  public setGameTips(value: boolean) {
    optionsService.options.tip_game_setup = value;
    optionsService.options.tip_matchmaking = value;
    optionsService.options.tip_game = value;
    optionsService.options.tip_fpe_2 = value;
    optionsService.options.tip_fpe_4 = value;
    optionsService.options.tip_fpe_6 = value;
    optionsService.options.tip_fpe_8 = value;
    optionsService.options.tip_fpe_9 = value;
    optionsService.options.tip_fpe_11 = value;
    optionsService.options.tip_fpe_15 = value;
    optionsService.options.tip_fpe_17 = value;
    optionsService.options.tip_cards_play = value;
  }

}
