import { ApiService } from './api.service';
import authService from './auth.service';
import optionsService from './options.service';

export class ShopService extends ApiService {

  constructor() {
    super();
  }

  public async exchangeLoot(id: string): Promise<IExchangeResult> {
    return this.call<IExchangeResult>('exchange/loot', id);
  }

  public async exchangePossibility(id: string): Promise<IExchangeResult> {
    return this.call<IExchangeResult>('exchange/possibility', id);
  }

  public async purchase(id: string): Promise<IPurchaseResult> {
    return this.call<IPurchaseResult>('purchase', id);
  }

  protected async call<T>(type: string, id: string): Promise<T> {
    const ret: Response = await fetch(
      `${process.env.VUE_APP_ARENA_REALMS_URL.replace('{realm}', optionsService.options.realm)}/shop/${type}`,
      {
        method: 'post',
        body: JSON.stringify({
          shopItemId: id,
        }),
        headers: {
          'Authorization': `Bearer ${authService.getAccessToken()}`,
          'Content-Type': 'application/json',
        },
      },
    );

    const json = await ret.json();
    return json;
  }

}

export interface IExchangeResult {
  status: boolean;
  message?: string;
}

export interface IPurchaseResult {
  status: boolean;
  message?: string;
  url?: string;
}

export default new ShopService();
