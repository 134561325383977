






import { Component, Prop, Vue } from 'vue-property-decorator';
import { ICardCoords } from '@thefirstspine/types-rest';
import { IAnimation } from '../helpers/animations.helper';

@Component
export default class Card extends Vue {
  @Prop() private animation?: IAnimation;

  public data() {
    return {
      rotation: 0,
    };
  }

  public mounted() {
    if (
      !this.animation ||
      !this.animation.metadata ||
      !this.animation.metadata.from ||
      !this.animation.metadata.to) {
      return;
    }

    const from: ICardCoords = {
      x: parseInt(this.animation.metadata.from.split('-')[0], 10),
      y: parseInt(this.animation.metadata.from.split('-')[1], 10),
    };
    const to: ICardCoords = {
      x: parseInt(this.animation.metadata.to.split('-')[0], 10),
      y: parseInt(this.animation.metadata.to.split('-')[1], 10),
    };

    if (from.y - 1 === to.y) {
      this.$data.rotation = 180;
    }

    if (from.x - 1 === to.x) {
      this.$data.rotation = -90;
    }

    if (from.y - 1 === to.y) {
      this.$data.rotation = 0;
    }

    if (from.x + 1 === to.x) {
      this.$data.rotation = 90;
    }
  }
}
