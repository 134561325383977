import Vue from 'vue';
import App from './App.vue';
import router from './router';
import fr from './messages/fr';
import en from './messages/en';
import VueI18n from 'vue-i18n';
import VueAudio from './components/VueAudio.vue';
import Tips from './components/Tips.vue';
import PlayerCard from '@/components/PlayerCard.vue';
import './registerServiceWorker';
import solidPancakeService from './services/solid-pancake.service';

declare global {
  interface Window { OneSignal: any; }
}

// tslint:disable: no-console

Vue.config.productionTip = false;
Vue.config.errorHandler = (err, vm, info) => {
  const phases: {[key: string]: string} = {
    'mounted hook (Promise/async)': 'mount',
  };
  const event: string = `componentLifecycle.${(phases[info] ? phases[info] : 'unkown')}`;
  const action: string = err.message;
  console.log(`%cSending ${event} to Solid Pancake`, 'background: #222; color: #bada55');
  console.log(`%c${action}`, 'background: #222; color: #999');
  solidPancakeService.addEvent(
    event,
    'clientError',
    action);
};

// Vue config
Vue.use(VueI18n);

// Global component registration (we need it in the whole app)
Vue.component('VueAudio', VueAudio);
Vue.component('Tips', Tips);
Vue.component('player-card', PlayerCard);

// Create VueI18n instance with options
const i18n = new VueI18n({
  messages: {
    fr,
    en,
  },
});

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

window.addEventListener('load', () => {
  window.OneSignal = window.OneSignal || [];
  window.OneSignal.push(() => {
    window.OneSignal.init({
      appId: process.env.VUE_APP_ONE_SIGNAL_APP_ID,
      subdomainName: process.env.VUE_APP_ONE_SIGNAL_SUBDOMAIN,
      allowLocalhostAsSecureOrigin: process.env.VUE_APP_ENV === 'development' ? true : undefined,
      safari_web_id: '',
      notifyButton: {
        enable: true,
        size: 'small',
      },
    });
  });
});
