import { ApiService } from './api.service';

// tslint:disable: no-console
export class SolidPancakeService extends ApiService {

  protected sessionId: string = '';
  protected events: any[] = [];

  constructor() {
    super();
    window.onunhandledrejection = (e: any) => {
      const event: string = 'unhandledRejection';
      const action: string = e.reason.message;
      console.log(`%cSending ${event} to Solid Pancake`, 'background: #222; color: #bada55');
      console.log(`%c${action}`, 'background: #222; color: #999');
      this.addEvent(
        event,
        'clientError',
        action);
    };
  }

  public async createSession(product: string, label: string = '', version: string = '') {
    try {
      const response: Response = await fetch(
        `${process.env.VUE_APP_SOLID_PANCAKE_URL}/api/session`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            product,
            label,
            version,
          }),
        });

      this.sessionId = await response.text();
      this.events.forEach((e) => this.sendEvent(e));
    } catch (e) {
      // Do nothing
    }
  }

  public addEvent(event: string, category: string = '', action: string = '', label: string = '') {
    if (this.sessionId !== '') {
      this.sendEvent({
        event,
        label,
        category,
        action,
      });
    } else {
      this.events.push({
        event,
        label,
        category,
        action,
      });
    }
  }

  protected sendEvent(event: any) {
    fetch(
      `${process.env.VUE_APP_SOLID_PANCAKE_URL}/api/event`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionId: this.sessionId,
          ...event,
        }),
      });
  }

}

export default new SolidPancakeService();
