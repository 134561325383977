




import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Howl } from 'howler';
import gsap from 'gsap';

@Component
export default class VueAudio extends Vue {
  @Prop() private src?: string;
  @Prop() private volume?: number;
  @Prop() private loop?: boolean;
  @Prop() private playing?: boolean;
  @Prop() private fade?: number;

  /**
   * Main Howl component
   */
  private h: Howl|null = null;

  public mounted() {
    if (!this.src) {
      return;
    }

    this.h = new Howl({
      src: this.src,
      autoplay: this.playing,
      loop: this.loop,
      volume: this.volume,
    });
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
      this.h?.play();
      } else {
      this.h?.pause();
      }
    }, false);
  }

  public destroyed() {
    if (!this.h) {
      return;
    }

    this.h.stop();
    this.h = null;
  }

  private dirty() {
    // Do not set props on nul element
    if (!this.h) {
      return;
    }

    if (this.h.playing() !== this.playing) {
      if (this.playing) {
        this.h.play();
      } else {
        this.h.stop();
      }
    }

    if (this.loop !== undefined) {
      this.h.loop(this.loop);
    }

    if (this.volume !== undefined) {
      if (this.fade === undefined) {
        this.h.volume(this.volume);
      } else {
        const target = {
          volume: this.h.volume(),
        };
        const howl = this.h;
        gsap.to(target, {
          volume: this.volume,
          onUpdate: () => {
            howl.volume(target.volume);
          },
        });
      }
    }
  }

  @Watch('src')
  private srcChanged() { this.dirty(); }

  @Watch('volume')
  private volumeChanged() { this.dirty(); }

  @Watch('loop')
  private loopChanged() { this.dirty(); }

  @Watch('playing')
  private playingChanged() { this.dirty(); }

}
