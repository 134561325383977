












































































































import { Component, Vue } from 'vue-property-decorator';
import restService from '../services/rest.service';
import arenaService from '../services/arena.service';
import { IWizard, IWizardHistoryItem, IWizardItem } from '@thefirstspine/types-arena';
import wizardService from '../services/wizard.service';
import { ITriumph, IAvatar, IShopItem, ILoot } from '@thefirstspine/types-rest';
import moment from 'moment';
import optionsService from '../services/options.service';
import shopService from '../services/shop.service';
import messagingService, { ISubscriber, IMessage } from '../services/messaging.service';
import ShopItemPrice from '@/components/ShopItemPrice.vue';
import websiteService from '@/services/website.service';
import calendarService from '../services/calendar.service';

@Component({
  components: {
    ShopItemPrice,
  },
})
export default class Home extends Vue {

  public async mounted() {
    const wizard: IWizard = await wizardService.getMe();
    const shopItems: IShopItem[] = await restService.shopItems();
    this.$data.wizard = wizard;
    this.$data.shopItems = shopItems;

    // Load available events
    try {
      const events = (await calendarService.currentEvents());
      this.$data.availableEvents = events.map((e: any) => e.type);
    } catch (e) {
      // do nothing
    }
  }

  public getFilterName(name: string): string {
    return this.$t(`shop.categories.${name}Title`).toString();
  }

  public data() {
    return {
      currentFilter: this.$router.currentRoute.query.filter ? this.$router.currentRoute.query.filter : 'featured',
      wizard: null,
      shopItems: [],
      selectedItem: null,
      purchaseStatus: null,
      options: optionsService.options,
      availableEvents: [],
    };
  }

  public alreadyPurchased(itemId: string): boolean {
    if (!this.$data.wizard) {
      return false;
    }

    const shopItem: IShopItem|undefined = this.$data.shopItems.find((s: IShopItem) => s.id === itemId);

    if (!shopItem || !shopItem.oneTimePurchase) {
      return false;
    }

    const firstNotPurchasedItem: ILoot|undefined = shopItem.loots.find((loot: ILoot) => {
      return (this.$data.wizard as IWizard).items
        .find((item: IWizardItem) => item.name === loot.name && item.num > 0) !== undefined;
    });

    return firstNotPurchasedItem !== undefined;
  }

  public toCurrency(num: number): string {
    if (Math.ceil(num) === num) {
      return num.toString(10);
    }
    return num.toFixed(2);
  }

  public async exchange(id: string) {
    try {
      this.$data.purchaseStatus = 'purchasing';
      const result = await shopService.exchangeLoot(id);
      if (!result.status) {
        throw new Error(result.message);
      }
      this.$data.purchaseStatus = 'purchased';
      this.$data.wizard = await wizardService.getMe();
    } catch (e) {
      alert(e.message);
      this.$data.purchaseStatus = null;
    }
  }

  public async purchase(id: string) {
    try {
      // Set the state to "purchasing..."
      this.$data.purchaseStatus = 'purchasing';

      // Get the result
      const result = await shopService.purchase(id);
      if (!result.status || !result.url) {
        throw new Error(result.message);
      }

      // Subscribe to messaging service
      const subscriber: ISubscriber = {
        subject: 'TheFirstSpine:shop',
        handler: async (message: IMessage) => {
          if (message.message.id === id) {
            this.$data.purchaseStatus = 'purchased';
            this.$data.wizard = await wizardService.getMe();
          }
        },
      };
      messagingService.subscribe(subscriber);

      // Web context
      const webWindow: Window|null = window.open(
        result.url,
        '_blank',
        'toolbar=no,location=no,directories=no,status=no,' +
        'menubar=no,scrollbars=yes,resizable=yes,width=600,height=800',
      );
    } catch (e) {
      alert(e.message);
      this.$data.purchaseStatus = null;
    }
  }

  public get filteredShopItems(): any[] {
    return this.$data.shopItems.filter(
      (item: any) => item.categories.includes(this.$data.currentFilter),
    );
  }

  public get shards(): number {
    return this.$data.wizard ?
      this.$data.wizard.items.reduce((acc: number, curr: any) => acc + (curr.name === 'shard' ? curr.num : 0), 0) :
      0;
  }

  public get goldenGalleons(): number {
    return this.$data.wizard ?
      this.$data.wizard.items
        .reduce((acc: number, curr: any) => acc + (curr.name === 'golden-galleon' ? curr.num : 0), 0) :
      0;
  }

  public get candyShards(): number {
    return this.$data.wizard ?
      this.$data.wizard.items
        .reduce((acc: number, curr: any) => acc + (curr.name === 'candy-shard' ? curr.num : 0), 0) :
      0;
  }

}
