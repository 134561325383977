












































import { Component, Vue } from 'vue-property-decorator';
import authService, { IUser } from '../services/auth.service';
import { IWizard } from '@thefirstspine/types-arena';
import wizardService from '../services/wizard.service';
import restService from '../services/rest.service';
import messagingService from '../services/messaging.service';
import arenaService from '../services/arena.service';
import optionsService from '../services/options.service';

@Component({
  components: {
  },
})
export default class Home extends Vue {
  public data() {
    return {
      onlyFpe: true,
    };
  }

  public async mounted() {
    if (optionsService.options.tip_game) {
      this.$data.onlyFpe = false;
    }
  }

}
