




















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ICard, ICardSideStat, ICardStat } from '@thefirstspine/types-rest';
import Card from '../components/Card.vue';
import FormattedTextCard from '../components/FormattedTextCard.vue';

@Component({
  components: {
    Card,
    FormattedTextCard,
  },
})
export default class VueAudio extends Vue {

  @Prop()
  private card?: ICard;

  @Prop()
  private stats?: ICardStat;

  @Prop()
  private holo?: boolean;

  @Prop()
  private premium?: boolean;

  @Prop()
  private cardStyle?: string;

  public data() {
    return {
      cardData: this.card,
    };
  }

  public extractUniquCapacities(card: ICard): string[] {
    const ret: string[] = [];
    if (!card.stats) {
      return [];
    }

    if (this.stats) {
      if (this.stats.capacities) {
        this.stats.capacities.forEach((c: string) => {
          if (!ret.includes(c)) {
            ret.push(c);
          }
        });
      }

      ['top', 'right', 'bottom', 'left'].forEach((side: string) => {
        const stats: any = this.stats;
        const statSide: ICardSideStat|undefined = stats[side];
        if (statSide && statSide.capacity && !ret.includes(statSide.capacity)) {
          ret.push(statSide.capacity);
        }
      });
    } else {
      if (card.stats.capacities) {
        ret.push(...card.stats.capacities);
      }

      ['top', 'right', 'bottom', 'left'].forEach((side: string) => {
        const stats: any = card.stats;
        const statSide: ICardSideStat|undefined = stats[side];
        if (statSide && statSide.capacity && !ret.includes(statSide.capacity)) {
          ret.push(statSide.capacity);
        }
      });
    }

    return ret;
  }

  public get displayCardStatsChanged(): boolean {
    return !!(
      this.card &&
      this.stats &&
      JSON.stringify(this.card.stats) !== JSON.stringify(this.stats));
  }

}
