





















import { Component, Vue, Prop } from 'vue-property-decorator';
import { IQuest } from '@thefirstspine/types-rest';

@Component
export default class Tchat extends Vue {

  @Prop() public type?: 'weekly'|'daily';
  @Prop() public quest?: IQuest;

  public data() {
    return {
    };
  }

}
