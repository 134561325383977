import { Observable, Subscriber, Subject } from 'rxjs';

/**
 * Auth service
 * Manages requests & transactions with the auth microservice
 */
export class AuthService {

  /**
   * Microservice host
   */
  public static readonly HOST: string = process.env.VUE_APP_AUTH_URL ? process.env.VUE_APP_AUTH_URL : '';

  /**
   * Microservice version
   */
  public static readonly VERSION: string = 'v2';

  /**
   * Observable to "logged" state
   */
  public isLogged: Subject<boolean>;

  constructor() {
    this.isLogged = new Subject<boolean>();
  }

  /**
   * Requests a token in exchange of a email & a password.
   * The token will be stored in the service.
   * @param email
   * @param password
   */
  public async login(email: string, password: string): Promise<string> {
    const response = await fetch(
      `${AuthService.HOST}/api/${AuthService.VERSION}/login`, {
        method: 'post',
        body: JSON.stringify({
          email,
          password,
        }),
        headers: {
          'Content-type': 'application/json',
        },
        mode: 'cors',
      });

    const responseJson = await response.json();
    if (response.status >= 202) {
      throw new Error(responseJson.error ? responseJson.error : 'Unknown error');
    }

    // Dispatch subject
    this.isLogged.next(true);

    return this.setToken(responseJson.access_token, responseJson.refresh_token);
  }

  public async loginWithFacebookCode(code: string, redirectUri: string): Promise<string> {
    const response = await fetch(
      `${AuthService.HOST}/api/${AuthService.VERSION}/login-with-facebook`, {
        method: 'post',
        body: JSON.stringify({
          code,
          redirect_uri: redirectUri,
        }),
        headers: {
          'Content-type': 'application/json',
        },
        mode: 'cors',
      });

    const responseJson = await response.json();
    if (response.status >= 202) {
      throw new Error(responseJson.error ? responseJson.error : 'Unknown error');
    }

    // Dispatch subject
    this.isLogged.next(true);

    return this.setToken(responseJson.access_token, responseJson.refresh_token);
  }

  /**
   * Sign up the user & requests a token from the microservice.
   * The token will be stored in the service.
   */
  public async signup(email: string, password: string): Promise<string> {
    const response = await fetch(
      `${AuthService.HOST}/api/${AuthService.VERSION}/signup`, {
        method: 'post',
        body: JSON.stringify({
          email,
          password,
        }),
        headers: {
          'Content-type': 'application/json',
        },
        mode: 'cors',
      });

    const responseJson = await response.json();
    if (response.status >= 202) {
      throw new Error(responseJson.error ? responseJson.error : 'Unknown error');
    }

    return this.setToken(responseJson.access_token, responseJson.refresh_token);
  }

  /**
   * Refresh the token.
   * The token will be stored in the service.
   */
  public async refreshToken(): Promise<string> {
    const response = await fetch(
      `${AuthService.HOST}/api/${AuthService.VERSION}/refresh`, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          refresh_token: this.getRefreshToken(),
        }),
        mode: 'cors',
      });

    const responseJson = await response.json();
    if (response.status >= 202) {
      throw new Error(responseJson.error ? responseJson.error : 'Unknown error');
    }

    return this.setToken(responseJson.access_token, responseJson.refresh_token);
  }

  /**
   * Get the user data from the auth microservice. Needs to have a stored token.
   */
  public async me(): Promise<IUser> {
    // Auto refresh token
    await this.autoRefreshToken();

    // Fetch API
    const response = await fetch(
      `${AuthService.HOST}/api/${AuthService.VERSION}/me`, {
        method: 'get',
        headers: {
          'Content-type': 'application/json',
          'Authorization': 'Bearer ' + this.getAccessToken(),
        },
        mode: 'cors',
      });

    const responseJson = await response.json();
    if (response.status >= 202) {
      throw new Error(responseJson.error ? responseJson.error : 'Unknown error');
    }

    return responseJson;
  }

  /**
   * Get the access token
   */
  public getAccessToken(): string|null {
    return window.localStorage.getItem('accessToken');
  }

  /**
   * Get the refresh token
   */
  public getRefreshToken(): string|null {
    return window.localStorage.getItem('refreshToken');
  }

  /**
   * Get the decoded token.
   */
  public getDecodedToken(): IToken|null {
    const encodedToken = this.getAccessToken();
    if (encodedToken === null) {
      return null;
    }

    const encodedDataPart = encodedToken.split('.')[1];
    if (!encodedDataPart) {
      return null;
    }

    const decodedDataPart = atob(encodedDataPart);
    if (!decodedDataPart) {
      return null;
    }

    return JSON.parse(decodedDataPart) as IToken;
  }

  /**
   * Store the token in the service
   * @param token
   */
  public setToken(accessToken: string, refreshToken: string): string {
    window.localStorage.setItem('accessToken', accessToken);
    window.localStorage.setItem('refreshToken', refreshToken);
    return accessToken;
  }

  /**
   * Remove the token from the service
   */
  public logout() {
    fetch(
      `${AuthService.HOST}/api/${AuthService.VERSION}/logout`, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
          'Authorization': 'Bearer ' + this.getAccessToken(),
        },
        mode: 'cors',
      });
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('refreshToken');
  }

  /**
   * Auto-refresh the token when considered as too old (delivered 2 hours ago).
   */
  protected async autoRefreshToken(): Promise<void> {
    // Get the decoded token
    const decodedToken = this.getDecodedToken();
    if (!decodedToken) {
      return;
    }

    // Does the token need to be refreshed?
    const dateNow = Math.round(Date.now() / 1000);
    if (decodedToken.iat + (60 * 60 * 2) < dateNow) {
      await this.refreshToken();
    }
  }

}

export interface IUser {
  user_id: number;
}

export interface IToken {
  sub: number;
  iat: number;
  exp: number;
}

export default new AuthService();
