






























































































































import { Component, Vue } from 'vue-property-decorator';
import authService, { IUser } from '../services/auth.service';
import { IWizard } from '@thefirstspine/types-arena';
import wizardService from '../services/wizard.service';
import restService from '../services/rest.service';
import messagingService from '../services/messaging.service';
import arenaService from '../services/arena.service';
import optionsService from '../services/options.service';
import websiteService from '../services/website.service';
import calendarService from '../services/calendar.service';
import Quest from '@/components/Quest.vue';
import ScheduledLoot from '@/components/ScheduledLoot.vue';
import { IQuest } from '@thefirstspine/types-rest';

@Component({
  components: {
    Quest,
    ScheduledLoot,
  },
})
export default class Home extends Vue {
  public data() {
    return {
      ready: false,
      wizard: null,
      cycleMessageHidden: true,
      cycle: null,
      shards: 0,
      runes: 0,
      coins: 0,
      goldenGalleons: 0,
      candyShards: 0,
      availableEvents: [],
      eventMessageHidden: true,
      event: null,
      scheduledLoot: null,
      dailyQuest: null,
      weeklyQuest: null,
    };
  }

  public async mounted() {
    // Test for language
    if (optionsService.options.language === '') {
      this.$router.push('/select-language');
      return;
    }

    // Get the user
    try {
      const me: IUser = await authService.me();
    } catch (e) {
      this.$router.push('/login');
      return;
    }

    // Test for realm
    if (optionsService.options.realm === '') {
      this.$router.push('/select-realm');
      return;
    }

    // Connect websocket
    if (!messagingService.connected) {
      await messagingService.connect();
    }

    // Try to retrieve a game
    try {
      const getGameResponse = await arenaService.getCurrentGame();
      if (getGameResponse && getGameResponse.id) {
          this.$router.push(`/game?gameId=${getGameResponse.id}`);
      }
      return;
    } catch {
      // do nothing as we do not found any opened game instance for this user
    }

    // Get the wizard data
    await this.updateWizard();

    // Ready!
    this.$data.ready = true;

    // Get the cycle
    this.$data.cycle = await calendarService.currentCycle();
    this.$data.cycleMessageHidden =
      window.localStorage.getItem(`cycleMessageHidden-${this.$data.cycle.name}`) === 'true' ? true : false;

    // Load quests
    const quests = await restService.quests();
    this.$data.dailyQuest = quests.daily;
    this.$data.weeklyQuest = quests.weekly;

    // Load available events
    try {
      const events = (await calendarService.currentEvents());
      if (events && events.length) {
        this.$data.event = events[0];
        this.$data.eventMessageHidden =
          window.localStorage.getItem(`eventMessageHidden-${this.$data.event.id}`) === 'true' ? true : false;
      }
      this.$data.availableEvents = events.map((e: any) => e.type);
    } catch (e) {
      // Do nothing
    }

    try {
      const scheduledLoot = await calendarService.scheduledLoot();
      if (scheduledLoot?.rewarded) {
        this.$data.scheduledLoot = scheduledLoot;
      }
    } catch (e) {
      // do nothing
    }
  }

  public hideCycleMessage() {
    this.$data.cycleMessageHidden = true;
    window.localStorage.setItem(`cycleMessageHidden-${this.$data.cycle.name}`, 'true');
  }

  public hideEventMessage() {
    this.$data.eventMessageHidden = true;
    window.localStorage.setItem(`eventMessageHidden-${this.$data.event.id}`, 'true');
  }

  public async takeQuest(quest: IQuest) {
    this.$data.wizard.quests.push(quest.id);
    try {
      await wizardService.setMe({
        quests: this.$data.wizard.quests,
      });
    } catch (e) {
      // do nothing
    }
    this.updateWizard();
  }

  public hasTakenQuest(quest: IQuest) {
    return this.$data.wizard.quests.includes(quest.id);
  }

  public async updateWizard() {
    const wizard: IWizard = await wizardService.getMe();
    if (!wizard.publicRoom) {
      wizardService.setMe({
        publicRoom: optionsService.options.language as ('fr'|'en'),
      });
      wizard.publicRoom = optionsService.options.language as ('fr'|'en');
    }
    this.$data.shards = wizard.items
      .reduce((acc: number, curr: any) => acc + (curr.name === 'shard' ? curr.num : 0), 0);
    this.$data.runes = wizard.items
      .reduce(
        (acc: number, curr: any) =>
          acc + (['rune-solid', 'rune-holo', 'rune-ghostly'].includes(curr.name) ? curr.num : 0),
        0);
    this.$data.coins = wizard.items
      .reduce((acc: number, curr: any) => acc + (curr.name === 'coin' ? curr.num : 0), 0);
    this.$data.goldenGalleons = wizard.items
      .reduce((acc: number, curr: any) => acc + (curr.name === 'golden-galleon' ? curr.num : 0), 0);
    this.$data.candyShards = wizard.items
      .reduce((acc: number, curr: any) => acc + (curr.name === 'candy-shard' ? curr.num : 0), 0);
    this.$data.wizard = wizard;
  }
}
