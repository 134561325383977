export default {
  app: {
    home: 'Home',
    cards: 'Cards',
    shop: 'Shop',
    profile: 'Profile',
    signupOrLogin: 'Sign up or log in',
    cycleValidUntil: 'Cycle available until',
    dateFormat: `MM D YYYY`,
    error: `Error`,
    agreed: `Ok`,
    oops: `Oops!`,
    yes: `Yes`,
    no: `No`,
  },
  options: {
    gameplayAndDisplay: `Gameplay & display`,
    soundsAndMusic: `Sounds and music`,
    account: `Account`,
    displaySize: `Display size (restart needed)`,
    cardsColors: `Cards color scheme`,
    displayHistory: `Display actions history`,
    playMusic: `Play the music`,
    playSounds: `Play the sounds`,
    logout: `Sign out`,
    logoutOnThisClient: `Sign out on this client`,
    language: `Language`,
    save: `Save`,
    concede: `Concede the game`,
    concedeWarning: `Be careful: abuses will be punished.`,
    resetTips: `Reset the First Game Experience`,
    resetTipsButton: `Reset`,
    hideTips: `Avoid the First Game Experience`,
    hideTipsButton: `Avoid`,
    changeRealm: `Change realm`,
    change: `Change`,
  },
  quest: {
    daily: `Daily quest`,
    weekly: `Weekly quest`,
  },
  toast: {
    loot: {
      'title': `Loot`,
      'shard': `Manag shard`,
      'holo': `Holo {cardName}`,
      'premium': `Premium {cardName}`,
      'rune-holo': `Holo rune`,
      'rune-solid': `Solide rune`,
      'rune-ghostly': `Ghostly rune`,
      'golden-galleon': `Golden Galleons`,
      'candy-shard': `Candy Shard`,
      'coin': `Strange Coin`,
    },
    quest: {
      complete: `Quest completed`,
      obtain: `Quest obtained`,
      progress: `Quest progression`,
    },
  },
  home: {
    immediate: `Quick game`,
    immediateDescription: `Find an opponent quickly in a random game.`,
    daily: `Daily challenge`,
    dailyDescription: `Take part in the game of the day. The first earnings of the day are doubled.`,
    cycle: `Thematic versus`,
    cycleDescription: `Play a game related to the current cycle.`,
    private: `Private game`,
    privateDescription: `Create a private game with your rules.`,
    cycleBlock: `The {cycleName} will ends in {days} days`,
    cycleBlockDescription: `Take part in the tournament of this cycle to win the rewards! Click here for more information`,
    newCycleTitle: `Nouveau cycle`,
    newCycleOk: `Ok, I got it.`,
    play: 'Play',
    cards: 'Cards',
    shop: 'Shop',
    collection: 'Collection',
    newEventTitle: `New event`,
    newEventOk: `Ok, I got it.`,
    fpe: `First Game Experience`,
  },
  login: {
    welcome: `Welcome!`,
    welcome2: `Sign in, or create a wizard account`,
    email: `Email`,
    password: `Password`,
    login: `Sign in`,
    subscribe: `Sign up`,
    loginWithFacebook: `Sign in with Facebook`,
    forgottenPassword: `Forgotten password`,
    checkCredentials: `Review your credentials`,
  },
  about: {
    title: `Thank you for your interest in our game!`,
    part1: `This long-standing project is a paper version game presented at many festivals in France. Unfortunately, financing such a game is not easy and we decided to create a digital version to raise the funds necessary to shape the game.`,
    part2: `The online purchases you make will bring us closer to this goal. Despite everything, the content of this game <strong>is not locked behind micro-transactions</strong>. We try as much as possible to be respectful towards our players, because without you, we are nothing.`,
    part3: `This digital version also allows us to vary the pleasures and test new things. Finally, it allows you to try the prototype without having to use your ink cartridges, because it is certain that it is while playing that comes the pleasure of playing 😀`,
    part4: `One last thing before to start: this game <strong>was not created by a team of 50 people</strong>. We are three: game designer, illustrator and tester. We put a lot of time and love into this game, and we hope you will enjoy it 🤗`,
    start: `Start playing`,
  },
  selectRealm: {
    title: 'Choose a realm',
    part1: 'Arena servers are organized into "realms". Each realm has its own level of play as well as its language.',
    part2: 'If you are playing The First Spine for the first time, we recommend that you take a realm at beginner level. You can change your realm at any time in the game options.',
    name: 'Name',
    languageTitle: 'Language',
    regionTitle: 'Region',
    levelTitle: 'Level',
    chose: 'Chose',
    language: {
      fr: 'French',
      en: 'English',
    },
    region: {
      eu: 'Europe',
    },
    level: {
      low: 'Beginner',
      high: 'Expert',
    },
  },
  cards: {
    searchPlaceholder: `Search card...`,
    filters: {
        destinyTitle: `Only destinies`,
        destinyDescription: `Destinies are the primary decks on the game. They contains 36 cards.`,
        originTitle: `Ony origins`,
        originDescription: `Origins are the secondary decks on the game. They contains 6 cards and are available for each destiny.`,
        allTitle: `All decks`,
    },
    sort: {
      name: `Sort by name`,
      type: `Sort by type`,
      number: `Sort by number`,
    },
    styles: {
      'style-': `Classic style`,
      'style-nostalgy': `Nostalgy style`,
      'style-scales': `Scales style`,
      'style-cartographer': `Cartographer style`,
      'style-corsair': `Corsair style`,
      'style-ghostly': `Ghostly style`,
      'style-sunlight': `Sunlight style`,
      'style-burning': `Burning style`,
      'style-luminescent': `Luminescent style`,
      'style-scary': `Scary style`,
      'style-purple-candy': `Purple Candy style`,
      'style-bloody': `Bloody style`,
      'style-frozen': `Frozen style`,
      'style-festive': `Festive style`,
    },
    covers: {
      'cover-': `Classic sleeve`,
      'cover-hunter': `Hunter sleeve`,
      'cover-summoner': `Summoner sleeve`,
      'cover-conjurer': `Conjurer sleeve`,
      'cover-corsair': `Corsair sleeve`,
      'cover-sorcerer': `Sorcerer sleeve`,
      'cover-barbers': `Barbed Wires sleeve`,
      'cover-veneniagora': `Veneniagora sleeve`,
      'cover-smoky-totem': `Smoky Totam sleeve`,
      'cover-heal': `Heal sleeve`,
      'cover-reconstruct': `Reconstruct sleeve`,
      'cover-banshee': `Banshee sleeve`,
      'cover-purple-candy': `Purple Candy sleeve`,
      'cover-putrefaction': `Putrefaction sleeve`,
      'cover-ruin': `Ruin sleeve`,
      'cover-replacement': `Replacement sleeve`,
      'cover-the-authority': `The Authority sleeve`,
      'cover-monstrous-portal': `Monstrous Portal sleeve`,
    },
    gameSelectionTitle: `Chosing a deck`,
    gameSelectionButton: `Chose the deck {deckname}`,
    backToDecks: `Back to the decks`,
    stylesTitle: `Card styles`,
    coversTitle: `Sleeves`,
  },
  profile: {
    'triumphs': `Triumphs`,
    'triumphs-category-activities': `Activities`,
    'triumphs-category-destinies-and-origins': `Destinies & origins`,
    'triumphs-category-achievements': `Achievements`,
    'triumphs-category-targets': `Targets`,
    'history': `History`,
    'victory': `victory`,
    'defeat': `defeat`,
    'unkown': `unkown`,
    'changeAvatar': `Change avatar`,
    'choseAvatar': `Chose this avatar`,
    'choseTriumph': `Chose this triumph as a title`,
    'back': `Back`,
    'gametype-classic': `Classic game`,
    'gametype-tournament': `Tournament of the cycle`,
    'gametype-fpe': `First game experience`,
    'gametype-standard': `Standard`,
    'gametype-quick': `Quick`,
    'datetime': `YYYY-MM-DD - h:mm A`,
    'profile': 'Profile',
    'triumphsExplication': `Your triumphs represent your achievements on Arena. Use them to change your title and show your determination!`,
    'quests': `Quests`,
    'abandon': `Abandon`,
  },
  social: {
    emptyTitle: `Your acolytes list is empty`,
    emptyText: `You can add some acolytes by clicking on "Add as acolyte" on the player cards.`,
    acolytes: `Your acolytes`,
    future: `In a near future you will be able to send messages to your acolytes.`,
    inList: `{name} added you to his acolytes list.`,
    notInList: `You are not in the acolytes' list of {name}`,
    unkonwnIdTitle: `This ID is unknown`,
    unkonwnIdText: `The ID you typed is not known.`,
  },
  play: {
    destiny: `Destiny`,
    origin: `Origin`,
    launch: `Launch game`,
    notEnoughShields: `Not enough shields available.`,
    choseName: `Before you start playing, choose your wizard name.`,
    placeLabel: `Place:`,
    modifiersLabel: `Modifiers:`,
    queues: {
      fpe: {
        title: `First Play experience`,
        description: `Discover the rules of The First Spine with this tutorial part. You can replay it at any time or hide it in the game options by pressing "Esc".`,
      },
      immediate: {
        title: `Quick game`,
        description: `Find an opponent quickly widthout any modifier.`,
      },
      daily: {
        title: `Daily challenge`,
        description: `The daily game contains a location and a fixed modifier for the day. Earnings are doubled for the first game of each day.`,
      },
      cycle: {
        title: `Thematic versus`,
        description: `Each cycle, participate in an exclusive part of the current cycle. Your earnings are doubled if you win, but you don't earn anything if you lose.`,
      },
      private: {
        title: `Partie privée`,
      },
    },
    modifiers: {
      'immediate': {
        title: `Immediate`,
        description: `The matchmaking is quicker.`,
      },
      'random': {
        title: `Random`,
        description: `The conditions of your next battle are still unknown.`,
      },
      'great-ancients-eggs': {
        title: `The Eggs of the Great Ancients`,
        description: `Your deck will be filled with eggs that will hatch in the next turn.`,
      },
      'souvenirs-from-your-enemy': {
        title: `Souvenirs of your enemy`,
        description: `Your deck will have cards corresponding to your opponent's destiny.`,
      },
      'daily': {
        title: `Daily`,
        description: `Your earnings will be doubled for each first game of the day.`,
      },
      'cycle': {
        title: `Cycle`,
        description: `Your earnings are doubled in case of victory. You have no earning in case of defeat.`,
      },
      'harvesting-souls': {
        title: `Harvesting Souls`,
        description: `Your deck has Blood Strength spells that destroy creatures while healing you.`,
      },
      'golden-galleons': {
        title: `Golden Galleons`,
        description: `Your deck contains Golden Galleon cards which are converted to eponymous currency at the end of the game.`,
      },
      'annihilation-matts': {
        title: `Annihilation Matts`,
        description: `Annihilation Matts are present on the board. The player who destroys one of them loses the game.`,
      },
      'trick-or-treat': {
        title: `Trick or Treat`,
        description: `The Trics Celebration is open! Prank cards are added to your deck, and will allow you to earn Candy Shards!`,
      },
      'frozen-statues': {
        title: `Frozen Statues`,
        description: `Frozen statues are placed on the game board. Once destroyed, they are turned over and placed under the control of the player who destroyed them.`,
      },
      'triple-shards': {
        title: `Triple shards`,
        description: `3x mana shards.`,
      },
      'mutations': {
        title: `Mutations`,
        description: `"Mutations" cards are added to your deck that allow you to change your creatures and artifacts.`,
      },
      'drifter': {
        title: `Drifter`,
        description: `Add cards from Drifter's Tales, the solo game available on Steam.`,
      },
    },
    themes: {
      'random': {
        title: `Unknown location`,
        description: `You let the fate decide. You will know the place of your battle when you get there.`,
      },
      'dead-forest': {
        title: `Sleeping Forest`,
        description: `This forest has seen many travelers pass but it has always remained animal-free, as if they knew it was cursed..`,
      },
      'spine-s-cave': {
        title: `Spine's Cave`,
        description: `A cave where we celebrate the birth of the First Spine. It is said that Volk'ha took up residence before dying for the first time.`,
      },
      'forgotten-cemetery': {
        title: `Forgotten cemetery`,
        description: `No one remembers this cemetery where Insane reappeared. The names on the graves have all been erased by time.`,
      },
      'wasted-fields': {
        title: `Wasted fields`,
        description: `Nothing grows on these once fertile fields. Only pain can be harvested there.`,
      },
      'sacrifice-church': {
        title: `Eglise du Sacrifice`,
        description: `Lieu de pélerinage final des Chasseurs, l'Eglise du Sacrifice libère les pélerins du poids des pouvoirs de la Couronne par la mort. Mais leur mort signifit-elle la fin de leur existance ?`,
      },
      'snow-man-lair': {
        title: `Snow Man's Lair`,
        description: `The Snow Man stays in this cave with labyrinthine mazes. Better not to get lost in it, otherwise you will freeze to death.`,
      },
      'ruined-laboratory': {
        title: `Ruined Laboratory`,
        description: `A long forgotten Scholar laboratory. There are secrets it is better not to discover, but the Scholars were ready to defy all prohibitions.`,
      },
    },
  },
  private: {
    standard: `Standard game`,
    standardDescription: `Play a standard game according to official rules.`,
    quick: `Quick game`,
    quickDescription: `A quick game brings you closer to your opponent by reducing the playing area.`,
    all: `All`,
    none: `None`,
    chose: `Choose this type of game`,
    joinTitle: `Join a game`,
    or: `or`,
    createGame: `Create a game`,
    nbOfPlayers: `Numbers of players`,
    destinies: `Authorized destinies`,
    origins: `Authorized origins`,
    gameCreated: `Created game!`,
    gameCreatedText: `Your game has been created and will be available for the next 30 minutes. Give this code to your friends to join you:`,
    nextStep: `Next step`,
    errorOnJoin: `Unable to join the private game`,
  },
  matchmaking: {
    searching: `Looking for opponents...`,
    full: `The queue is full`,
    wait: `Waiting for players...`,
    prepare: `Prepare yourselves !`,
    solo: `Preparing game...`,
    quit: `Cancel matchmaking`,
    advice1: `A matchmaking takes place every minute. The tournaments are classified while the classic games are based on the order of arrival`,
    advice2: `The tournament rules change with each cycle. You can see their specifics in the game setting screen.`,
    advice3: `Each tournament has a reward for the best player in the cycle, as well as for having participated in an entire match.`,
    advice4: `In case of bad behavior you will be cursed and your game will be altered by having cursed cards in your hand.`,
    advice5: `TFS is also a story to discover. Discover the universe by reading the many cards that make up the game.`,
    advice6: `Each cycle lasts 6 weeks and has its own cosmetics available in the store. They won't go away, you have all your time!`,
    advice7: `You can resume a game at any time, even after a disconnection or an accidental closure of the client.`,
    advice8: `You can view your holos, premiums and seasonal cards in your card collection.`,
    advice9: `Keep an eye on your triumphs in your profile. They will help you show your determination to your opponents.`,
  },
  game: {
    'displayDiscard': `Displaying discard`,
    'discardBetween': `Discard between {min} and {max} card(s).`,
    'discardExactly': `Discard {value} card(s).`,
    'validateDiscard': `Validate discard`,
    'pass': `Pass`,
    'timeLeft': `{expirationTime} second(s) left to answer`,
    'lose': `Your opponent won`,
    'win': `You have triumphed over your opponent`,
    'returnToHome': `Return to home`,
    'loot-shard': `Mana shards`,
    'loot-defeat-mark': `Defeat mark`,
    'loot-victory-mark': `Victory mark`,
    'cancelAction': `Cancel action`,
    'choseAction': `Chose an action`,
    'seeDiscard': `See discard`,
    'seeLoots': `See your loot`,
  },
  shop: {
    categories: {
      featuredTitle: `Featured this cycle`,
      featuredDescription: `Cards & style of the current cycle`,
      holosTitle: `Holos`,
      holosDescription: `The holo cards of the previous cycles`,
      stylesTitle: `Styles`,
      stylesDescription: `The styles of the previous cycles`,
      coversTitle: `Covers`,
      coversDescription: `The covers of the previous cycles`,
      shardsTitle: `Mana shards`,
      shardsDescription: `Buy mana shards with real money`,
      seasonialTitle: `Seasonial`,
      seasonialDescription: `Items only available for this cycle`,
    },
    alreadyPurchased: `already bought`,
    purchase: `Purchase {item}`,
    purchaseWithMoney: `Purchase {item} with real money`,
    purchaseFor: `Purchase for {price} €`,
    exchangeFor: `Trade for {price}`,
    purchased: `{item} was bought!`,
    back: `Back`,
    cancel: `Cancel`,
  },
  name: {
    chose: `Chose a wizard name.`,
    choseText: `The other players will know you by this name.`,
    validate: `Validate`,
    longer: `Chose a longer name.`,
    failed: `Name change failed.`,
    placeholder: `Your wizard name`,
  },
  runes: {
    'rune-holo': {
      title: `Holo rune`,
      text: `A shiny rune that can be used to craft a new Holo card.`,
    },
    'rune-solid': {
      title: `Solid rune`,
      text: `A rune that represents the solidity. Can be used to craft a new sleeve.`,
    },
    'rune-ghostly': {
      title: `Ghostly rune`,
      text: `A mysterious rune that has the form desired by the one who looks it. Can be used to craft a new style.`,
    },
    'craft': `Craft`,
    'craft-rune-holo': `Create a holo for`,
    'craft-rune-solid': `Create a sleeve for`,
    'craft-rune-ghostly': `Create a style for`,
    'no-item': `Not enough ingredients`,
    'no-possibility': `You already got it all`,
  },
  collection: {
    'title': `Your collection`,
    'text1': `Here is the collection of all the cosmetics available on Arena.`,
    'text2': `If you missed a seasonal cosmetic, it will come back: we have no predatory retention mechanics towards our players.`,
    'back': `Back`,
    'type-holo': `Holos`,
    'type-premium': `Premium cards`,
    'type-cover': `Sleeves`,
    'type-style': `Styles`,
    'category-shop': `Purchasable in store`,
    'category-tournaments': `Participation in tournaments`,
    'category-cycles': `Cycle contents`,
    'category-craft': `Crafting`,
    'subcategory-shop-classic': `Classic online store`,
    'subcategory-shop-classic-description': `Cosmetics present in the online store.`,
    'subcategory-shop-corsairs': `Corsairs Shop`,
    'subcategory-shop-corsairs-description': `Cosmetics available in the seasonal shop during the corsairs arrival.`,
    'subcategory-tournaments-tournaments': `Cycles Tournaments`,
    'subcategory-tournaments-tournaments-description': `Awards for the winners of the Cycles tournaments.`,
    'subcategory-cycles-great-ancient': `Cycle of the Great Ancient`,
    'subcategory-cycles-great-ancient-description': `Cosmetics obtainable during the Cycle of the Great Ancient.`,
    'subcategory-cycles-harvester': `Cycle of the Harvest`,
    'subcategory-cycles-harvester-description': `Cosmetics obtainable during the Cycle of the Harvest.`,
    'subcategory-cycles-treasure': `Cycle of the Treasure`,
    'subcategory-cycles-treasure-description': `Cosmetics obtainable during the Cycle of the Treasure.`,
    'subcategory-cycles-souvenirs': `Cycle of the Souvenirs`,
    'subcategory-cycles-souvenirs-description': `Cosmetics obtainable during the Cycle of the Souvenirs.`,
    'subcategory-cycles-crowned-souls': `Cycle of the Crowned Souls`,
    'subcategory-cycles-crowned-souls-description': `Cosmetics obtainable during the Cycle of the Crowned Souls.`,
    'subcategory-cycles-snow-man': `Cycle of the Snow Man`,
    'subcategory-cycles-snow-man-description': `Cosmetics obtainable during the Cycle of the Snow Man.`,
    'subcategory-cycles-absurdal': `Cycle of the Absurdal`,
    'subcategory-cycles-absurdal-description': `Cosmetics obtainable during the Cycle of the Absurdal.`,
    'subcategory-cycles-drifter': `Cycle of the Drifter`,
    'subcategory-cycles-drifter-description': `Cosmetics obtainable during the Cycle of the Drifter.`,
    'subcategory-craft-rune-holo': `Holo Rune`,
    'subcategory-craft-rune-holo-description': `Holo Cards crafted with an Holo Rune obtainable in Weekly Quests.`,
    'subcategory-craft-rune-solid': `Solid Rune`,
    'subcategory-craft-rune-solid-description': `Sleeves crafted with an Solid Rune obtainable in Weekly Quests.`,
    'subcategory-craft-rune-ghostly': `Ghostly Rune`,
    'subcategory-craft-rune-ghostly-description': `Styless crafted with an Ghostly Rune obtainable in Weekly Quests.`,
  },
  tips: {
    understood: 'I get it',
    rules: `View the rules (new window)`,
    cards_home: {
      title: `Explanations: the decks`,
      html: `
      <p>Here are your decks. In The First Spine, you don't have to play for hours or pay to have the same cards as your opponents.</p>
      <p>All decks are available to all players and have the same cards.</p>`,
    },
    cards_deck: {
      title: `Explanations: the cards`,
      html: `
      <p>Here are your cards.</p>
      <p>There are 3 types of cards: the <span class="has-text-danger">creatures</span>, the <span class="has-text-info">artifacts</span> and the <span class="has-text-primary">spells</span>.</p>
      <p>Each card has strength and defense for each side. The number in the center represents the life of the card. If the life of the card drops to 0, the card is destroyed and goes to your discard pile.</p>
      <p>Cards can also have side and side life abilities. Abilities are all explained when your pointer passes over the cards.</p>`,
    },
    cards_covers: {
      title: `Explanations: the sleeves`,
      html: `
      <p>Arena allows you to personalize your playing experience by changing the back of your cards during a game thanks to the card covers.</p>
      <p>You can buy new sleeves in the online store.</p>`,
    },
    cards_styles: {
      title: `Explanation: styles`,
      html: `
      <p>You can change the appearance of your cards using styles.</p>
      <p>You can buy new styles in the online shop.</p>`,
    },
    shop: {
      title: `Explanations: the shop`,
      html: `
      <p>Our main objective is to launch a pre-order campaign with the paper version of the game. But for that, we need to produce at least 500 copies of the game in order to have correct prices. With the money obtained from this digital version of the game, you will help us achieve this goal of launching this game in physical version.</p>
      <p>Each item in the shop is buyable with mana shards, which you can earn at the end of each game.</p>`,
    },
    runes: {
      title: `Explications: the craft`,
      html: `
      <p>Weekly quests allow you to get runes, useful for making cosmetics.</p>
      <p>When you get a cosmetic, you won't be able to get it again in order to avoid duplicates. If the possibilities of cosmetics are exhausted, then manufacturing will not be possible. We add new recipes frequently, so check back here often!</p>`,
    },
    profile: {
      title: `Explanations: your profile`,
      html: `
      <p>Here is your profile.</p>
      <p>By playing you can win triumphs you can display next to your name. This triumph will be visible to all players.</p>
      <p>You can also modify your avatar. Avatars are added regularly!</p>`,
    },
    game_setup: {
      title: `Explanations: create a game`,
      html: `
      <p>To play, you have to choose two decks that will be shuffled: one destiny that contains 36 cards, and one origin that contains 6 cards. To do this, click on the boxes at the center of the interface.</p>
      <p>As soon as you are ready, click on "Launch game". Don't worry: we'll explain everything step by step.</p>`,
    },
    matchmaking: {
      title: `Explanations: matchmaking`,
      html: `
      <p>Whenever you start a game, we are looking for players who want to fight in the same type of game.</p>
      <p>The wait may be quite long (one to three minutes) because the matchmaking is performed every minute on our servers.</p>
      <p>The First Game Experience has no human opponent.</p>`,
    },
    fpe_2: {
      title: `Explanations: at the start of your turn`,
      html: `
      <p>At the start of your turn, you can discard a card for free by placing it in your discard pile. Each additional card will cost you one life point.</p>
      <p class="has-text-primary has-text-weight-bold">For this scripted part, discard the Heal (this card is pretty useless at this point of the game).</p>
      <p>To do this, press "Discard" at the bottom of the interface to select the discard action, then on the "Care" card which will be highlighted..</p>
      <p>As soon as you are done, you must click on "Validate the discard" at the bottom of the interface. You will automatically draw to have a hand of six cards.</p>`,
    },
    fpe_4: {
      title: `Explanations: play a spell`,
      html: `
      <p>Once your discard is done, you can do three actions in the order you want.</p>
      <p>One of these actions is to play a spell. Spells are the <strong class="has-text-primary">yellow</strong> cards.</p>
      <p>To play a spell, choose the spell you want to play from the action list and the spell in your hand. The targets will be highlighted and you will only have to choose it by pressing it.</p>
      <p class="has-text-primary has-text-weight-bold">Try to play a Thunder on the Fox on your left!</p>`,
    },
    fpe_6: {
      title: `Explanations: place a card`,
      html: `
      <p>Second possible action during the action phase: the pose.</p>
      <p>You can put a <strong class="has-text-danger">creature</strong> or an <strong class="has-text-info">artifact</strong> next to a card that you control.</p>
      <p>The <strong class="has-text-danger">creatures (in red)</strong> can move around the board and attack, while the <strong class="has-text-info">artifacts (in blue)</strong> can neither move nor attack.</p>
      <p>To place a card, choose the action "Place card". Then choose the card to place and the place where to put your card.</p>
      <p class="has-text-primary has-text-weight-bold">Try to place the Smoky Totem on top of the enemy Banshee!</p>`,
    },
    fpe_8: {
      title: `Explanations: move a creature`,
      html: `
      <p>Third possible action during the action phase: the move.</p>
      <p>You can move a <strong class="has-text-danger">creature</strong> one step.</p>
      <p>To move a creature, you must choose the action "Move a creature". Then choose the <strong class="has-text-danger">creature</strong> on the game board and its destination.</p>
      <p class="has-text-primary has-text-weight-bold">Try to move your Banshee by one step up!</p>`,
    },
    fpe_9: {
      title: `Explanations: go to confrontations`,
      html: `
      <p>All right, you've done your three actions: laying a card, spelling, and moving a creature.</p>
      <p class="has-text-primary">Click on "go to confrontations" to go to the next phase.</p>`,
    },
    fpe_11: {
      title: `Explications: confrontations`,
      html: `
      <p>At the end of your turn, your <strong class="has-text-danger">creatures</strong> will attack adjacent enemy cards. Your <strong class="has-text-info">artifacts</strong> which have the symbol <i class="capacity-threat"></i> can attack cards that are on the side of this symbol.</p>
      <img src="img/tip_fpe_11_example.png" />
      <p>In the example above, the player holding the Fox ends his turn. The Fox will therefore attack the Tower. Damage is calculated simultaneously on both cards: the fox has <span class="stat strength">2</span> in attack, and the tower has <span class="stat defense">2</span> in defense. The Tower will take no damage. However, the fox has no defense and the Tower has <span class="stat strength">3</span> in force on the side where it is attacked: the Fox is therefore destroyed, because it had <span class="stat life">3</span> hit points.</p>
      <p>To compare one card with another, choose the "Confront" action. Then click on the attacking card, then on the target.</p>
      <p class="has-text-primary has-text-weight-bold">Try to bring your Smoky Totem into a confrontation with the enemy Banshee!</p>`,
    },
    fpe_15: {
      title: `Explanations: your opponent's turn`,
      html: `
      <p>Your opponent goes through the same phases as you:</p>
      <ul>
        <li>Discard & draw</li>
        <li>Actions (puting cards, spells, moving creatures)</li>
        <li>Confrontations</li>
      </ul>
      <p>Your opponent has placed a Barbed Wire just in front of him to block your path and it's your turn again.</p>
      <p class="has-text-primary has-text-weight-bold">For this scripted game, discard your barbed wire because this card is useless in this context.</p>`,
    },
    fpe_17: {
      title: `Explanations: it's your turn!`,
      html: `
      <p class="has-text-centered">You now have all the cards in hand to be able to win this game!</p>`,
    },
    cards_list: {
      title: `Explanations: cards & decks`,
      html: `
      <p>In The First Spine, you don't have to play for hours or pay to have the same cards as your opponents. All decks are available to all players and have the same cards.</p>
      <p>You can navigate among your decks with the left part of the interface. Below, you will find the cosmetics available for your cards: styles as well as card covers (with the button <i class="fas fa-book-open"></i>).</p>
      <p>If you want more information on a card, just keep pressing it.</p>`,
    },
    cards_play: {
      title: `Explanations: choosing a deck`,
      html: `
      <p>Here are your cards.</p>
      <p>There are 3 types of cards: the <span class="has-text-danger">creatures</span>, the <span class="has-text-info">artifacts</span> and the <span class="has-text-primary">spells</span>.</p>
      <p><img src="img/tip_cards_play_example.png" /></p>
      <p>Each card has strength and defense for each side. The number in the center represents the life of the card. If the life of the card drops to 0, the card is destroyed and goes to your discard pile.</p>
      <p>Cards can also have side and side life abilities. Abilities are all explained when your pointer passes over the cards.</p>
      <p>Choose a deck on the left side. You can get more information on a card by pressing and holding it. Once you have chosen your deck, press "Choose deck ..." at the top of the interface.</p>`,
    },
  },
  cardInfo: {
    statsChanges: `Stats changes:`,
    hasHolo: `This card has his holo version unlocked`,
    hasPremium: `This card has his premium version unlocked`,
  },
  playerCard: {
    victories: `victories`,
    defeats: `defeats`,
    addAsAcolyte: `Add as acolyte`,
    removeAcolyte: `Remove from acolytes list`,
    report: `Report player`,
  },
  capacities: {
    treason: {
      title: `Treason`,
      description: `If the card is destroyed by an opponent, it must be returned to the board in the same place and owned by the opponent who destroyed it. The card loses this ability once it has been returned to the game. The card will be placed in the discard of its original owner.`,
    },
    threat: {
      title: `Threat`,
      description: `The card confronts with other cards placed on the side of the ability.`,
    },
    run: {
      title: `Run`,
      description: `At the start of your turn (before the draw phase), you can move one of the cards that has this ability.`,
    },
    burdenEarth: {
      title: `Burden Earth`,
      description: `If the card is destroyed by another card, the space occupied by this card can only be used from your next turn.`,
    },
    aura: {
      title: `Aura`,
      description: `The card placed on the side of the ability gains {strength}2{/strength} for all of its sides as long as it is controlled by the same player.`,
    },
    grow: {
      title: `Growth`,
      description: `The card gains {strength}2{/strength} at the start of each owner’s turn for 5 turns.`,
    },
    death: {
      title: `Death`,
      description: `If the card loses at least one hit point, it is destroyed. If the card is destroyed by a {creature}creature{/creature} or an {artifact}artifact{/artifact}, that {creature}creature{/creature} or that {artifact}artifact{/artifact} is destroyed.`,
    },
  },
};
