import { IGameAction, IGameCard } from '@thefirstspine/types-arena';

export default class AnimationsHelper {

  public static spellAnimation(action: IGameAction<any>, stack: IAnimation[]) {
    AnimationsHelper.playAnimation(
      stack,
      {
        name: 'spell',
        fromUser: action.user,
        metadata: {
          coords: action && action.response ? action.response.boardCoords : '3-3',
        },
        effects: [],
        sfx: [],
      }, 2);
  }

  public static throwCardsAnimation(action: IGameAction<any>, stack: IAnimation[]) {
    AnimationsHelper.playAnimation(
      stack,
      {
        name: 'throw-cards',
        fromUser: action.user,
        metadata: {
        },
        effects: [],
        sfx: ['audio/sfx-pick-card.mp3'],
      }, 1);
  }

  public static takeCardsAnimation(stack: IAnimation[]) {
    AnimationsHelper.playAnimation(
      stack,
      {
        name: 'take-card',
        fromUser: 0,
        metadata: {
        },
        effects: [],
        sfx: ['audio/sfx-take-card.mp3'],
      }, .5);
  }

  public static releaseCardsAnimation(stack: IAnimation[]) {
    AnimationsHelper.playAnimation(
      stack,
      {
        name: 'release-card',
        fromUser: 0,
        metadata: {
        },
        effects: [],
        sfx: ['audio/sfx-release-card.mp3'],
      }, .5);
  }

  public static spellHealAnimation(action: IGameAction<any>, stack: IAnimation[]) {
    AnimationsHelper.playAnimation(
      stack,
      {
        name: 'spell-heal',
        fromUser: action.user,
        metadata: {
          coords: action && action.response ? action.response.boardCoords : '3-3',
        },
        effects: [],
        sfx: ['audio/sfx-heal.mp3'],
      }, 2);
  }

  public static spellThunderAnimation(action: IGameAction<any>, stack: IAnimation[]) {
    AnimationsHelper.playAnimation(
      stack,
      {
        name: 'spell-thunder',
        fromUser: action.user,
        metadata: {
          coords: action && action.response ? action.response.boardCoords : '3-3',
        },
        effects: ['shake-lighten'],
        sfx: ['audio/sfx-thunder.mp3'],
      }, 3);
  }

  public static spellEtherAnimation(action: IGameAction<any>, stack: IAnimation[]) {
    AnimationsHelper.playAnimation(
      stack,
      {
        name: 'spell-ether',
        fromUser: action.user,
        metadata: {
          coords: action && action.response ? action.response.boardCoords : '3-3',
        },
        effects: ['shake', 'lighten'],
        sfx: ['audio/sfx-ether.mp3'],
      }, 3);
  }

  public static spellReconstructAnimation(action: IGameAction<any>, stack: IAnimation[]) {
    AnimationsHelper.playAnimation(
      stack,
      {
        name: 'spell-reconstruct',
        fromUser: action.user,
        metadata: {
          coords: action && action.response ? action.response.boardCoords : '3-3',
        },
        effects: [],
        sfx: ['audio/sfx-reconstruct.mp3'],
      }, 3);
  }

  public static spellPutrefactionAnimation(action: IGameAction<any>, stack: IAnimation[]) {
    AnimationsHelper.playAnimation(
      stack,
      {
        name: 'spell-putrefaction',
        fromUser: action.user,
        metadata: {
          coords: action && action.response ? action.response.boardCoords : '3-3',
        },
        effects: [],
        sfx: ['audio/sfx-putrefaction.mp3'],
      }, 2);
  }

  public static spellReplacementAnimation(action: IGameAction<any>, stack: IAnimation[]) {
    AnimationsHelper.playAnimation(
      stack,
      {
        name: 'spell-replacement',
        fromUser: action.user,
        metadata: {
          coords: action && action.response ? action.response.boardCoords : '3-3',
        },
        effects: ['darken'],
        sfx: ['audio/sfx-replacement.mp3'],
      }, 2);
  }

  public static spellRuinAnimation(action: IGameAction<any>, stack: IAnimation[]) {
    AnimationsHelper.playAnimation(
      stack,
      {
        name: 'spell-ruin',
        fromUser: action.user,
        metadata: {
          coords: action && action.response ? action.response.boardCoords : '3-3',
        },
        effects: [],
        sfx: ['audio/sfx-ruin.mp3'],
      }, 2);
  }

  public static spellFireAnimation(action: IGameAction<any>, stack: IAnimation[]) {
    AnimationsHelper.playAnimation(
      stack,
      {
        name: 'spell-fire',
        fromUser: action.user,
        metadata: {
          coords: action && action.response ? action.response.boardCoords : '3-3',
        },
        effects: [],
        sfx: ['audio/sfx-fire.mp3'],
      }, 2);
  }

  public static spellAlterTheFateAnimation(action: IGameAction<any>, stack: IAnimation[]) {
    AnimationsHelper.playAnimation(
      stack,
      {
        name: 'spell-alter-the-fate',
        fromUser: action.user,
        metadata: {
          coords: action && action.response ? action.response.boardCoords : '3-3',
        },
        effects: [],
        sfx: ['audio/sfx-alter-the-fate.mp3'],
      }, 2);
  }

  public static spellAchievement(action: IGameAction<any>, stack: IAnimation[]) {
    AnimationsHelper.playAnimation(
      stack,
      {
        name: 'spell-achievement',
        fromUser: action.user,
        metadata: {
          coords: action && action.response ? action.response.boardCoords : '3-3',
        },
        effects: [],
        sfx: ['audio/sfx-achieve.mp3'],
      }, 2);
  }

  public static confrontsAnimation(action: IGameAction<any>, stack: IAnimation[]) {
    AnimationsHelper.playAnimation(
      stack,
      {
        name: 'confronts',
        fromUser: action.user,
        metadata: {
          coords: action && action.response ? action.response.boardCoordsFrom : '3-3',
          from: action && action.response ? action.response.boardCoordsFrom : '3-3',
          to: action && action.response ? action.response.boardCoordsTo : '3-3',
        },
        effects: ['shake'],
        sfx: ['audio/sfx-confronts.mp3'],
      }, 1);
  }

  public static lifeAnimation(card: IGameCard, life: number, stack: IAnimation[]) {
    AnimationsHelper.playAnimation(
      stack,
      {
        name: 'life',
        fromUser: card.user,
        metadata: {
          coords: card.coords ? `${card.coords.x}-${card.coords.y}` : '3-3',
          count: life,
        },
        effects: [],
        sfx: [],
      }, 2);
  }

  public static destroyedAnimation(card: IGameCard, stack: IAnimation[]) {
    AnimationsHelper.playAnimation(
      stack,
      {
        name: 'destroyed',
        fromUser: card.user,
        metadata: {
          coords: card.coords ? `${card.coords.x}-${card.coords.y}` : '3-3',
        },
        effects: [],
        sfx: ['audio/sfx-destroyed.mp3'],
      }, 1);
  }

  public static playAnimation(stack: IAnimation[], animation: IAnimation, time: number) {
    stack.push(animation);
    setTimeout(() => {
      const index: number = stack.findIndex((a) => a === animation);
      stack.splice(index, 1);
    }, time * 1000);
  }
}

export interface IAnimation {
  name: string;
  fromUser: number;
  metadata?: any;
  effects: effect[];
  sfx: string[];
}

export declare type effect = 'shake'|'darken'|'lighten'|'shake-lighten';
