






















import { Component, Vue } from 'vue-property-decorator';
import Card from '@/components/Card.vue';
import restService from '../services/rest.service';
import arenaService from '../services/arena.service';
import { IWizard } from '@thefirstspine/types-arena';
import wizardService from '../services/wizard.service';

@Component({
  components: {
    Card,
  },
})
export default class Home extends Vue {

  public async mounted() {
    const wizard: IWizard = await wizardService.getMe();
    this.$data.name = wizard.name;
  }

  public data() {
    return {
      name: '',
    };
  }

  public async validate() {
    const name = this.$data.name;
    if (!name || name.length < 5) {
      alert(this.$t('name.longer').toString());
      return;
    }
    await wizardService.setMe({
      name,
    });

    const wizard: IWizard = await wizardService.getMe();
    if (wizard.name === name) {
      const redirect: string = this.$router.currentRoute.query.redirect ?
        this.$router.currentRoute.query.redirect.toString() :
        '/profile';
      this.$router.push(redirect);
    } else {
      alert(this.$t('name.failed'));
    }
  }

}
