

















































































































import { Component, Vue } from 'vue-property-decorator';
import wizardService from '../services/wizard.service';
import optionsService from '../services/options.service';
import CollectedItem from '@/components/CollectedItem.vue';
import { IWizardItem } from '@thefirstspine/types-arena';

@Component({
  components: {
    CollectedItem,
  },
})
export default class Collection extends Vue {

  public async mounted() {
    this.$data.wizard = await wizardService.getMe();
  }

  public data() {
    return {
      wizard: null,
      options: optionsService.options,
      types: {
        holo: {
          filter: /^holo-/,
        },
        premium: {
          filter: /^premium-/,
        },
        cover: {
          filter: /^cover-/,
        },
        style: {
          filter: /^style-/,
        },
      },
      items: {
        shop: {
          classic: ['holo-hunter', 'holo-summoner', 'holo-conjurer', 'holo-sorcerer', 'holo-soul-of-a-sacrified-hunter', 'holo-deadly-viper', 'holo-barbers', 'holo-shadows-banner', 'holo-ether', 'style-nostalgy', 'style-scales', 'style-cartographer', 'style-ghostly', 'style-sunlight', 'style-scary', 'style-frozen', 'cover-hunter', 'cover-summoner', 'cover-conjurer', 'cover-sorcerer', 'cover-barbers', 'cover-banshee', 'cover-replacement'],
          corsairs: ['style-corsair', 'cover-corsair'],
        },
        tournaments: {
          tournaments: ['premium-conjurer', 'premium-summoner', 'premium-sorcerer', 'premium-hunter'],
        },
        cycles: {
          'souvenirs': ['holo-conjurer-souvenir', 'holo-summoner-souvenir', 'holo-sorcerer-souvenir', 'holo-hunter-souvenir'],
          'treasure': ['holo-golden-galleon'],
          'great-ancient': ['holo-great-ancient-egg'],
          'harvester': ['holo-blood-strength'],
          'crowned-souls': ['holo-annihilation-matt'],
          'snow-man': ['holo-ice-statue', 'holo-frozen-fox', 'holo-frozen-banshee', 'holo-frozen-viper'],
          'absurdal': ['holo-mutate-fox', 'holo-mutate-banshee', 'holo-mutate-tower', 'holo-mutate-barbed-wires'],
          'drifter': ['holo-mara-fox', 'holo-mara-banshee', 'holo-argento-tower', 'holo-argento-barbed-wires', 'holo-insane-putrefaction', 'holo-insane-ruin'],
        },
        craft: {
          'rune-holo': ['holo-smoky-totem', 'holo-veneniagora', 'holo-heal', 'holo-reconstruct', 'holo-putrefaction', 'holo-ruin'],
          'rune-solid': ['cover-smoky-totem', 'cover-veneniagora', 'cover-heal', 'cover-reconstruct', 'cover-putrefaction', 'cover-ruin'],
          'rune-ghostly': ['style-luminescent', 'style-burning', 'style-bloody'],
        },
      },
      selectedType: null,
      selectedCategory: 'shop',
    };
  }

  public selectType(type: string) {
    this.$data.selectedType = type;
    this.$data.selectedCategory = Object.keys(this.$data.items)
      .find((category: string) => this.getItemsOfCategory(type, category).length > 0);
  }

  public allItems(): string[] {
    const items: string[] = [];
    Object.keys(this.$data.items).forEach((category: string) => {
      Object.keys(this.$data.items[category]).forEach((subcategory) => {
        items.push(...this.$data.items[category][subcategory]);
      });
    });
    return items;
  }

  public countItem(type: string): number {
    const item: IWizardItem|undefined = this.$data.wizard.items.find((i: IWizardItem) => i.name === type);
    return item ? item.num : 0;
  }

  public getItemsOfSubcategory(type: string, category: string, subcategory: string): string[] {
    return this.$data.items[category][subcategory]
      .filter((i: string) => this.$data.types[type].filter.test(i));
  }

  public getItemsOfCategory(type: string, category: string): string[] {
    return Object.keys(this.$data.items[category])
      .reduce((acc: string[], value: string) => [...acc, ...this.getItemsOfSubcategory(type, category, value)], [])
      .filter((i: string) => this.$data.types[type].filter.test(i));
  }

}
