export default {
  app: {
    home: 'Accueil',
    cards: 'Cartes',
    shop: 'Boutique',
    profile: 'Profil',
    signupOrLogin: 'Inscription ou connexion',
    cycleValidUntil: `Cycle disponible jusqu'au`,
    dateFormat: `D/MM/YYYY`,
    error: `Erreur`,
    agreed: `D'accord`,
    oops: `Oups`,
    yes: `Oui`,
    no: `Non`,
  },
  options: {
    gameplayAndDisplay: `Jouabilité & affichage`,
    soundsAndMusic: `Sons et musique`,
    account: `Compte`,
    displaySize: `Taille de l'affichage (necessite de redémarrer le jeu)`,
    cardsColors: `Couleurs des cartes`,
    displayHistory: `Afficher l'historique des actions`,
    playMusic: `Jouer la musique`,
    playSounds: `Jouer les effets sonores`,
    logout: `Se déconnecter`,
    logoutOnThisClient: `Se déconnecter sur ce client`,
    language: `Langue`,
    save: `Sauvegarder`,
    concede: `Abandonner la partie`,
    concedeWarning: `Attention : les abus seront punis.`,
    resetTips: `Relancer la Première Expérience de Jeu`,
    resetTipsButton: `Relancer`,
    hideTips: `Cacher la Première Expérience de Jeu`,
    hideTipsButton: `Cacher`,
    changeRealm: `Changer de domaine`,
    change: `Changer`,
  },
  quest: {
    daily: `Quête journalière`,
    weekly: `Quête hebdomadaire`,
  },
  toast: {
    loot: {
      'title': `Récompense`,
      'shard': `Eclats de Mana`,
      'holo': `{cardName} holo`,
      'premium': `{cardName} premium`,
      'rune-holo': `Rune holo`,
      'rune-solid': `Rune solide`,
      'rune-ghostly': `Rune fantomatique`,
      'golden-galleon': `Galions d'Or`,
      'candy-shard': `Eclat de Sucre`,
      'coin': `Pièce Etrange`,
    },
    quest: {
      complete: `Quête accomplie`,
      obtain: `Quête obtenue`,
      progress: `Progression de quête`,
    },
  },
  home: {
    immediate: `Partie rapide`,
    immediateDescription: `Trouvez un opposant rapidement dans une partie aléatoire.`,
    daily: `Challenge du jour`,
    dailyDescription: `Participez à la partie du jour. Les premiers gains du jours sont doublés.`,
    cycle: `Versus thématique`,
    cycleDescription: `Jouez une partie en rapport avec le cycle en cours.`,
    private: `Partie privée`,
    privateDescription: `Créez une partie privée avec vos règles.`,
    cycleBlock: `Le {cycleName} prendra fin dans {days} jours`,
    cycleBlockDescription: `Participez au tournoi de ce cycle pour remporter les récompenses ! Plus d'infos en cliquant ici`,
    newCycleTitle: `Nouveau cycle`,
    newCycleOk: `D'accord.`,
    play: 'Jouer',
    cards: 'Cartes',
    shop: 'Boutique',
    collection: 'Collection',
    newEventTitle: `Nouvel événement`,
    newEventOk: `D'accord.`,
    fpe: `Première expérience de jeu`,
  },
  login: {
    welcome: `Bienvenue !`,
    welcome2: `Connectez-vous, ou créez un compte sorcier`,
    email: `E-mail`,
    password: `Mot de passe`,
    login: `Se connecter`,
    subscribe: `S'inscrire`,
    loginWithFacebook: `Se connecter avec Facebook`,
    forgottenPassword: `Mot de passe oublié`,
    checkCredentials: `Vérifiez vos identifiants`,
  },
  about: {
    title: `Merci de vous intéresser à notre jeu !`,
    part1: `Ce projet de longue date est un jeu en version papier présenté sur de nombreux festivals en France. Malheureusement, le financement d'un tel jeu n'est pas facile et nous avons décidé de créer une version numérique pour rassembler les fonds nécessaires au façonnage du jeu.`,
    part2: `Les achats en ligne que vous ferez nous rapprochera de cet objectif. Malgré tout, le contenu de ce jeu <strong>n'est pas verrouillé derrière des micro-transactions</strong>. Nous essayons au possible d'être respectueux envers nos joueurs, car sans vous, nous ne sommes rien.`,
    part3: `Cette version numérique nous permet aussi de varier les plaisirs et de tester de nouvelles choses. Enfin, elle vous permet d'essayer le prototype sans avoir à utiliser vos cartouches d'encre, car on est certain que c'est en jouant que vient le plaisir de jouer 😀`,
    part4: `Une dernière chose avant de commencer : ce jeu <strong>n'a pas été créé par une équipe de 50 personnes</strong>. Nous sommes trois : game designer, illustrateur et testeur. Nous avons mis beaucoup de temps et d'amour dans ce jeu, et on espère que vous l'apprécierez 🤗`,
    start: `Commencer à jouer`,
  },
  selectRealm: {
    title: 'Choisissez un domaine',
    part1: 'Les serveurs de Arena sont organisés en "domaine". Chaque domaine dispose de son propre niveau de jeu ainsi que sa langue.',
    part2: 'Si vous jouez pour la première fois à The First Spine nous vous conseillons de prendre un domaine au niveau de jeu débutant. Vous pouvez changer de domaine à tout moment dans les options de jeu.',
    name: 'Nom',
    languageTitle: 'Langue',
    regionTitle: 'Région',
    levelTitle: 'Niveau',
    chose: 'Choisir',
    language: {
      fr: 'Français',
      en: 'Anglais',
    },
    region: {
      eu: 'Europe',
    },
    level: {
      low: 'Débutant',
      high: 'Expert',
    },
  },
  cards: {
    searchPlaceholder: `Recherchez une carte...`,
    filters: {
      destinyTitle: `Seulement les destinées`,
      destinyDescription: `Les destinées sont les decks principaux du jeu. Ils contiennent 36 cartes.`,
      originTitle: `Seulement les origines`,
      originDescription: `Les origines sont les decks secondaires du jeu. Ils contiennent 6 cartes pour chaque destinée.`,
      allTitle: `Tous les decks`,
    },
    sort: {
      name: `Trier par nom`,
      type: `Trier par type`,
      number: `Trier par quantité`,
    },
    styles: {
      'style-': `Style classique`,
      'style-nostalgy': `Style nostalgique`,
      'style-scales': `Style écailles`,
      'style-cartographer': `Style cartographe`,
      'style-corsair': `Style Corsaire`,
      'style-ghostly': `Style Fantomatique`,
      'style-sunlight': `Style Ensoleillé`,
      'style-burning': `Style Brûlant`,
      'style-luminescent': `Style Luminescent`,
      'style-scary': `Style Effrayant`,
      'style-purple-candy': `Style Sucre Violet`,
      'style-bloody': `Style Sanglant`,
      'style-frozen': `Style Gelé`,
      'style-festive': `Style Festif`,
    },
    covers: {
      'cover-': `Protège-cartes classique`,
      'cover-hunter': `Protège-cartes Chasseur`,
      'cover-summoner': `Protège-cartes Invocateur`,
      'cover-conjurer': `Protège-cartes Illusionniste`,
      'cover-corsair': `Protège-cartes Corsaire`,
      'cover-sorcerer': `Protège-cartes Prestidigitateur`,
      'cover-barbers': `Protège-cartes Barbelés`,
      'cover-veneniagora': `Protège-cartes Vénéniagora`,
      'cover-smoky-totem': `Protège-cartes Totem Fûmant`,
      'cover-heal': `Protège-cartes Soin`,
      'cover-reconstruct': `Protège-cartes Reconstruction`,
      'cover-banshee': `Protège-cartes Banshee`,
      'cover-purple-candy': `Protège-cartes Sucre Violet`,
      'cover-putrefaction': `Protège-cartes Putréfaction`,
      'cover-ruin': `Protège-cartes Ruine`,
      'cover-replacement': `Protège-cartes Remplacement`,
      'cover-the-authority': `Protège-cartes L'Autorité`,
      'cover-monstrous-portal': `Protège-cartes Portail Monstrueux`,
    },
    gameSelectionTitle: `Choix d'un deck en cours`,
    gameSelectionButton: `Choisir le deck {deckname}`,
    backToDecks: `Retour aux decks`,
    stylesTitle: `Styles de cartes`,
    coversTitle: `Protège-cartes`,
  },
  profile: {
    'triumphs': `Triomphes`,
    'triumphs-category-activities': `Activités`,
    'triumphs-category-destinies-and-origins': `Destinées & origines`,
    'triumphs-category-achievements': `Exploits`,
    'triumphs-category-targets': `Cibles`,
    'history': `Combats`,
    'victory': `victoire`,
    'defeat': `défaite`,
    'unkown': `nom inconnu`,
    'changeAvatar': `Changer d'avatar`,
    'choseAvatar': `Choisir cet avatar`,
    'choseTriumph': `Choisir ce triomphe en titre`,
    'back': `Retour`,
    'gametype-classic': `Partie classique`,
    'gametype-tournament': `Tournoi du cycle`,
    'gametype-fpe': `Première expérience de jeu`,
    'gametype-standard': `Standard`,
    'gametype-quick': `Rapide`,
    'datetime': `DD/MM/YYYY - HH:mm`,
    'profile': 'Profil',
    'triumphsExplication': `Vos triomphes représentent vos exploits sur Arena. Utilisez-les pour changer votre titre et affichez votre détermination !`,
    'quests': `Quêtes`,
    'abandon': `Abandonner`,
  },
  social: {
    emptyTitle: `Votre liste d'acolytes est vide`,
    emptyText: `Vous pouvez ajouter des acolytes en cliquant sur "Ajouter en tant qu'acolyte" lorsque vous affichez une carte de joueur.`,
    acolytes: `Vos acolytes`,
    future: `Dans un futur proche vous pourrez envoyer des messages à vos acolytes.`,
    inList: `{name} vous a ajouté à sa liste d'acolytes.`,
    notInList: `Vous ne faites pas partie de la liste d'acolytes de {name}`,
    unkonwnIdTitle: `Cet identifiant est inconnu`,
    unkonwnIdText: `L'identifiant saisi est inconnu.`,
  },
  play: {
    destiny: `Destinée`,
    origin: `Origine`,
    launch: `Lancer la partie`,
    notEnoughShields: `Vous n'avez plus de boucliers de disponible.`,
    choseName: `Avant de commencer à jouer, choisissez votre nom de sorcier.`,
    placeLabel: `Lieu :`,
    modifiersLabel: `Modificateurs :`,
    queues: {
      fpe: {
        title: `Première Expérience de jeu`,
        description: `Découvrez les règles de The First Spine avec cette partie tutorée. Vous pouvez à tout moment la rejouer ou la masque dans les options de jeu en appuyant sur "Echap".`,
      },
      immediate: {
        title: `Partie rapide`,
        description: `Trouvez un opposant rapidement dans une partie sans modificateur.`,
      },
      daily: {
        title: `Challenge du jour`,
        description: `La partie du jour contient un lieu et un modificateur fixe pour la journée. Les gains sont doublés pour la première partie de chaque jour.`,
      },
      cycle: {
        title: `Versus thématique`,
        description: `Chaque cycle, participez à une partie exclusive au cycle en cours. Vos gains sont doublés en cas de victoire, mais vous ne gagnez rien en cas de défaite.`,
      },
      private: {
        title: `Partie privée`,
      },
    },
    modifiers: {
      'immediate': {
        title: `Immédiat`,
        description: `Le matchmaking est plus rapide.`,
      },
      'random': {
        title: `Au hasard`,
        description: `Les conditions de votre prochaines bataille sont encore inconnus.`,
      },
      'great-ancients-eggs': {
        title: `Les oeufs de Grands Anciens`,
        description: `Votre deck sera agrémentés d'oeufs qui écloront au prochain tour.`,
      },
      'souvenirs-from-your-enemy': {
        title: `Souvenirs de votre adversaire`,
        description: `Votre deck aura des cartes correspondantes à la destinée de votre adversaire.`,
      },
      'daily': {
        title: `Du jour`,
        description: `Vos gains seront doublés pour chaque première partie de la journée.`,
      },
      'cycle': {
        title: `Du cycle`,
        description: `Vos gains sont doublés en cas de victoire. Vous n'avez pas de gains en cas de défaite.`,
      },
      'harvesting-souls': {
        title: `Moisson d'Âmes`,
        description: `Votre deck est agrémenté de sortilèges Force de Sang qui détruisent des créatures tout en vous soignant.`,
      },
      'golden-galleons': {
        title: `Galions d'Or`,
        description: `Votre deck contient des cartes Galion d'Or qui sont converties en monnaie éponyme à la fin de la partie.`,
      },
      'annihilation-matts': {
        title: `Mâts de l'Annihilation`,
        description: `Des Mâts de l'Annihilation sont présents sur le plateau. Le joueur qui détruit l'un d'eux perd la partie.`,
      },
      'trick-or-treat': {
        title: `Un bonbon ou un Tour`,
        description: `Le Festival des Facéties est ouvert ! Des cartes farceuses sont ajoutées à votre deck, et vous permettront de gagner des Eclats de Sucre !`,
      },
      'frozen-statues': {
        title: `Statues gelées`,
        description: `Des statues gelées sont placées sur le plateau de jeu. Une fois détruites, elles sont retournées et placées sous le contrôle du joueur qui les a détruites.`,
      },
      'triple-shards': {
        title: `Eclats triplés`,
        description: `Les Eclats de Mana sont triplés.`,
      },
      'mutations': {
        title: `Mutations`,
        description: `Des cartes "Mutations" sont ajoutées à votre deck qui permettent de changer vos créatures et artéfacts.`,
      },
      'drifter': {
        title: `Vagabond`,
        description: `Ajoute des cartes de Drifter's Tales, le jeu solo disponible sur Steam.`,
      },
    },
    themes: {
      'random': {
        title: `Lieu inconnu`,
        description: `Vous laissez faire votre destin. Vous saurez le lieu de votre bataille lorsque vous y arriverez.`,
      },
      'dead-forest': {
        title: `Forêt Endormie`,
        description: `Cette forêt a vu passer de nombreux voyageurs mais elle est toujours resté sans animaux, comme s'ils savaient qu'elle était maudite.`,
      },
      'spine-s-cave': {
        title: `La Grotte de l'Epine`,
        description: `Une grotte où l'on célèbre la naissance de l'Epine Primordiale. On dit que Volk'ha il a élu domicile avant de mourir pour la première fois.`,
      },
      'forgotten-cemetery': {
        title: `Cimetière oublié`,
        description: `Personne ne se souvient de ce cimetière où Démence est réapparu. Les noms sur les tombes ont tous été effacés par le temps.`,
      },
      'wasted-fields': {
        title: `Les Champs devastés`,
        description: `Rien ne pousse sur ces champs autrefois féconds. Seule la douleur peut y être récoltée.`,
      },
      'sacrifice-church': {
        title: `Eglise du Sacrifice`,
        description: `Lieu de pélerinage final des Chasseurs, l'Eglise du Sacrifice libère les pélerins du poids des pouvoirs de la Couronne par la mort. Mais leur mort signifie-t-elle la fin de leur existence ?`,
      },
      'snow-man-lair': {
        title: `Caverne de l'Homme en Blanc`,
        description: `L'Homme en Blanc séjourne dans cette caverne aux dédales labyrinthiques. Mieux vaut ne pas s'y perdre, sous peine de mourir gelé.`,
      },
      'ruined-laboratory': {
        title: `Laboratoire en ruines`,
        description: `Un laboratoire d'Erudit oublié depuis longtemps. Il y a des secrets qu'il vaut mieux ne pas découvrir, mais les Erudits étaient prêts à braver tous les interdits.`,
      },
    },
  },
  private: {
    standard: `Partie standard`,
    standardDescription: `Jouez une partie standard selon les règles officielles.`,
    quick: `Partie rapide`,
    quickDescription: `Une partie rapide vous rapproche de votre adversaire en diminuant la zone de jeu.`,
    all: `Toutes`,
    none: `Aucune`,
    chose: `Choisir ce type de jeu`,
    joinTitle: `Rejoindre une partie`,
    or: `ou`,
    createGame: `Créer une partie`,
    nbOfPlayers: `Nombre de joueurs`,
    destinies: `Destinées autorisées`,
    origins: `Origines autorisées`,
    gameCreated: `Partie créée !`,
    gameCreatedText: `Votre partie a été créée et sera disponible pendant les 30 prochaines minutes.  Donnez ce code à vos amis pour qu'ils vous rejoingnent :`,
    nextStep: `Etape suivante`,
    errorOnJoin: `Impossible de rejoindre la partie privée`,
    theme: `Lieu`,
    modifier: `Modificateur`,
    speed: `Vitesse`,
    random: `Au hasard`,
    speedNormal: `Normale`,
    speedQuick: `Rapide`,
    speedSlow: `Lente`,
  },
  matchmaking: {
    searching: `Recherche d'opposants...`,
    full: `La file d'attente est pleine`,
    wait: `En attente de joueurs...`,
    prepare: `Préparez-vous !`,
    solo: `Préparation de la partie...`,
    quit: `Quitter le matchmaking`,
    advice1: `Les matchmaking ont lieu toutes les minutes. Les tournois sont classés tandis que les parties classiques sont basées sur l'ordre d'arrivée.`,
    advice2: `Les règles du tournoi changent à chaque cycle. Vous pouvez consulter leurs spécificités dans l'écran de paramétrage de jeu.`,
    advice3: `Chaque tournoi dispose d'une récompense pour le meilleur joueur du cycle, ainsi que pour avoir participé à un match en entier.`,
    advice4: `En cas de mauvais comportement vous serez maudit et votre partie en sera altérée en ayant des cartes maudites dans votre main.`,
    advice5: `TFS, c'est aussi une histoire à découvrir. Découvrez l'univers en lisant les nombreuses cartes qui composent le jeu.`,
    advice6: `Chaque cycle dure 6 semaines et dispose de ses propres cosmétiques disponibles en boutique. Ils ne disparaîtront pas, vous avez tout votre temps !`,
    advice7: `Vous pouvez reprendre une partie à tout moment, même après une déconnexion ou une fermeture accidentelle du client.`,
    advice8: `Vous pouvez consulter vos holos, premium et vos cartes saisonnières dans votre collection de cartes.`,
    advice9: `Gardez un oeil sur vos triomphes dans votre profil. Ils vous aideront à afficher votre détermination à vos adversaires.`,
  },
  game: {
    'displayDiscard': `Affichage de la défausse`,
    'discardBetween': `Défausser entre {min} et {max} carte(s).`,
    'discardExactly': `Défausser {value} carte(s).`,
    'validateDiscard': `Valider la défausse`,
    'pass': `Passer`,
    'timeLeft': `Il vous reste {expirationTime} secondes pour répondre`,
    'lose': `Votre adversaire a eu raison de vous`,
    'win': `Vous avez triomphé de votre adversaire`,
    'returnToHome': `Retourner à l'accueil`,
    'loot-shard': `Eclats de mana`,
    'loot-defeat-mark': `Marque de défaite`,
    'loot-victory-mark': `Marque de victoire`,
    'cancelAction': `Annuler l'action`,
    'choseAction': `Choisissez une action`,
    'seeDiscard': `Voir la défausse`,
    'seeLoots': `Voir les récompenses`,
  },
  shop: {
    categories: {
      featuredTitle: `En vedette ce cycle`,
      featuredDescription: `Les cartes & les styles de ce cycle`,
      holosTitle: `Holos`,
      holosDescription: `Les cartes holos des cycles derniers`,
      stylesTitle: `Styles`,
      stylesDescription: `Les styles des cycles derniers`,
      coversTitle: `Protèges-cartes`,
      coversDescription: `Les protèges-cartes des cycles derniers`,
      shardsTitle: `Eclats de mana`,
      shardsDescription: `Achetez des éclats de mana avec de l'argent réel`,
      seasonialTitle: `Saisonier`,
      seasonialDescription: `Les articles uniquement disponibles pour ce cycle`,
    },
    alreadyPurchased: `déjà acheté`,
    purchase: `Acheter {item}`,
    purchaseWithMoney: `Acheter {item} avec de l'argent réel`,
    purchaseFor: `Acheter pour {price} €`,
    exchangeFor: `Echanger contre {price}`,
    purchased: `{item} a été acheté !`,
    back: `Retour`,
    cancel: `Annuler`,
  },
  name: {
    chose: `Choisissez un nom de sorcier.`,
    choseText: `Les autres joueurs vous connaîtront sous ce nom.`,
    validate: `Valider`,
    longer: `Choisissez un nom plus long.`,
    failed: `La modification du nom a échoué.`,
    placeholder: `Votre nom de sorcier`,
  },
  runes: {
    'rune-holo': {
      title: `Rune holo`,
      text: `Une rune brillante qui peut être utilisée pour créer une nouvelle carte Holo.`,
    },
    'rune-solid': {
      title: `Rune solide`,
      text: `Une rune qui représente la solidité. Peut être utilisée pour créer un protège cartes.`,
    },
    'rune-ghostly': {
      title: `Rune fantomatique`,
      text: `Une rune mystérieuse qui a la forme désirée par celui qui la regarde. Peut être utilisée pour créer un nouveau style.`,
    },
    'craft': `Fabriquer`,
    'craft-rune-holo': `Créer une holo pour`,
    'craft-rune-solid': `Créer un protège-cartes pour`,
    'craft-rune-ghostly': `Créer un style pour`,
    'no-item': `Pas assez d'ingrédients`,
    'no-possibility': `Vous avez déjà tout obtenu`,
  },
  collection: {
    'title': `Votre collection`,
    'text1': `Voici la collection de tous les cosmétiques disponibles sur Arena.`,
    'text2': `Si vous avez manqué un cosmétique saisonnier, il reviendra : nous n'avons aucune mécanique de rétention prédatrice envers nos joueurs.`,
    'back': `Retour`,
    'type-holo': `Holos`,
    'type-premium': `Cartes premiums`,
    'type-cover': `Protège-cartes`,
    'type-style': `Styles`,
    'category-shop': `Achetable en boutique`,
    'category-tournaments': `Participation aux tournois`,
    'category-cycles': `Contenus de cycles`,
    'category-craft': `Fabrication`,
    'subcategory-shop-classic': `Boutique en ligne classique`,
    'subcategory-shop-classic-description': `Cosmétiques présents dans la boutique en ligne.`,
    'subcategory-shop-corsairs': `Boutique des Corsaires`,
    'subcategory-shop-corsairs-description': `Cosmétiques disponibles dans la boutique saisonnière pendant l'arrivée des corsairs.`,
    'subcategory-tournaments-tournaments': `Tournois des Cycles`,
    'subcategory-tournaments-tournaments-description': `Récompenses pour les gagnants des tournois des Cycles.`,
    'subcategory-cycles-great-ancient': `Cycle du Grand Ancien`,
    'subcategory-cycles-great-ancient-description': `Cosmétiques pouvant être obtenues lors du Cycle du Grand Ancien.`,
    'subcategory-cycles-harvester': `Cycle de la Moisson`,
    'subcategory-cycles-harvester-description': `Cosmétiques pouvant être obtenues lors du Cycle de la Moisson.`,
    'subcategory-cycles-crowned-souls': `Cycle des Âmes Couronnées`,
    'subcategory-cycles-crowned-souls-description': `Cosmétiques pouvant être obtenues lors du Cycle des Âmes Couronnées.`,
    'subcategory-cycles-treasure': `Cycle du Trésor`,
    'subcategory-cycles-treasure-description': `Cosmétiques pouvant être obtenues lors du Cycle du Trésor.`,
    'subcategory-cycles-souvenirs': `Cycle des Souvenirs`,
    'subcategory-cycles-souvenirs-description': `Cosmétiques pouvant être obtenues lors du Cycle des Souvenirs.`,
    'subcategory-cycles-snow-man': `Cycle de l'Homme en Blanc`,
    'subcategory-cycles-snow-man-description': `Cosmétiques pouvant être obtenues lors du Cycle de l'Homme en Blanc.`,
    'subcategory-cycles-absurdal': `Cycle de l'Absurdal`,
    'subcategory-cycles-absurdal-description': `Cosmétiques pouvant être obtenues lors du Cycle de l'Absurdal.`,
    'subcategory-cycles-drifter': `Cycle du Vagabond`,
    'subcategory-cycles-drifter-description': `Cosmétiques pouvant être obtenues lors du Cycle du Vagabond.`,
    'subcategory-craft-rune-holo': `Rune Holo`,
    'subcategory-craft-rune-holo-description': `Cartes holographiques fabriquées avec une Rune Holo pouvant être obtenue dans les quêtes hebdomadaires.`,
    'subcategory-craft-rune-solid': `Rune Solide`,
    'subcategory-craft-rune-solid-description': `Protège-cartes fabriqués avec une Rune Solide pouvant être obtenue dans les quêtes hebdomadaires.`,
    'subcategory-craft-rune-ghostly': `Rune Fantomatique`,
    'subcategory-craft-rune-ghostly-description': `Styles de cartes fabriqués avec une Rune Fantomatique pouvant être obtenue dans les quêtes hebdomadaires.`,
  },
  tips: {
    understood: 'Compris',
    rules: `Consulter les règles (nouvelle fenêtre)`,
    cards_home: {
      title: `Explications : les decks`,
      html: `
      <p>Voici vos decks. Dans The First Spine, vous n'avez pas à jouer des heures ou à payer pour disposer des mêmes cartes que vos opposants.</p>
      <p>Tous les decks sont disponibles pour tous les joueurs et disposent des mêmes cartes.</p>`,
    },
    cards_deck: {
      title: `Explications : les cartes`,
      html: `
      <p>Voici vos cartes.</p>
      <p>Il existe 3 types de cartes : les <span class="has-text-danger">créatures</span>, les <span class="has-text-info">artéfacts</span> et les <span class="has-text-primary">sortilèges</span>.</p>
      <p>Chaque carte dispose d'une force et d'une défense pour chaque côté. Le chiffre au centre représente la vie de la carte. Si la vie de la carte tombe à 0, la carte est détruite et part dans votre défausse.</p>
      <p>Les cartes peuvent aussi avoir des capacités sur les côtés et à côté de la vie. Les capacités sont toutes expliquées lorsque votre pointeur passe au-dessus des cartes.</p>`,
    },
    cards_covers: {
      title: `Explications : les protège-cartes`,
      html: `
      <p>Arena vous permet de personnaliser votre expérience de jeu en changeant l'arrière de vos cartes à volonté pendant une partie grâce aux protège-cartes.</p>
      <p>Vous pouvez acheter de nouveaux protège-cartes dans la boutique en ligne.</p>`,
    },
    cards_styles: {
      title: `Explications : les styles`,
      html: `
      <p>Vous pouvez changer l'aspect de vos cartes grâce aux styles.</p>
      <p>Vous pouvez acheter de nouveaux styles dans la boutique en ligne.</p>`,
    },
    shop: {
      title: `Explications : la boutique`,
      html: `
      <p>Notre objectif principal est de lancer une campagne de précommande avec la version papier du jeu. Mais pour cela, nous avons besoin de produire au moins 500 exemplaires du jeu afin d'avoir des prix corrects. Avec l'argent obtenu de cette version numérique du jeu, vous nous aiderez à atteindre cet objectif de lancer ce jeu en version physique.</p>
      <p>Chaque objet dans la boutique est achetable avec des éclats de mana, que vous pouvez remporter à la fin de chaque partie.</p>`,
    },
    runes: {
      title: `Explications : la fabrication`,
      html: `
      <p>Les quêtes hebdomadaires vous permettent d'obtenir des runes, utiles à la fabrication de cosmétiques.</p>
      <p>Lorsque vous obtenez un cosmétique, vous ne pourrez plus l'obtenir de nouveau afin d'éviter les doublons. Si les possibilités de cosmétiques sont épuisées, alors la fabrication ne sera pas possible. Nous ajoutons fréquemment de nouvelles recettes, alors revenez ici souvent !</p>`,
    },
    profile: {
      title: `Explications : votre profil`,
      html: `
      <p>Voici votre profil.</p>
      <p>En jouant vous pouvez remporter des triomphes que vous pouvez afficher à côté de votre nom. Ce triomphe sera visible par tous les joueurs.</p>
      <p>Vous pouvez modifier aussi votre avatar. Des avatars sont ajoutés régulièrement !</p>`,
    },
    game_setup: {
      title: `Explications : créer une partie`,
      html: `
      <p>Pour jouer, vous devez choisir deux decks qui seront mélangés : une destinée qui contient 36 cartes, et une origine qui contient 6 cartes. Pour ce faire, cliquez sur les cases au centre de l'interface.</p>
      <p>Dès que vous êtes prêt, cliquez sur "Lancer la partie". Ne vous inquiétez pas : on vous expliquera tout pas à pas.</p>`,
    },
    matchmaking: {
      title: `Explications : matchmaking`,
      html: `
      <p>A chaque fois que vous lancez une partie, nous recherchons des joueurs qui souhaitent combattre dans le même type de jeu.</p>
      <p>Il se peut que l'attente soit assez longue (de une à trois minutes) car le matchmaking est executé toutes les minutes sur nos serveurs.</p>
      <p>La Première Expérience de jeu ne comporte pas d'adversaire humain.</p>`,
    },
    fpe_2: {
      title: `Explications : au début de votre tour`,
      html: `
      <p>Au début de votre tour, vous pouvez vous défausser d'une carte gratuitement en la déposant dans votre défausse. Chaque carte supplémentaire vous coûtera un point de vie.</p>
      <p class="has-text-primary has-text-weight-bold">Pour cette partie scénarisée, défaussez-vous du soin (cette carte vous est inutile pour le moment).</p>
      <p>Pour ce faire, appuyez sur "Défausser" en bas de l'interface pour sélectionner l'action de défausse, puis sur la carte "Soin" qui sera en surbrillance.</p>
      <p>Dès que vous avez terminé, vous devez cliquer sur "Valider la défausse" en bas de l'interface. Vous piocherez automatiquement pour avoir une main de six cartes.</p>`,
    },
    fpe_4: {
      title: `Explications : jouer un sort`,
      html: `
      <p>Une fois votre défausse terminée, vous pouvez faire trois actions dans l'ordre que vous voulez.</p>
      <p>L'une de ces actions est de jouer un sortilège. Les sortilèges sont les cartes <strong class="has-text-primary">jaunes</strong>.</p>
      <p>Pour jouer un sort, choisissez le sort que vous voulez jouer dans la liste des actions ainsi que le sort dans votre main. Les cibles seront mises en surbrillance et vous n'aurez qu'à la choisir en appuyant dessus.</p>
      <p class="has-text-primary has-text-weight-bold">Essayez de jouer une Foudre sur le Renard à votre gauche !</p>`,
    },
    fpe_6: {
      title: `Explications : poser une carte`,
      html: `
      <p>Seconde action possible pendant la phase des actions : la pose.</p>
      <p>Vous pouvez poser une <strong class="has-text-danger">créature</strong> ou un <strong class="has-text-info">artéfact</strong> à côté d'une carte que vous contrôlez.</p>
      <p>Les <strong class="has-text-danger">créatures (en rouge)</strong> peuvent se déplacer sur le plateau et attaquer, tandis que les <strong class="has-text-info">artéfacts (en bleu)</strong> ne peuvent ni se déplacer, ni attaquer.</p>
      <p>Pour placer une carte, choisissez l'action "Poser une carte". Choisissez ensuite la carte à poser et l'endroit ou poser votre carte.</p>
      <p class="has-text-primary has-text-weight-bold">Essayez de placer le Totem Fûmant au-dessus de la Banshee ennemie !</p>`,
    },
    fpe_8: {
      title: `Explications : déplacer une créature`,
      html: `
      <p>Troisième action possible pendant la phase des actions : le déplacement.</p>
      <p>Vous pouvez déplacer une <strong class="has-text-danger">créature</strong> d'une case.</p>
      <p>Pour déplacer une créature, vous devez choisir l'action "Déplacer une créature". Choisissez ensuite la <strong class="has-text-danger">créature</strong> sur le plateau de jeu ainsi que sa destination.</p>
      <p class="has-text-primary has-text-weight-bold">Essayez de déplacer votre Banshee d'une case vers le haut !</p>`,
    },
    fpe_9: {
      title: `Explications : passer aux confrontations`,
      html: `
      <p>Très bien, vous avez fait vos trois actions : la pose d'une carte, le sortilège et le déplacement d'une créature.</p>
      <p>Cliquez sur "passer aux confrontations" pour passer à la phase suivante.</p>`,
    },
    fpe_11: {
      title: `Explications : confrontations`,
      html: `
      <p>A la fin de votre tour, vos <strong class="has-text-danger">créatures</strong> attaquent les cartes ennemies adjacentes. Vos <strong class="has-text-info">artéfacts</strong> qui ont le symbole <i class="capacity-threat"></i> peuvent attaquer les cartes qui sont du côté de ce symbole.</p>
      <img src="img/tip_fpe_11_example.png" />
      <p>Sur l'exemple ci-dessus, le joueur détenant le Renard termine son tour. Le Renard va donc attaquer la Tour. Les dégâts sont calculés de manières simultanée sur les deux cartes : le renard a <span class="stat strength">2</span> en attaque, et la tour a <span class="stat defense">2</span> en défense. La Tour ne prendra aucun dégât. Par-contre, le renard n'a aucune défense et la Tour a <span class="stat strength">3</span> en force sur le côté où elle est attaquée : le Renard est donc détruit, car il avait <span class="stat life">3</span> points de vie.</p>
      <p>Pour faire entrer en confrontation une carte avec une autre, choisissez l'action "Confronter". Cliquez ensuite sur la carte attaquante, puis sur la cible.</p>
      <p class="has-text-primary has-text-weight-bold">Essayez de faire entrer en confrontation votre Totem Fûmant avec la Banshee ennemie !</p>`,
    },
    fpe_15: {
      title: `Explications : tour de votre adversaire`,
      html: `
      <p>Votre adversaire passe par les mêmes phases que vous :</p>
      <ul>
        <li>Défausse & pioche</li>
        <li>Actions (pose de carte, sortilège, déplacement de créature)</li>
        <li>Confrontations</li>
      </ul>
      <p>Votre adversaire a posé un Barbelés juste devant lui pour vous barrer la route et c'est de nouveau votre tour.</p>
      <p class="has-text-primary has-text-weight-bold">Pour cette partie scénarisée, défaussez-vous de vos barbelés car il vous est inutile dans cette partie.</p>`,
    },
    fpe_17: {
      title: `Explications : à vous de jouer !`,
      html: `
      <p class="has-text-centered">Vous avez maintenant toutes les cartes en main pour pouvoir remporter cette partie !</p>`,
    },
    cards_list: {
      title: `Explications : cartes & decks`,
      html: `
      <p>Dans The First Spine, vous n'avez pas à jouer des heures ou à payer pour disposer des mêmes cartes que vos opposants. Tous les decks sont disponibles pour tous les joueurs et disposent des mêmes cartes.</p>
      <p>Vous pouvez naviguer parmi vos decks avec la partie gauche de l'interface. En bas, vous trouverez les cosmétiques disponibles pour vos cartes : les styles ainsi que les protège-cartes (avec le bouton <i class="fas fa-book-open"></i>).</p>
      <p>Si vous souhaitez plus d'informations sur une carte, restez simplement appuyé sur celle-ci.</p>`,
    },
    cards_play: {
      title: `Explications : choix d'un deck`,
      html: `
      <p>Voici vos cartes.</p>
      <p>Il existe 3 types de cartes : les <span class="has-text-danger">créatures</span>, les <span class="has-text-info">artéfacts</span> et les <span class="has-text-primary">sortilèges</span>.</p>
      <p><img src="img/tip_cards_play_example.png" /></p>
      <p>Chaque carte dispose d'une force et d'une défense pour chaque côté. Le chiffre au centre représente la vie de la carte. Si la vie de la carte tombe à 0, la carte est détruite et part dans votre défausse.</p>
      <p>Les cartes peuvent aussi avoir des capacités sur les côtés et à côté de la vie. Les capacités sont toutes expliquées lorsque votre pointeur passe au-dessus des cartes.</p>
      <p class="has-text-primary">Choisissez un deck sur la partie gauche. Vous pouvez obtenir plus d'informations sur une carte en restant appuyé sur celle-ci. Une fois que vous avez choisi votre deck, appuyez sur "Choisir le deck..." en haut de l'interface.</p>`,
    },
  },
  cardInfo: {
    statsChanges: `Changement de statistiques :`,
    hasHolo: `Cette carte a sa version Holo débloquée`,
    hasPremium: `Cette carte a sa version premium débloquée`,
  },
  playerCard: {
    victories: `victoires`,
    defeats: `défaites`,
    addAsAcolyte: `Ajouter en tant qu'acolyte`,
    removeAcolyte: `Retirer de la liste d'acolytes`,
    report: `Signaler un joueur`,
  },
  capacities: {
    treason: {
      title: `Trahison`,
      description: `Si la carte est détruite par un adversaire, elle doit être remise sur le plateau à la même place et contrôlée par l’adversaire qui l’a détruit. La carte perd cette capacité une fois qu’elle a été remise sur le plateau. Si la carte doit aller dans une pile de défausse, elle doit être mise dans la défausse de son propriétaire original.`,
    },
    threat: {
      title: `Menace`,
      description: `La carte entre en confrontation avec d’autres cartes placées sur le côté de la capacité.`,
    },
    run: {
      title: `Course`,
      description: `Au début de votre tour (avant la phase de pioche), vous pouvez déplacer l’une des cartes qui a cette capacité.`,
    },
    burdenEarth: {
      title: `Terre brûlée`,
      description: `Si la carte est détruite par une autre carte, la case occupée par cette carte ne peut être utilisée qu’à partir de votre prochain tour.`,
    },
    aura: {
      title: `Aura`,
      description: `La carte placée sur le côté de la capacité gagne {strength}2{/strength} pour tous ses côtés tant qu’elle est contrôlée par le même joueur.`,
    },
    grow: {
      title: `Croissance`,
      description: `La carte gagne {strength}2{/strength} au début de chaque tour de son propriétaire pendant 5 tours`,
    },
    death: {
      title: `Mort subite`,
      description: `Si la carte perd au moins un point de vie, elle est détruite. Si la carte est détruite par une {creature}créature{/creature} ou un {artifact}artéfact{/artifact}, cette {creature}créature{/creature} ou cet {artifact}artéfact{/artifact} est détruit.`,
    },
  },
};
