

















import { Component, Vue } from 'vue-property-decorator';
import Card from '@/components/Card.vue';
import optionsService from '../services/options.service';

@Component({
  components: {
    Card,
  },
})
export default class SelectLanguage extends Vue {

  public choseLanguage(lang: string) {
    optionsService.options.language = lang;
    this.$i18n.locale = lang;
    optionsService.saveOptions();
    this.$router.push('/');
  }

}
