










































































































import { Component, Vue } from 'vue-property-decorator';
import Card from '@/components/Card.vue';
import authService from './../services/auth.service';
import optionsService from '../services/options.service';
import wizardService from '../services/wizard.service';
import { IWizard } from '@thefirstspine/types-arena';

@Component({
  components: {
    Card,
  },
})
export default class Social extends Vue {

  public data() {
    return {
      websiteUrl: process.env.VUE_APP_WEBSITE_URL,
      wizard: null,
      friends: [],
      selectedFriend: null,
      searchDisplayed: false,
      search: '',
    };
  }

  public async mounted() {
    const wizard: IWizard = await wizardService.getMe();
    this.$data.wizard = wizard;
    this.loadFriends();
  }

  public async loadFriends() {
    const acolytes: IWizard[] = await Promise.all(this.$data.wizard.friends.map((f: number) => {
      return wizardService.get(f);
    }));

    acolytes.sort((a: IWizard, b: IWizard) => {
      return a.friends.includes(this.$data.wizard.id) ? -1 : 1;
    });

    this.$data.friends = acolytes;
  }

  public displayFriend(friend: IWizard) {
    this.$data.selectedFriend = friend;
    this.$data.searchDisplayed = false;
  }

  public async launchSearch() {
    this.$data.selectedFriend = null;
    this.$data.searchDisplayed = true;
    const result: IWizard|any = await wizardService.get(this.$data.search);
    if (result.id) {
      this.$data.selectedFriend = result;
    }
  }

  public addSelectedToFriends() {
    this.$data.wizard.friends.push(this.$data.selectedFriend.id);
    wizardService.setMe({
      friends: this.$data.wizard.friends,
    });
    this.loadFriends();
  }

  public removeSelectedToFriends() {
    this.$data.wizard.friends = this.$data.wizard.friends.filter((i: number) => i !== this.$data.selectedFriend.id);
    wizardService.setMe({
      friends: this.$data.wizard.friends,
    });
    this.loadFriends();
  }

}
