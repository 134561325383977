














import { Component, Vue, Prop } from 'vue-property-decorator';
import { IQuest, ILoot, ICard } from '@thefirstspine/types-rest';
import messagingService, { IMessage, ISubscriber } from '@/services/messaging.service';
import restService from '@/services/rest.service';
import { IUserQuest } from '@thefirstspine/types-arena';

@Component
export default class Tchat extends Vue {

  public mounted() {
    const allowedItems = [
      {
        name: '^shard$',
        mode: 'default',
        text: 'toast.loot.shard',
        image: 'img/toast-shard.png',
      },
      {
        name: '^golden-galleon$',
        mode: 'default',
        text: 'toast.loot.golden-galleon',
        image: 'img/toast-golden-galleon.png',
      },
      {
        name: '^candy-shard$',
        mode: 'default',
        text: 'toast.loot.candy-shard',
        image: 'img/toast-candy-shard.png',
      },
      {
        name: '^coin$',
        mode: 'default',
        text: 'toast.loot.coin',
        image: 'img/toast-coin.png',
      },
      {
        name: '^rune-holo$',
        mode: 'default',
        text: 'toast.loot.rune-holo',
        image: 'img/toast-rune-holo.png',
      },
      {
        name: '^rune-solid$',
        mode: 'default',
        text: 'toast.loot.rune-solid',
        image: 'img/toast-rune-solid.png',
      },
      {
        name: '^rune-ghostly$',
        mode: 'default',
        text: 'toast.loot.rune-ghostly',
        image: 'img/toast-rune-ghostly.png',
      },
      {
        name: '^holo-',
        mode: 'card',
        text: 'toast.loot.holo',
        image: 'img/toast-holo.png',
      },
      {
        name: '^premium-',
        mode: 'card',
        text: 'toast.loot.premium',
        image: 'img/toast-premium.png',
      },
      {
        name: '^cover-',
        mode: 'replaceName',
        text: 'covers.{name}',
        image: 'img/toast-cover.png',
      },
      {
        name: '^style-',
        mode: 'replaceName',
        text: 'styles.{name}',
        image: 'img/toast-style.png',
      },
    ];
    messagingService.subscribe({
      subject: 'TheFirstSpine:loot',
      handler: async (message: IMessage) => {
        message.message.forEach(async (loot: ILoot) => {
          const lootItem: any = allowedItems.find((i: any) => {
            return new RegExp(i.name).test(loot.name);
          });
          if (lootItem) {
            switch (lootItem.mode) {
              case 'default':
              default:
                this.addToast('toast.loot.title', lootItem.text, lootItem.image, loot.num);
                break;
              case 'card':
                const cardId: string = loot.name.split('-').splice(1, 10).join('-');
                const card: ICard = await restService.card(cardId);
                const cardName: any = card.name;
                this.addToast(
                  'toast.loot.title',
                  this.$t(lootItem.text, {cardName: cardName[this.$i18n.locale]}).toString(),
                  lootItem.image,
                  loot.num);
                break;
              case 'replaceName':
                const title: string = lootItem.text.replace('{name}', loot.name);
                this.addToast(
                  'toast.loot.title',
                  title,
                  lootItem.image,
                  loot.num);
                break;
            }
          }
        });
      },
    });
    messagingService.subscribe({
      subject: 'TheFirstSpine:quest:obtain',
      handler: async (message: IMessage) => {
        const quest: IQuest = message.message;
        const questName: any = quest.name;
        this.addToast('toast.quest.obtain', questName[this.$i18n.locale], 'img/toast-quest.png');
      },
    });
    messagingService.subscribe({
      subject: 'TheFirstSpine:quest:complete',
      handler: async (message: IMessage) => {
        const quest: IQuest = message.message;
        const questName: any = quest.name;
        this.addToast('toast.quest.complete', questName[this.$i18n.locale], 'img/toast-quest.png');
      },
    });
    messagingService.subscribe({
      subject: 'TheFirstSpine:quest:progress',
      handler: async (message: IMessage) => {
        const quest: IUserQuest = message.message;
        const questName: any = quest.name;
        this.addToast('toast.quest.progress', questName[this.$i18n.locale] + ` (${quest.objectiveCurrent} / ${quest.objectiveTarget})`, 'img/toast-quest.png');
      },
    });
  }

  public data() {
    return {
      toasts: [],
    };
  }

  public addToast(title: string, text: string, image: string, num: number = 0) {
    const uniq = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    this.$data.toasts.push({
      uniq,
      title,
      text,
      image,
      num,
    });

    setTimeout(() => {
      this.$data.toasts = this.$data.toasts.filter((t: any) => t.uniq !== uniq);
    }, 5000);
  }

}
