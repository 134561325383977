






import { Component, Prop, Vue } from 'vue-property-decorator';
import { ICardCoords } from '@thefirstspine/types-rest';
import { IAnimation } from '../helpers/animations.helper';

@Component
export default class Card extends Vue {
  @Prop() private animation?: IAnimation;

  public data() {
    return {
      count: '',
    };
  }

  public mounted() {
    this.$data.count = this.animation ? this.animation.metadata.count : 0;
  }
}
